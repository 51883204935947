import { supabase } from '../client/supabaseClient';
/**
 * Check if a shop exists using its identifier (UUID or shop identifier string)
 * @param shopIdentifier - The shop identifier (UUID or string identifier)
 * @returns The final shop ID as UUID, or '00000000-0000-0000-0000-000000000000' if not found
 */
export const checkShopExists = async (shopIdentifier: string): Promise<string> => {
  try {
    const { data, error } = await supabase
    .schema('api')
    .rpc(
      'get_final_shop_id',
      { input_value: shopIdentifier }
    );

    if (error) {
      console.error('Error checking shop existence:', error);
      return '00000000-0000-0000-0000-000000000000';
    }

    return data || '00000000-0000-0000-0000-000000000000';
  } catch (error) {
    console.error('Exception while checking shop existence:', error);
    return '00000000-0000-0000-0000-000000000000';
  }
};
