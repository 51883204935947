import React from 'react';
import usePrintStore from '../../store/printStore';

interface OrientationSelectorProps {
  documentId: string;
  orientation: 'portrait' | 'landscape';
}

const OrientationSelector: React.FC<OrientationSelectorProps> = ({ documentId, orientation }) => {
  const { updatePrintSettings } = usePrintStore();

  const handleOrientationChange = (newOrientation: 'portrait' | 'landscape') => {
    updatePrintSettings(documentId, { orientation: newOrientation });
  };

  return (
    <div className="mb-3">
      <label className="block mb-1 font-medium text-sm text-gray-700">Orientation</label>
      <div className="flex space-x-2">
        <button
          type="button"
          onClick={() => handleOrientationChange('portrait')}
          className={`flex-1 border p-2 rounded flex flex-col items-center transition-colors ${
            orientation === 'portrait' 
              ? 'bg-blue-50 border-blue-300' 
              : 'border-gray-300 hover:bg-gray-50'
          }`}
        >
          <div className="w-6 h-8 border border-gray-400 rounded mb-1"></div>
          <span className="text-xs">Portrait</span>
        </button>
        
        <button
          type="button"
          onClick={() => handleOrientationChange('landscape')}
          className={`flex-1 border p-2 rounded flex flex-col items-center transition-colors ${
            orientation === 'landscape' 
              ? 'bg-blue-50 border-blue-300' 
              : 'border-gray-300 hover:bg-gray-50'
          }`}
        >
          <div className="h-6 w-8 border border-gray-400 rounded mb-1"></div>
          <span className="text-xs">Landscape</span>
        </button>
      </div>
    </div>
  );
};

export default OrientationSelector;
