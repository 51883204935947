export const calculateBWPrice = (pageCount: number): number => {
  return 1.25 + (1.5 / pageCount);
};

export const calculateColorPrice = (pageCount: number): number => {
  // Starting at 15, gradually decreasing to a minimum of 8
  const basePrice = 15;
  const minPrice = 10;
  const reduction = Math.min(7, (pageCount - 1) * 0.5);
  return Math.max(minPrice, basePrice - reduction);
};
