import React from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Label } from '../ui/label';

const Support: React.FC = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4 text-blue-600">Support</h2>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <Card className="shadow-sm border border-gray-100">
            <CardHeader>
              <CardTitle className="text-lg text-gray-800">Contact Support</CardTitle>
              <CardDescription>
                Fill out the form below and we'll get back to you as soon as possible.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <form className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="name">Your Name</Label>
                    <Input id="name" placeholder="Enter your name" className="border-gray-300 focus:border-blue-500" />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Email Address</Label>
                    <Input id="email" type="email" placeholder="Enter your email" className="border-gray-300 focus:border-blue-500" />
                  </div>
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="subject">Subject</Label>
                  <Input id="subject" placeholder="What is this regarding?" className="border-gray-300 focus:border-blue-500" />
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="message">Message</Label>
                  <Textarea 
                    id="message" 
                    placeholder="Please describe your issue in detail"
                    rows={5}
                    className="border-gray-300 focus:border-blue-500"
                  />
                </div>
              </form>
            </CardContent>
            <CardFooter>
              <Button className="ml-auto bg-blue-600 hover:bg-blue-700 text-white">Submit Request</Button>
            </CardFooter>
          </Card>
        </div>
        
        <div className="lg:col-span-1">
          <Card className="shadow-sm border border-gray-100">
            <CardHeader>
              <CardTitle className="text-lg text-gray-800">Contact Information</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div>
                <p className="font-medium">Email Support</p>
                <p className="text-gray-500">support.ctrlp@gmail.com</p>
              </div>
              <div>
                <p className="font-medium">Phone Support</p>
                <p className="text-gray-500">+91-123-456-7890</p>
                <p className="text-xs text-gray-400">Mon-Fri, 9 AM - 6 PM</p>
              </div>
              <div>
                <p className="font-medium">Help Center</p>
                <a href="#" className="text-blue-600 hover:underline">Visit our knowledge base</a>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Support;
