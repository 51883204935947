// razorpayService.js
import axios from 'axios';
import { useAuthStore } from '../store/authStore';

interface RazorpayResponse {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
}

declare global {
    interface Window {
        Razorpay: any;
    }
}

class RazorPayService {
    constructor() {
        this._loadScript();
    }

    // Load the Razorpay script once
    _loadScript() {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    }

    // Create order on your backend - exposed as public method
    async createOrderId(
        amount: number,
        paymentType: 'ORDER' | 'WALLET' | 'PRINT_BAZAAR',
        userId: string,
        currency: string = 'INR',
        additionalNotes: Record<string, any> = {}
    ) {
        try {
            const notes = {
                ...additionalNotes,
                paymentType,
                userId
            };

            const response = await axios.post('https://ctrlp-payment-server-eight.vercel.app/new-server/create-razorpay-order', {
                amount: amount,
                currency,
                receipt: `receipt_${paymentType}_${Date.now()}`,
                notes,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error creating Razorpay order:', error);
            throw error;
        }
    }

    // Generic checkout method that requires an order ID
    async initiatePayment(
        {
            amount,
            currency = 'INR',
            orderId,
            paymentType,
            userId,
            name = 'CTRL+P',
            description,
            theme = { color: '#3399cc' },
            prefill = {},
            notes = {},
            orderSpecificData = {},
        }: {
            amount: number;
            currency?: string;
            orderId: string;
            paymentType: 'ORDER' | 'WALLET' | 'PRINT_BAZAAR';
            userId: string;
            name?: string;
            description?: string;
            theme?: { color: string;[key: string]: any };
            prefill?: Record<string, any>;
            notes?: Record<string, any>;
            orderSpecificData?: Record<string, any>;
        }) {
        console.log("in initiate payment");

        // Ensure order ID is provided
        if (!orderId) {
            throw new Error('Order ID is required to initiate payment');
        }

        // Open Razorpay checkout
        return new Promise((resolve, reject) => {
            // Process and verify the payment
            const handlePaymentVerification = async (response: RazorpayResponse) => {
                try {
                    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ctrlp-payment-server-eight.vercel.app/new-server';
                    const verificationEndpoint =
                        paymentType === 'WALLET' ? `${baseUrl}/verify-wallet-payment` :
                        paymentType === 'PRINT_BAZAAR' ? `${baseUrl}/verify-printbazaar-payment` :
                        `${baseUrl}/verify-order-payment`;

                    let payload;

                    if (paymentType === 'WALLET') {
                        payload = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                            amount: amount,
                            userId: userId
                        };
                    } else if (paymentType === 'PRINT_BAZAAR') {
                        payload = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                            amount: amount,
                            userId: userId,
                            ...orderSpecificData  // This contains author_id, document_id, totalCost
                        };
                    } else {
                        payload = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature,
                            ...orderSpecificData  // This contains session, orderSummary, etc.
                        };
                    }

                    console.log("payload", payload);
                    console.log("verificationEndpoint", verificationEndpoint);

                    try {
                        const verificationData = await axios.post(verificationEndpoint, payload);
                        console.log("verificationData", verificationData);

                        // Resolve the promise with the verification data
                        resolve(verificationData.data);
                    } catch (axiosError: unknown) {
                        console.error('Payment verification network error:', axiosError);

                        // Type guard for axios errors
                        if (axios.isAxiosError(axiosError)) {
                            if (axiosError.response) {
                                // The server responded with an error status
                                console.error('Server error response:', axiosError.response.status, axiosError.response.data);
                                reject(new Error(`Server verification failed with status ${axiosError.response.status}: ${axiosError.response.data?.message || 'Unknown error'
                                    }`));
                            } else if (axiosError.request) {
                                // The request was made but no response was received
                                reject(new Error('No response received from verification server'));
                            } else {
                                // Something happened in setting up the request
                                reject(new Error(`Error during verification request: ${axiosError.message}`));
                            }
                        } else {
                            // For non-axios errors
                            const errorMessage = axiosError instanceof Error ? axiosError.message : 'Unknown error occurred';
                            reject(new Error(`Payment verification failed: ${errorMessage}`));
                        }
                    }
                } catch (error: unknown) {
                    console.error('Payment verification failed:', error);
                    const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
                    reject(new Error(`Payment verification failed: ${errorMessage}`));
                }
            };

            // Configure payment options with handlers included
            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY_ID,
                amount: amount * 100,
                currency,
                name,
                description,
                order_id: orderId,
                prefill,
                notes: {
                    ...notes,
                    paymentType,
                    userId
                },
                theme,
                // Handlers for success and modal dismiss
                handler: function (response: RazorpayResponse) {
                    handlePaymentVerification(response);
                },
                modal: {
                    ondismiss: function () {
                        console.log('Payment cancelled by user');
                        reject(new Error('Payment was cancelled by user'));
                    }
                }
            };

            try {
                const razorpayInstance = new window.Razorpay(options);
                razorpayInstance.open();
            } catch (error: unknown) {
                console.error('Failed to open Razorpay checkout:', error);
                const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
                reject(new Error(`Failed to open Razorpay checkout: ${errorMessage}`));
            }
        });
    }

    // Convenience method for order payments
    async processOrderPayment(
        orderId: string,
        amount: number,
        userId: string,
        additionalDetails: Record<string, any> = {},
        orderData: {
            session: string | null | undefined, // Update session type to handle all possible cases
            orderSummary: any,
            userInfo: any,
            fileCosts: any[],
            totalCost: number,
            shop_id: string | null
        }
    ) {
        return this.initiatePayment({
            amount,
            orderId,
            paymentType: 'ORDER',
            userId,
            description: `Payment for order #${orderId.slice(0, 8)}`,
            orderSpecificData: {
                session: orderData.session,
                orderSummary: orderData.orderSummary,
                userInfo: orderData.userInfo,
                fileCosts: orderData.fileCosts,
                totalCost: orderData.totalCost,
                shop_id: orderData.shop_id || ''
            },
            ...additionalDetails
        });
    }

    // Convenience method for wallet top-up
    async processWalletPayment(
        orderId: string,
        amount: number,
        userId: string,
        additionalDetails: Record<string, any> = {}
    ) {
        return this.initiatePayment({
            amount,
            orderId,
            paymentType: 'WALLET',
            userId,
            description: 'Wallet Recharge',
            ...additionalDetails
        });
    }

    // Convenience method for Print Bazaar payments
    async processPrintBazaarPayment(
        orderId: string,
        amount: number,
        userId: string,
        documentData: {
            author_id: string,
            document_id: string,
            totalCost: number
        },
        additionalDetails: Record<string, any> = {}
    ) {
        return this.initiatePayment({
            amount,
            orderId,
            paymentType: 'PRINT_BAZAAR',
            userId,
            description: `Payment for document purchase`,
            orderSpecificData: {
                ...documentData,
                ...additionalDetails
            }
        });
    }
}

export default new RazorPayService();