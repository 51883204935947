import React from 'react';
import { Document } from '../../store/printStore';
import usePrintStore from '../../store/printStore';

interface DocumentCardProps {
  document: Document;
  isSelected: boolean;
  onClick: () => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ document, isSelected, onClick }) => {
  const { printSettings } = usePrintStore();
  const settings = printSettings[document.id];

  return (
    <div 
      className={`p-3 rounded-lg cursor-pointer transition-all mb-2 border ${
        isSelected 
          ? 'border-blue-500 bg-blue-50 shadow-md' 
          : 'border-gray-200 hover:border-blue-300 hover:bg-gray-50'
      }`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center flex-1 min-w-0">
          <div className="flex-shrink-0 mr-3 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </div>
          <div className="min-w-0">
            <h3 className="font-medium text-sm truncate" title={document.name}>
              {document.name}
            </h3>
            <p className="text-xs text-gray-500 truncate" title={`${settings.copies} copies • ${settings.color ? 'Color' : 'B&W'} • ${settings.doubleSided ? 'Double-sided' : 'Single-sided'}`}>
              {settings.copies} {settings.copies > 1 ? 'copies' : 'copy'} • 
              {settings.color ? ' Color' : ' B&W'} • 
              {settings.doubleSided ? ' Double-sided' : ' Single-sided'}
            </p>
          </div>
        </div>
        
        {isSelected && (
          <div className="flex-shrink-0 text-blue-600 ml-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentCard;
