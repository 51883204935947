import { supabase } from '../client/supabaseClient';

/**
 * Fetches the specified user's wallet balance from the database.
 * Calls the 'fetch_wallet_balance' RPC in the 'api' schema.
 * @param userId - The UUID of the user whose balance to fetch
 */
export async function fetchWalletBalance(userId: string): Promise<number | null> {
    try {
        const { data, error } = await supabase
            .schema('api')
            .rpc('fetch_wallet_balance', { user_id_param: userId });

        if (error) {
            console.error('Error fetching wallet balance:', error);
            return null;
        }

        return data; // Returns the wallet balance
    } catch (error) {
        console.error('Unexpected error in fetchWalletBalance:', error);
        return null;
    }
}