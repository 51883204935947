import { supabase } from '../client/supabaseClient';

export interface PrintBazaarPurchase {
  user_id: string;
  doc_id: string;
  amount: number;
  payment_type: 'WALLET' | 'RAZORPAY' | 'FREE';
  order_id?: string;
}

export async function addPrintBazaarPurchase(
  purchase: PrintBazaarPurchase
) {
  const { data, error } = await supabase
    .schema('api')
    .rpc('add_print_bazaar_purchase', {
      _user_id: purchase.user_id,
      _doc_id: purchase.doc_id,
      _amount: purchase.amount,
      _payment_type: purchase.payment_type,
      _order_id: purchase.order_id
    });

  if (error) throw error;
  return data;
}

export async function checkPrintBazaarPurchase(userId: string, docId: string) {
  const { data, error } = await supabase
    .schema('api')
    .rpc('check_print_bazaar_purchase', {
      _user_id: userId,
      _doc_id: docId
    });

  if (error) throw error;
  return data;
}
