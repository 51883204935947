import React from 'react';
import ShopSelectionCardComponent from '../shop-selection';

// Add this prop to receive location permission response from parent
interface ShopSelectionCardProps {
  showLocationModal?: (show: boolean) => void;
  locationResponse?: boolean | null;
}

// This is now just a wrapper component for backward compatibility
const ShopSelectionCard: React.FC<ShopSelectionCardProps> = (props) => {
  return <ShopSelectionCardComponent {...props} />;
};

export default ShopSelectionCard;
