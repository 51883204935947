import React from 'react';
import usePrintStore from '../../store/printStore';

interface CopiesControlProps {
  documentId: string;
  copies: number;
}

const CopiesControl: React.FC<CopiesControlProps> = ({ documentId, copies }) => {
  const { updatePrintSettings } = usePrintStore();
  
  return (
    <div className="mb-3">
      <label className="block mb-1 font-medium text-sm text-gray-700">Copies</label>
      <div className="flex items-center">
        <button
          onClick={() => updatePrintSettings(documentId, { 
            copies: Math.max(1, copies - 1) 
          })}
          className="px-3 py-2 border border-gray-300 rounded-l bg-gray-100 hover:bg-gray-200"
        >
          -
        </button>
        <input
          type="number"
          min="1"
          value={copies}
          onChange={e => updatePrintSettings(documentId, { copies: parseInt(e.target.value) || 1 })}
          className="border-t border-b border-gray-300 py-2 w-full text-center focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
        />
        <button
          onClick={() => updatePrintSettings(documentId, { 
            copies: copies + 1 
          })}
          className="px-3 py-2 border border-gray-300 rounded-r bg-gray-100 hover:bg-gray-200"
        >
          +
        </button>
      </div>
    </div>
  );
};

export default CopiesControl;
