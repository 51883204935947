import { supabase } from '../client/supabaseClient';
import { uploadPDF } from '../storage/upload-pdf';

type PrintBazaarDocResult = {
  id: string;
  user_id: string;
  title: string;
  description: string | null;
  file_name: string;
  file_path: string;
  cover_image_path: string | null;
  file_size: number;
  file_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  views: number;
  downloads: number;
};

type PrintBazaarDocInput = {
  title: string;
  description?: string;
  pdfFile: File;
  coverImage?: File;
  previewPages?: File[];
  cost?: number;
  isFree?: boolean;
  tags?: string[];
  category?: string;
  status?: 'pending' | 'approved' | 'rejected';
  pageCount: number;
};

/**
 * Uploads a document to PrintBazaar including the PDF file, cover image, preview pages,
 * and creates a record in the PrintBazaarDocs table
 */
export async function addPrintBazaarDoc({
  title,
  description,
  pdfFile,
  coverImage,
  previewPages = [],
  cost = 0,
  isFree = false,
  tags = [],
  category,
  status = 'pending',
  pageCount
}: PrintBazaarDocInput): Promise<PrintBazaarDocResult | null> {
  try {
    console.log('Uploading document to PrintBazaar...');
    
    // Upload preview pages first
    const previewUrls: string[] = [];
    if (previewPages.length > 0) {
      for (let i = 0; i < Math.min(previewPages.length, 3); i++) {
        const previewFile = previewPages[i];
        const previewUrl = await uploadPDF(
          previewFile,
          'print-bazaar-docs',
          `docs_preview/preview_${i + 1}-${pdfFile.name.replace(/[^\w.-]/g, '_')}`
        );
        previewUrls.push(previewUrl);
      }
    }

    // Upload the PDF file
    const pdfUrl = await uploadPDF(
      pdfFile,
      'print-bazaar-docs', // bucket name must match exactly what we use in API
      `docs/${Date.now()}_${pdfFile.name.replace(/[^\w.-]/g, '_')}`
    );
    
    // Upload the cover image if provided
    let coverImageUrl = null;
    if (coverImage) {
      coverImageUrl = await uploadPDF(
        coverImage,
        'print-bazaar-docs', // same bucket, different prefix
        `covers/${Date.now()}_${coverImage.name.replace(/[^\w.-]/g, '_')}`
      );
    }
    
    // Create the document record in the database
    const { data, error } = await supabase
      .schema('api')
      .rpc('add_print_bazaar_doc', {
        _title: title,
        _description: description || null,
        _file_name: pdfFile.name,
        _file_path: pdfUrl,
        _cover_image_path: coverImageUrl,
        _file_size: pdfFile.size,
        _file_type: pdfFile.type,
        _status: status,
        _cost: cost,
        _is_free: isFree,
        _tags: JSON.stringify(tags),
        _category: category || null,
        _preview_pages: JSON.stringify(previewUrls),
        _page_count: pageCount
      });
      
    if (error) {
      console.error('Error adding PrintBazaar document:', error);
      throw new Error(`Failed to add PrintBazaar document: ${error.message}`);
    }
    
    console.log('PrintBazaar document added successfully:', data);
    return data;
  } catch (error) {
    console.error('Unexpected error in addPrintBazaarDoc:', error);
    throw error;
  }
}
