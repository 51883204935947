import { Document, PrintSettings, DocumentSource } from './documentTypes';

// Paper type enum for consistent coding
export enum PaperType {
  A4 = 0,
  A3 = 1,
  LETTER = 2,
  LEGAL = 3
}

// Document group represents documents with the same print settings
export interface DocumentGroup {
  id: string;
  documents: {
    id: string;
    name: string;
    file: File;
  }[];
  source: DocumentSource;  // Add source with correct type
  printSettingsId: string;
  printSettings: PrintSettings;
  settingsSummary: string;
  printSettingsCode: number; // Numeric code representing settings combination
  uploadFilePath?: string; // URL after uploading
  totalPages?: number; // Add this field for tracking total pages in the group
  price?: number; // Add this field for tracking group-specific price
}

// Store interface for document groups
export interface DocumentGroupsStore {
  documentGroups: DocumentGroup[];
  
  // Methods
  addGroup: (group: DocumentGroup) => void;
  updateGroup: (id: string, changes: Partial<DocumentGroup>) => void;
  clearGroups: () => void;
  setuploadFilePath: (groupId: string, filePath: string) => void;
  
  // Helper method to generate print settings code
  generatePrintSettingsCode: (settings: PrintSettings) => number;
}

// Upload summary for final reporting
export interface UploadSummary {
  source: string;
  totalGroups: number;
  totalDocuments: number;
  totalPages: {
    color: number;
    bw: number;
    total: number;
  };
  totalPrice: number;
  uploadedGroups: {
    groupId: string;
    printSettingsCode: number;
    documentNames: string[];
    settingsSummary: string;
    uploadFilePath: string;
    totalPages: number; // Add this field
    price: number; // Add this field
  }[];
}
