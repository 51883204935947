import { supabase } from '../client/supabaseClient';

interface ApiDocumentResponse {
  id: string;
  preview_pages: string | string[];
  tags: string | string[];
  [key: string]: any;  // for other fields
}

/**
 * Fetches Print Bazaar documents for the current user or approved documents for everyone
 * @param onlyMine If true, fetches only the current user's documents, otherwise fetches all approved docs
 * @returns Array of Print Bazaar documents
 */
export async function fetchPrintBazaarDocs(onlyMine: boolean = false) {
  try {
    const { data, error } = await supabase
      .schema('api')
      .rpc(onlyMine ? 'fetch_my_print_bazaar_docs' : 'get_marketplace_docs');
    
    if (error) {
      console.error('Error fetching print bazaar documents:', error);
      return [];
    }
    
    // Parse JSON fields before returning
    return data?.map((doc: ApiDocumentResponse) => ({
      ...doc,
      preview_pages: Array.isArray(doc.preview_pages) 
        ? doc.preview_pages 
        : JSON.parse(doc.preview_pages || '[]'),
      tags: Array.isArray(doc.tags) 
        ? doc.tags 
        : JSON.parse(doc.tags || '[]'),
      cost: Number(doc.cost || 0),
      is_free: Boolean(doc.is_free)
    })) || [];
    
  } catch (error) {
    console.error('Unexpected error in fetchPrintBazaarDocs:', error);
    return [];
  }
}

/**
 * Fetches a specific Print Bazaar document by ID
 * Also increments the view count if the viewer is not the owner
 * @param docId The document ID to fetch
 * @returns The document or null if not found
 */
export async function fetchPrintBazaarDocById(docId: string) {
  try {
    const { data, error } = await supabase
      .schema('api')
      .rpc('fetch_print_bazaar_doc_by_id', {
        _doc_id: docId
      });
    
    if (error) {
      console.error('Error fetching print bazaar document by ID:', error);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error('Unexpected error in fetchPrintBazaarDocById:', error);
    return null;
  }
}

/**
 * Fetches free Print Bazaar documents
 * @returns Array of free Print Bazaar documents
 */
export async function fetchFreePrintBazaarDocs() {
  try {
    const { data, error } = await supabase
      .schema('api')
      .rpc('fetch_free_print_bazaar_docs');
    
    if (error) {
      console.error('Error fetching free print bazaar documents:', error);
      return [];
    }
    
    return data;
  } catch (error) {
    console.error('Unexpected error in fetchFreePrintBazaarDocs:', error);
    return [];
  }
}
