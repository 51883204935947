import { supabase } from '../client/supabaseClient';
import { User } from '@supabase/supabase-js';
export interface PendingDocument {
    id: string;                 // UUID in PostgreSQL -> string in TypeScript
    title: string;              // TEXT
    user_id: string;            // UUID
    user_email: string;         // TEXT
    description: string;        // TEXT
    file_name: string;          // TEXT
    file_path: string;          // TEXT
    cover_image_path: string | null;  // TEXT nullable
    file_size: number;          // BIGINT -> number
    file_type: string;          // TEXT
    status: string;             // TEXT
    created_at: string;         // TIMESTAMPTZ -> string
    updated_at: string;         // TIMESTAMPTZ -> string
    views: number;              // INTEGER
    downloads: number;          // INTEGER
  }

/**
 * Checks if a user is an admin by calling the is_admin RPC function
 * @param user The user to check (not actually needed for the RPC)
 * @returns True if the user is an admin, false otherwise
 */
export const checkIfUserIsAdmin = async (user: User | null): Promise<boolean> => {
  if (!user) {
    console.log('Admin check: No user provided');
    return false;
  }
  
  console.log('Checking admin status for user ID using RPC:', user.id);
  
  try {
    // Call the is_admin() RPC function
    const { data, error } = await supabase.schema('api').rpc('is_admin');
    
    console.log('Admin check RPC result:', { data, error });
    
    if (error) {
      console.error('Error checking admin status with RPC:', error);
      return false;
    }
    
    // The function returns a boolean directly
    return data || false;
  } catch (error) {
    console.error('Error in checkIfUserIsAdmin RPC call:', error);
    return false;
  }
};

/**
 * Interface for admin dashboard statistics
 */
export interface AdminDashboardStats {
  users_count: number;
  documents_count: number;
  pending_approvals: number;
  total_revenue: number;
}

/**
 * Fetches all dashboard statistics for the admin panel in a single RPC call
 * Requires the get_admin_dashboard_stats() RPC function to exist in Supabase
 */
export const getAdminDashboardStats = async (): Promise<AdminDashboardStats> => {
  try {
    const { data, error } = await supabase.schema('api').rpc('get_admin_dashboard_stats');
    
    console.log('Admin dashboard stats RPC result:', { data, error });
    
    if (error) {
      console.error('Error fetching admin dashboard stats:', error);
      // Return default values in case of error
      return {
        users_count: 0,
        documents_count: 0,
        pending_approvals: 0,
        total_revenue: 0
      };
    }
    
    return data || {
      users_count: 0,
      documents_count: 0,
      pending_approvals: 0,
      total_revenue: 0
    };
  } catch (error) {
    console.error('Error calling getAdminDashboardStats:', error);
    return {
      users_count: 0,
      documents_count: 0,
      pending_approvals: 0,
      total_revenue: 0
    };
  }
};

/**
 * Helper function to create the is_admin RPC function in Supabase
 * This should be run once from an admin account or during setup
 */
export const createAdminRpcFunction = async (): Promise<void> => {
  try {
    console.log('Creating is_admin RPC function in Supabase...');
    // Note: This requires superuser privileges and won't work in most environments
    // It's better to create this function through the Supabase dashboard SQL editor
    const query = `
      create or replace function is_admin()
      returns boolean
      language plpgsql
      security definer
      as $$
      begin
        return exists (
          select 1 from admins where id = auth.uid()
        );
      end;
      $$;
    `;
    
    // This is just for reference - you should create the function through the Supabase dashboard
    console.log('RPC function creation SQL:', query);
    
    console.log('Note: Please manually create this function in the Supabase SQL editor');
  } catch (error) {
    console.error('Error creating admin RPC function:', error);
  }
};

// Interface for pending document type
export interface PendingDocument {
  id: string;
  title: string;
  user_id: string;
  user_email: string;
  description: string;
  file_name: string;
  file_path: string;
  cover_image_path: string | null;
  file_size: number;
  file_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  views: number;
  downloads: number;
}
// Fetch all pending documents
export const getPendingDocuments = async (): Promise<PendingDocument[]> => {
    console.log('Calling getPendingDocuments RPC...');
    
    try {
      console.log('Fetching pending documents from api schema');
      const { data, error } = await supabase
        .schema('api')
        .rpc('get_pending_documents');
      
      if (error) {
        console.error('Error fetching pending documents:', error);
        throw error;
      }
      
      // Log the structure of the returned data to help debug
      if (data && data.length > 0) {
        console.log('Sample document structure:', data[0]);
      } else {
        console.log('No pending documents found');
      }
      
      return data || [];
    } catch (error) {
      console.error('Exception in getPendingDocuments:', error);
      throw error;
    }
  };

// Update document status (approve or deny)
export const updateDocumentStatus = async (documentId: string, status: 'approved' | 'denied'): Promise<void> => {
  console.log(`Updating document ${documentId} to status ${status}`);
  
  try {
    // Try without schema prefix first
    console.log('Attempting to call update_document_status RPC without schema prefix');
    const { data, error } = await supabase.rpc('update_document_status', {
      document_id: documentId,
      new_status: status
    });
    
    console.log('Update RPC response:', { data, error });
    
    if (error) {
      console.error('Error updating document status:', error);
      
      // Try with schema prefix as fallback
      console.log('Attempting with api schema prefix as fallback...');
      const apiResult = await supabase.schema('api').rpc('update_document_status', {
        document_id: documentId,
        new_status: status
      });
      
      console.log('API schema update RPC response:', apiResult);
      
      if (apiResult.error) {
        console.error('Error with api schema prefix:', apiResult.error);
        throw apiResult.error;
      }
      
      return;
    }
  } catch (error) {
    console.error('Exception in updateDocumentStatus:', error);
    throw error;
  }
};
