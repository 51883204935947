import React, { useState, useEffect, useRef } from 'react';
import { PageSelection as PageSelectionType } from '../../types/documentTypes';
import usePrintStore from '../../store/printStore';
import { calculateEffectivePageCount } from '../../utils/pageUtils';

interface PageSelectionProps {
  documentId: string;
  pageSelection: PageSelectionType; // Now enforced as non-undefined
  totalPages: number;
}

const PageSelection: React.FC<PageSelectionProps> = ({ documentId, pageSelection, totalPages }) => {
  const { updatePageSelection } = usePrintStore();
  
  // State for form inputs
  const [customRangeFrom, setCustomRangeFrom] = useState<string>('');
  const [customRangeTo, setCustomRangeTo] = useState<string>('');
  const [customPages, setCustomPages] = useState<string>('');
  
  // Editing state flags to prevent loops
  const [isEditingRange, setIsEditingRange] = useState<boolean>(false);
  const [isEditingCustom, setIsEditingCustom] = useState<boolean>(false);
  
  // Error states
  const [rangeError, setRangeError] = useState<string>('');
  const [customPagesError, setCustomPagesError] = useState<string>('');
  
  // Effective page count state
  const [effectivePageCount, setEffectivePageCount] = useState<number>(0);

  // Flag to control initial sync from store
  const initialSyncDoneRef = useRef<boolean>(false);

  // Initialize form values from props and calculate effective pages
  useEffect(() => {
    if (!initialSyncDoneRef.current) {
      // Initial sync of values from store
      if (pageSelection.type === 'range' && pageSelection.range) {
        setCustomRangeFrom(pageSelection.range.start.toString());
        setCustomRangeTo(pageSelection.range.end.toString());
      } else if (pageSelection.type === 'custom' && pageSelection.customPages && pageSelection.customPages.length > 0) {
        setCustomPages(formatCustomPagesForDisplay(pageSelection.customPages));
      }
      initialSyncDoneRef.current = true;
    } else {
      // Update only if user is not currently editing
      if (pageSelection.type === 'range' && pageSelection.range && !isEditingRange) {
        setCustomRangeFrom(pageSelection.range.start.toString());
        setCustomRangeTo(pageSelection.range.end.toString());
      }
      
      if (pageSelection.type === 'custom' && pageSelection.customPages && 
          pageSelection.customPages.length > 0 && !isEditingCustom) {
        setCustomPages(formatCustomPagesForDisplay(pageSelection.customPages));
      }
    }

    if (totalPages) {
      const count = calculateEffectivePageCount(pageSelection, totalPages);
      setEffectivePageCount(count);
    }
  }, [pageSelection, totalPages, isEditingRange, isEditingCustom]);

  // Helper function to format custom pages array into display string
  const formatCustomPagesForDisplay = (pages: number[]): string => {
    if (!pages || pages.length === 0) return '';
    
    // Sort pages for better display
    const sortedPages = [...pages].sort((a, b) => a - b);
    
    // Group consecutive pages into ranges
    const ranges: (number | [number, number])[] = [];
    let rangeStart = sortedPages[0];
    let prev = sortedPages[0];
    
    for (let i = 1; i < sortedPages.length; i++) {
      const current = sortedPages[i];
      if (current !== prev + 1) {
        if (rangeStart === prev) {
          ranges.push(rangeStart);
        } else {
          ranges.push([rangeStart, prev]);
        }
        rangeStart = current;
      }
      prev = current;
    }
    
    // Add the last range or number
    if (rangeStart === prev) {
      ranges.push(rangeStart);
    } else {
      ranges.push([rangeStart, prev]);
    }
    
    // Format ranges to string
    return ranges.map(range => {
      if (Array.isArray(range)) {
        return `${range[0]}-${range[1]}`;
      }
      return range.toString();
    }).join(',');
  };

  // Validate range input against page count
  const validateRange = (from: number, to: number): boolean => {
    if (!totalPages) return true;
    
    if (isNaN(from) || isNaN(to) || from < 1) {
      setRangeError('Start page must be at least 1');
      return false;
    }
    
    if (to < from) {
      setRangeError('End page must be greater than or equal to start page');
      return false;
    }
    
    if (to > totalPages) {
      setRangeError(`Document only has ${totalPages} pages`);
      return false;
    }
    
    setRangeError('');
    return true;
  };

  // Parse custom pages input safely
  const parseCustomPages = (input: string): number[] => {
    if (!input.trim()) return [];
    
    try {
      const result = input.split(',')
        .flatMap(part => {
          part = part.trim();
          if (!part) return [];
          
          if (part.includes('-')) {
            // Handle ranges like "5-10"
            const [startStr, endStr] = part.split('-').map(s => s.trim());
            const start = parseInt(startStr);
            const end = parseInt(endStr);
            
            if (isNaN(start) || isNaN(end) || start > end) {
              throw new Error(`Invalid range: ${part}`);
            }
            
            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
          } else {
            // Handle single pages
            const num = parseInt(part);
            if (isNaN(num)) {
              throw new Error(`Invalid page number: ${part}`);
            }
            return num;
          }
        })
        .filter(num => !isNaN(num) && num > 0);
      
      // Ensure values are unique and sorted
      return Array.from(new Set(result)).sort((a, b) => a - b);
    } catch (error) {
      setCustomPagesError(error instanceof Error ? error.message : 'Invalid format');
      return [];
    }
  };

  // Validate custom pages input against page count
  const validateCustomPages = (pages: number[]): boolean => {
    if (!totalPages) return true;
    
    if (pages.length === 0) {
      setCustomPagesError('Please enter at least one valid page number');
      return false;
    }
    
    const maxPage = Math.max(...pages);
    const minPage = Math.min(...pages);
    
    if (minPage < 1) {
      setCustomPagesError('Page numbers must be at least 1');
      return false;
    }
    
    if (maxPage > totalPages) {
      setCustomPagesError(`Document only has ${totalPages} pages`);
      return false;
    }
    
    setCustomPagesError('');
    return true;
  };

  // Handle page selection type change
  const handlePageSelectionChange = (type: 'all' | 'range' | 'custom') => {
    if (type === 'range') {
      // For range type, set default values if empty
      let from = parseInt(customRangeFrom) || 1;
      let to = parseInt(customRangeTo) || (totalPages || 1);
      
      // If changing to range type, set default values
      if (pageSelection.type !== 'range') {
        from = 1;
        to = totalPages || 1;
        setCustomRangeFrom(from.toString());
        setCustomRangeTo(to.toString());
      }
      
      // Only update if validation passes
      if (validateRange(from, to)) {
        const newSelection = {
          type,
          range: { start: from, end: to }
        };
        updatePageSelection(documentId, newSelection);
        
        // Update effective page count
        setEffectivePageCount(calculateEffectivePageCount(newSelection, totalPages));
      }
    } else if (type === 'custom') {
      // If changing to custom type, set default value
      if (pageSelection.type !== 'custom') {
        const defaultPage = '1';
        setCustomPages(defaultPage);
        
        const newSelection = {
          type,
          customPages: [1]
        };
        updatePageSelection(documentId, newSelection);
        setEffectivePageCount(1);
      } else if (customPages.trim()) {
        // Use existing custom pages if available
        try {
          const customPageArray = parseCustomPages(customPages);
          if (validateCustomPages(customPageArray)) {
            updatePageSelection(documentId, {
              type,
              customPages: customPageArray
            });
          }
        } catch (error) {
          // Handle errors silently
        }
      }
    } else {
      // All pages
      const newSelection = { type };
      updatePageSelection(documentId, newSelection);
      
      // Update effective page count
      setEffectivePageCount(calculateEffectivePageCount(newSelection, totalPages));
    }
  };
  
  // Handle range input changes - only update local state
  const handleRangeInputChange = (field: 'from' | 'to', value: string) => {
    setIsEditingRange(true);
    if (field === 'from') {
      setCustomRangeFrom(value);
    } else {
      setCustomRangeTo(value);
    }
  };
  
  // Submit range changes to store only when done editing
  const handleRangeInputBlur = () => {
    const from = parseInt(customRangeFrom) || 1;
    const to = parseInt(customRangeTo) || (totalPages || 1);
    
    if (validateRange(from, to)) {
      updatePageSelection(documentId, {
        type: 'range',
        range: { start: from, end: to }
      });
      
      setEffectivePageCount(calculateEffectivePageCount({
        type: 'range',
        range: { start: from, end: to }
      }, totalPages));
    }
    
    // Clear editing flag after a brief delay to ensure values are stable
    setTimeout(() => setIsEditingRange(false), 100);
  };
  
  // Handle custom pages input change - only update local state
  const handleCustomPagesChange = (value: string) => {
    setIsEditingCustom(true);
    setCustomPages(value);
  };
  
  // Submit custom pages to store only when done editing
  const handleCustomPagesBlur = () => {
    if (customPages.trim()) {
      try {
        const parsedPages = parseCustomPages(customPages);
        if (parsedPages.length > 0 && validateCustomPages(parsedPages)) {
          updatePageSelection(documentId, {
            type: 'custom',
            customPages: parsedPages
          });
          
          setEffectivePageCount(calculateEffectivePageCount({
            type: 'custom',
            customPages: parsedPages
          }, totalPages));
        }
      } catch (error) {
        // Just set error - don't update selection
        setCustomPagesError('Invalid format. Please use numbers and ranges (e.g., 1,3,5-7)');
      }
    }
    
    // Clear editing flag after a brief delay
    setTimeout(() => setIsEditingCustom(false), 100);
  };

  return (
    <div className="mb-3">
      <label className="block mb-1 font-medium text-sm text-gray-700">
        Pages{" "}
        {effectivePageCount > 0 && totalPages 
          ? `(${effectivePageCount} of ${totalPages} selected)` 
          : ""}
      </label>
      
      <div className="space-y-2 text-sm">
        <label className="flex items-center">
          <input 
            type="radio" 
            checked={pageSelection.type === 'all'}
            onChange={() => handlePageSelectionChange('all')}
            className="mr-2"
          />
          All Pages {totalPages ? `(1-${totalPages})` : ''}
        </label>
        
        <label className="flex items-center">
          <input 
            type="radio" 
            checked={pageSelection.type === 'range'}
            onChange={() => handlePageSelectionChange('range')}
            className="mr-2"
          />
          Page Range
        </label>
        
        {pageSelection.type === 'range' && (
          <div className="pl-5">
            <div className="flex items-center space-x-2">
              <input 
                type="number" 
                min="1"
                max={totalPages || undefined}
                placeholder="From" 
                className={`border ${rangeError ? 'border-red-300' : 'border-gray-300'} rounded w-16 px-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500`}
                value={customRangeFrom}
                onChange={e => handleRangeInputChange('from', e.target.value)}
                onFocus={() => setIsEditingRange(true)}
                onBlur={handleRangeInputBlur}
              />
              <span>to</span>
              <input 
                type="number" 
                min="1"
                max={totalPages || undefined}
                placeholder="To" 
                className={`border ${rangeError ? 'border-red-300' : 'border-gray-300'} rounded w-16 px-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500`}
                value={customRangeTo}
                onChange={e => handleRangeInputChange('to', e.target.value)}
                onFocus={() => setIsEditingRange(true)}
                onBlur={handleRangeInputBlur}
              />
            </div>
            {rangeError && <p className="text-xs text-red-500 mt-1">{rangeError}</p>}
            {totalPages > 0 && (
              <p className="text-xs text-gray-500 mt-1">
                Document has {totalPages} pages 
                {effectivePageCount > 0 && (
                  <span> • {effectivePageCount} will be printed</span>
                )}
              </p>
            )}
          </div>
        )}
        
        <label className="flex items-center">
          <input 
            type="radio" 
            checked={pageSelection.type === 'custom'}
            onChange={() => handlePageSelectionChange('custom')}
            className="mr-2"
          />
          Custom Pages
        </label>
        
        {pageSelection.type === 'custom' && (
          <div className="pl-5">
            <input 
              type="text" 
              placeholder="e.g. 1,3,5-7" 
              className={`border ${customPagesError ? 'border-red-300' : 'border-gray-300'} rounded w-full px-2 py-1 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500`}
              value={customPages}
              onChange={e => handleCustomPagesChange(e.target.value)}
              onFocus={() => setIsEditingCustom(true)}
              onBlur={handleCustomPagesBlur}
            />
            {customPagesError && <p className="text-xs text-red-500 mt-1">{customPagesError}</p>}
            <p className="text-xs text-gray-500 mt-1">
              Separate with commas, or use hyphens for ranges
              {totalPages > 0 && ` (max ${totalPages})`}
              {effectivePageCount > 0 && (
                <span> • {effectivePageCount} pages will be printed</span>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageSelection;
