import React from 'react';
import { Document, PrintSettings } from '../../types/documentTypes';
import DocumentInfo from './DocumentInfo';
import ColorModeSelector from './ColorModeSelector';
import SidesSelector from './SidesSelector';
import OrientationSelector from './OrientationSelector';
import PaperSizeSelector from './PaperSizeSelector';
import CopiesControl from './CopiesControl';
import PageSelection from './PageSelection';

interface PrintOptionsPanelProps {
  document: Document;
  settings: PrintSettings;
  onBack?: () => void;
}

const PrintOptionsPanel: React.FC<PrintOptionsPanelProps> = ({ document, settings, onBack }) => {
  // Default values for optional settings
  const orientation = settings.orientation || 'portrait';
  const paperType = settings.paperType || 'A4'; 
  const pageSelection = settings.pageSelection || { type: 'all' };

  return (
    <div className="space-y-4">
      <h3 className="font-medium mb-3 text-gray-700">Print Settings</h3>

      {/* Document info with name and page count */}
      <DocumentInfo document={document} pageSelection={settings.pageSelection} />

      {/* Color Settings */}
      <ColorModeSelector documentId={document.id} isColor={settings.color} />
      
      {/* Double-Sided Settings */}
      <SidesSelector documentId={document.id} isDoubleSided={settings.doubleSided} />

      {/* Orientation Settings */}
      <OrientationSelector documentId={document.id} orientation={orientation} />

      {/* Paper Type */}
      <PaperSizeSelector documentId={document.id} paperType={paperType} />

      {/* Copies */}
      <CopiesControl documentId={document.id} copies={settings.copies} />
      
      {/* Page Selection */}
      <PageSelection
        documentId={document.id}
        pageSelection={pageSelection}
        totalPages={document.pageCount || 0}
      />
      
      {/* Back button for mobile view */}
      {onBack && (
        <div className="md:hidden">
          <button 
            onClick={onBack}
            className="text-sm text-blue-600 mt-4"
          >
            ← Back to Preview
          </button>
        </div>
      )}
    </div>
  );
};

export default PrintOptionsPanel;
