// Import necessary dependencies and components
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import usePrintStore from '../../store/printStore';
import useDocumentGroupsStore from '../../store/documentGroupsStore';
import { FiFileText } from 'react-icons/fi';
import IconWrapper from './IconWrapper';
import { uploadPDF } from '../../lib/supabase/storage/upload-pdf';
import { calculateEffectivePageCount } from '../../utils/pageUtils';
import { trimPdfByPageSelection } from '../../services/pdfTrimService';
import { groupDocumentsBySettings, processMergeGroups } from '../../services/pdfMergeService';
import { calculateBWPrice, calculateColorPrice } from '../../utils/priceCalculations';
import DocumentListItem from './DocumentListItem';
import { DocumentGroup, UploadSummary } from '../../types/documentGroupTypes';
import { v4 as uuidv4 } from 'uuid';
import { uploadPrintJobs } from '../../services/uploadPrintJobsService';
import { useAuthStore } from '../../store/authStore';
import RazorPayService from '../../services/RazorPayService';
import { useWalletStore } from '../../store/walletStore';
import DocumentProcessingService from '../../services/DocumentProcessingService';
import { fetchWalletBalance } from '../../lib/supabase/api/fetch-wallet-balance';
import { usePaymentTypeStore } from '../../store/paymentTypeStore';
import { deleteFile } from '../../utils/indexedDBUtils';
import { FILE_STORAGE_PREFIX } from '../../utils/fileStorage';

// Interface definitions for type safety
interface TrimmedDocument {
  id: string;
  name: string;
  file: File;
  source?: 'print_bazar' | 'default'; // Add source to TrimmedDocument
}

interface PrintDocument {
  id: string;
  name: string;
  [key: string]: any;
}

interface PrintSettings {
  color: boolean;
  copies: number;
  doubleSided: boolean;
  effectivePageCount: number;
  orientation: 'portrait' | 'landscape';
  pageSelection: { type: string;[key: string]: any };
  paperType: string;
  [key: string]: any;
}

interface PrintStoreState {
  currentStep: number;
  documents: PrintDocument[];
  printSettings: {
    [documentId: string]: PrintSettings;
  };
  selectedDocumentId: string;
  selectedShop: string;
}

interface PrintStorage {
  state: PrintStoreState;
  version: number;
}

interface PaymentResult {
  success: boolean;
  error?: string;
  payment_id?: string;
  order_id?: string;
  amount?: number;
  [key: string]: any;
}

const OrderSummaryCard: React.FC = () => {
  // Hook into stores and get necessary state/functions
  const { user, session } = useAuthStore();
  const { documents, printSettings, setCurrentStep, updateEffectivePageCount, removeDocument } = usePrintStore();
  const {
    documentGroups,
    addGroup,
    clearGroups,
    generatePrintSettingsCode,
    setuploadFilePath
  } = useDocumentGroupsStore();
  const { balance, handleDeductMoney } = useWalletStore();
  const { setPaymentType } = usePaymentTypeStore();
  const navigate = useNavigate();

  // Local state management
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isComplete, setIsComplete] = useState(false);
  const [uploadSummary, setUploadSummary] = useState<UploadSummary | null>(null);
  const [databaseUploadComplete, setDatabaseUploadComplete] = useState(false);
  const [showWalletConfirm, setShowWalletConfirm] = useState(false);

  // Calculate effective page counts on document changes
  useEffect(() => {
    documents.forEach(doc => {
      if (!printSettings[doc.id]?.effectivePageCount) {
        updateEffectivePageCount(doc.id);
      }
    });
  }, [documents, printSettings, updateEffectivePageCount]);

  // Cleanup groups on unmount
  useEffect(() => {
    return () => {
      clearGroups();
    };
  }, [clearGroups]);

  // Helper function to calculate price for a single document
  const calculatePrice = (docId: string) => {
    const settings = printSettings[docId];
    if (!settings) return 0;

    let pageCount = settings.effectivePageCount || 1;
    if (!settings.effectivePageCount && settings.pageSelection) {
      const doc = documents.find(d => d.id === docId);
      if (doc && doc.pageCount) {
        pageCount = calculateEffectivePageCount(settings.pageSelection, doc.pageCount);
      }
    }

    const pricePerPage = settings.color
      ? calculateColorPrice(pageCount)
      : calculateBWPrice(pageCount);
    const copies = settings.copies || 1;

    return pricePerPage * pageCount * copies;
  };

  // Calculate total pages by type (color and B&W)
  const totalPageCounts = documents.reduce((counts, doc) => {
    const settings = printSettings[doc.id];
    if (!settings) return counts;

    const pageCount = settings.effectivePageCount || 1;
    const copies = settings.copies || 1;

    if (settings.color) {
      counts.color += pageCount * copies;
    } else {
      counts.bw += pageCount * copies;
    }

    return counts;
  }, { color: 0, bw: 0 });

  // Calculate costs for each file and total price
  const fileCosts = documents.map(doc => calculatePrice(doc.id));
  const totalPrice = fileCosts.reduce((sum, cost) => sum + cost, 0);

  // Create a human-readable summary of print settings
  const createSettingsSummary = (settings: any) => {
    const colorMode = settings.color ? 'Color' : 'B&W';
    const duplex = settings.doubleSided ? 'Double-sided' : 'Single-sided';
    const copies = settings.copies > 1 ? `${settings.copies} copies` : '1 copy';
    const paperInfo = `${settings.paperType}, ${settings.orientation}`;

    return `${colorMode}, ${duplex}, ${paperInfo}, ${copies}`;
  };

  // Calculate total pages for a group of documents
  const calculateGroupPageCount = (groupDocIds: string[], trimmedDocuments: TrimmedDocument[], printSettings: any) => {
    let totalPages = 0;

    for (const docId of groupDocIds) {
      const settings = printSettings[docId];
      if (!settings) continue;

      const pageCount = settings.effectivePageCount || 1;
      const copies = settings.copies || 1;
      totalPages += pageCount * copies;
    }

    return totalPages;
  };

  // Calculate total price for a group of documents
  const calculateGroupPrice = (groupDocIds: string[], printSettings: any) => {
    let groupPrice = 0;

    for (const docId of groupDocIds) {
      const settings = printSettings[docId];
      if (!settings) continue;

      const pageCount = settings.effectivePageCount || 1;
      const copies = settings.copies || 1;
      const pricePerPage = settings.color ? calculateColorPrice(pageCount) : calculateBWPrice(pageCount);

      groupPrice += pricePerPage * pageCount * copies;
    }

    return groupPrice;
  };

  const handleRazorpayClick = () => {
    setPaymentType('print_order');
    handleProceedToPayment();
  };

  const handleWalletClick = () => {
    setPaymentType('wallet');
    setShowWalletConfirm(true);
  };

  const handleProceedToPayment = async () => {
    if (isComplete) return;

    setIsUploading(true);
    setUploadError(null);
    clearGroups();
    console.log('=== Starting document upload process ===');

    try {
      console.log(`Documents to upload: ${documents.length}`, documents);

      const trimmedDocuments: TrimmedDocument[] = [];
      for (const doc of documents) {
        console.log(`Processing document: ${doc.name} (ID: ${doc.id})`);

        let file: File | null = null;

        if (doc.file) {
          console.log(`File found directly in document object`);
          file = doc.file;
        } else {
          console.log(`File not found in document, trying document file map...`);
          file = await usePrintStore.getState().getDocumentFile(doc.id);

          if (!file) {
            console.error(`Could not find file data for document: ${doc.name}`);
            throw new Error(`Could not find file data for document: ${doc.name}`);
          }
        }

        const settings = printSettings[doc.id];
        let trimmedFile = file;

        if (settings && (settings.pageSelection || settings.pageRange)) {
          console.log(`Trimming PDF based on page selection...`);
          const pageSelectionString = settings.pageSelection || settings.pageRange || '';

          try {
            trimmedFile = await trimPdfByPageSelection(file, pageSelectionString);
            console.log(`PDF trimmed successfully. New size: ${trimmedFile.size}`);
          } catch (trimError) {
            console.error(`Error trimming PDF:`, trimError);
            throw new Error(`Failed to trim PDF: ${trimError instanceof Error ? trimError.message : 'Unknown error'}`);
          }
        } else {
          console.log(`No page selection specified, using entire document.`);
        }

        trimmedDocuments.push({
          id: doc.id,
          name: doc.name,
          file: trimmedFile,
          source: doc.source // Pass the source from original document
        });
      }

      console.log(`All documents trimmed: ${trimmedDocuments.length}`);

      const documentGroups = groupDocumentsBySettings(trimmedDocuments, printSettings);
      console.log(`Grouped into ${documentGroups.length} settings groups:`, documentGroups);

      const groupPrices = new Map<string, number>();
      const groupPageCounts = new Map<string, number>();

      documentGroups.forEach(group => {
        const firstDocId = group.documentIds[0];
        const settings = printSettings[firstDocId];
        const firstDoc = trimmedDocuments.find(d => d.id === firstDocId);

        if (!settings) return;

        const settingsCode = generatePrintSettingsCode(settings);
        const settingsSummary = createSettingsSummary(settings);
        const groupPrice = calculateGroupPrice(group.documentIds, printSettings);
        const totalGroupPages = calculateGroupPageCount(group.documentIds, trimmedDocuments, printSettings);
        const groupId = uuidv4();

        groupPrices.set(groupId, groupPrice);
        groupPageCounts.set(groupId, totalGroupPages);

        const enhancedGroup: DocumentGroup = {
          id: groupId,
          documents: group.documentIds.map(id => {
            const doc = trimmedDocuments.find(d => d.id === id);
            return {
              id: id,
              name: doc?.name || 'Unknown',
              file: doc?.file as File
            };
          }),
          source: trimmedDocuments.find(d => d.id === group.documentIds[0])?.source || 'default', // Get source from first document
          printSettingsId: firstDocId,
          printSettings: settings,
          settingsSummary,
          printSettingsCode: settingsCode,
          totalPages: totalGroupPages,
          price: groupPrice
        };

        addGroup(enhancedGroup);
      });

      const { mergedFiles, mergeInfo } = await processMergeGroups(documentGroups);
      console.log(`After merging: ${mergedFiles.length} files to upload, ${mergeInfo.length} merge operations`);

      if (mergeInfo.length > 0) {
        const mergeMessages = mergeInfo.map(info =>
          `• ${info.docNames.join(", ")} merged with ${info.settings} settings`
        );

        const message = "Some documents with identical print settings have been merged to optimize your print job:\n\n" +
          mergeMessages.join("\n");

        console.log("Showing merge notification:", message);

        setTimeout(() => {
          window.alert(message);
        }, 100);
      } else {
        console.log("No documents were merged - all have unique settings");
      }

      const currentGroups = useDocumentGroupsStore.getState().documentGroups;
      console.log('Preparing to upload document groups:', currentGroups);

      const uploadPromises = currentGroups.map(async (group, index) => {
        const fileToUpload = mergedFiles[index] || group.documents[0].file;
        const fileName = fileToUpload.name;

        console.log(`[${index}] Starting upload of ${fileName} to Supabase...`, {
          groupId: group.id,
          settingsCode: group.printSettingsCode,
          settingsSummary: group.settingsSummary,
          totalPages: group.totalPages || groupPageCounts.get(group.id) || 0,
          price: group.price || groupPrices.get(group.id) || 0
        });

        const groupFileName = `order-${Date.now()}-${fileName}`;
        try {
          const filePath = await uploadPDF(fileToUpload, 'pdfs', groupFileName);
          console.log(`[${index}] Upload successful. filePath: ${filePath}`);

          setuploadFilePath(group.id, filePath);

          return {
            success: true,
            filePath,
            groupId: group.id,
            groupFileName: groupFileName,
            printSettingsCode: group.printSettingsCode,
            documentNames: group.documents.map(d => d.name),
            settingsSummary: group.settingsSummary,
            totalPages: group.totalPages || groupPageCounts.get(group.id) || 0,
            price: group.price || groupPrices.get(group.id) || 0
          };
        } catch (uploadError) {
          console.error(`[${index}] Upload failed:`, uploadError);
          return {
            success: false,
            error: uploadError,
            documentNames: group.documents.map(d => d.name)
          };
        }
      });

      console.log('All upload promises created, waiting for resolution...');
      const results = await Promise.all(uploadPromises);

      const successful = results.filter(r => r.success);
      const failed = results.filter(r => !r.success);

      console.log(`Upload summary: ${successful.length} successful, ${failed.length} failed`);

      if (failed.length > 0) {
        console.error('Failed uploads:', failed);
        throw new Error(`${failed.length} document(s) failed to upload`);
      }

      const finalGroups = useDocumentGroupsStore.getState().documentGroups;
      const summary: UploadSummary = {
        source: 'pdfs',
        totalGroups: finalGroups.length,
        totalDocuments: documents.length,
        totalPages: {
          color: totalPageCounts.color,
          bw: totalPageCounts.bw,
          total: totalPageCounts.color + totalPageCounts.bw
        },
        totalPrice: totalPrice,
        uploadedGroups: successful.map(s => ({
          groupId: s.groupId!,
          groupFileName: s.groupFileName!,
          printSettingsCode: s.printSettingsCode!,
          documentNames: s.documentNames!,
          settingsSummary: s.settingsSummary!,
          uploadFilePath: s.filePath!,
          totalPages: s.totalPages || 0,
          price: s.price || 0
        }))
      };

      setUploadSummary(summary);

      console.log('=== ORDER DATA FOR DATABASE INTEGRATION ===');
      console.log('Upload Summary:', JSON.stringify(summary, null, 2));
      console.log('Document Groups:', finalGroups);
      console.log('Shop Info:', usePrintStore.getState().selectedShop);
      console.log('=== END OF ORDER DATA ===');

      console.log('Summary:', summary);

      const printStorageData = JSON.parse(localStorage.getItem('print-storage') || '{}') as PrintStorage;

      const documents_ = printStorageData.state?.documents || [];
      const printSettings_ = printStorageData.state?.printSettings || {};

      const uploadedFiles = documents_.map((doc: PrintDocument) => {
        return {
          id: doc.id,
          name: doc.name,
          settings: printSettings_[doc.id] || {
            color: false,
            copies: 1,
            doubleSided: false,
            orientation: 'portrait',
            paperType: 'A4',
            pageSelection: { type: 'all' }
          }
        };
      });
      console.log('uploadedFiles:', uploadedFiles);

      const paymentType = usePaymentTypeStore.getState().paymentType;
      const orderAmount = totalPrice;
      const selectedShop = usePrintStore.getState().selectedShop;

      if (!user) {
        throw new Error('User information is required for payment');
      }

      const userId = user.id;
      const orderSpecificData = {
        session: session?.access_token || '',
        orderSummary: [summary],
        userInfo: user.user_metadata,
        fileCosts: fileCosts,
        totalCost: orderAmount,
        shop_id: selectedShop
      };

      if (paymentType === 'print_order') {
        const orderNotes = {};
        const orderData = await RazorPayService.createOrderId(
          orderAmount,
          'ORDER',
          userId,
          'INR',
          orderNotes
        );

        const paymentResult = await RazorPayService.processOrderPayment(
          orderData.orderId,
          orderAmount,
          userId,
          {
            prefill: {
              name: user?.user_metadata.name,
              email: user?.user_metadata.email,
              contact: user?.phone || '',
              shop_id: selectedShop
            }
          },
          orderSpecificData
        ) as PaymentResult;

        if (!paymentResult.success) {
          setUploadError('Payment failed: ' + paymentResult.error);
          return;
        }
      } else if (paymentType === 'wallet') {
        const walletBalance = await fetchWalletBalance(userId);
        if (!walletBalance || walletBalance < orderAmount) {
          alert('Insufficient wallet balance');
          return;
        }

        const success = await handleDeductMoney(orderAmount, orderSpecificData);
        if (!success) {
          setUploadError('Wallet payment failed');
          return;
        }
      }

      try {
        console.log('Starting database upload...');
        const result = await uploadPrintJobs(totalPrice);

        if (result.success) {
          console.log('All print jobs uploaded to database successfully:', result);
          setDatabaseUploadComplete(true);
          setIsComplete(true);
          alert(`All ${result.totalUploaded} print jobs have been submitted successfully!`);
        } else {
          console.error('Some print jobs failed to upload:', result.failedUploads);
          if (result.totalUploaded > 0) {
            alert(`${result.totalUploaded} print jobs uploaded, but ${result.failedUploads.length} failed. Please try again later.`);
          } else {
            throw new Error('Failed to upload any print jobs to the database');
          }
        }
      } catch (dbError) {
        console.error('Error uploading to database:', dbError);
        setUploadError(`Print job submission failed: ${dbError instanceof Error ? dbError.message : 'Unknown error'}`);
      }

      if (!uploadError) {
        alert('Documents uploaded successfully! Proceeding to payment gateway...');
      }

    } catch (error) {
      console.error('Error in upload process:', error);

      if (error instanceof Error) {
        setUploadError(`Upload failed: ${error.message}`);
      } else {
        setUploadError('Upload failed with an unknown error');
      }
    } finally {
      setIsUploading(false);
      console.log('=== Document upload process completed ===');

      // Cleanup after success
      // 1. Clear IndexedDB files
      for (const doc of documents) {
        const key = `${FILE_STORAGE_PREFIX}${doc.id}`;
        await deleteFile(key);
        console.log('Cleared IndexedDB file:', key);
      }
      // 2. Remove documents from print store
      for (const doc of documents) {
        removeDocument(doc.id);
        console.log('Removed document from store:', doc.id);
      }
      // 3. Reset current step and navigate
      setCurrentStep(0);
      console.log('Reset current step to 0');
      navigate('/my-account?tab=orders');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl mx-auto">
      <h2 className="text-2xl font-semibold mb-4 text-blue-600">Order Summary</h2>

      <div className="mb-6">
        <div className="flex justify-center mb-3">
          <IconWrapper icon={FiFileText} />
        </div>

        <p className="text-gray-500 mb-4">Review your order before proceeding to payment</p>

        <div className="border-t border-b border-gray-200 py-4 mb-4">
          <h3 className="font-medium mb-2">Documents</h3>
          <ul className="space-y-2">
            {documents.map(doc => {
              const settings = printSettings[doc.id];
              if (!settings) return null;
              return (
                <DocumentListItem
                  key={doc.id}
                  doc={doc}
                  settings={settings}
                />
              );
            })}
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="font-medium mb-2">Page Summary</h3>
          <div className="text-sm text-gray-600">
            <p>Black & White: {totalPageCounts.bw} pages (₹3 per page)</p>
            <p>Color: {totalPageCounts.color} pages (₹10 per page)</p>
            <p>Total Pages: {totalPageCounts.bw + totalPageCounts.color}</p>
          </div>
        </div>

        <div className="flex justify-between font-bold text-lg">
          <span>Total</span>
          <span>₹{totalPrice.toFixed(2)}</span>
        </div>

        {uploadError && (
          <div className="mt-3 text-red-600 text-center">
            {uploadError}
          </div>
        )}
      </div>

      <div className="flex flex-col-reverse sm:flex-row sm:justify-between gap-2">
        <button
          onClick={() => setCurrentStep(2)}
          className="w-full sm:w-auto px-6 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg"
          disabled={isUploading}
        >
          Back
        </button>
        <div className="flex flex-col sm:flex-row gap-2">
          <button
            onClick={handleWalletClick}
            className="w-full sm:w-auto px-6 py-2 bg-white hover:bg-gray-200 text-blue-600 rounded-lg border border-blue-600"
            disabled={isUploading || isComplete}
          >
            Pay via Wallet
          </button>
          <button
            onClick={handleRazorpayClick}
            className="w-full sm:w-auto px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
            disabled={isUploading || isComplete}
          >
            {isUploading ? 'Processing...' : isComplete ? 'Completed' : 'Pay via Razorpay'}
          </button>
        </div>
      </div>

      {showWalletConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full m-4">
            <h3 className="text-xl font-semibold mb-4">Confirm Wallet Payment</h3>
            <div className="space-y-3">
              <p>Amount to be deducted: ₹{totalPrice.toFixed(2)}</p>
              <p>Available balance: ₹{balance ? balance?.toFixed(2) : '0.00'}</p>
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-4">
                <p className="text-sm text-yellow-700">
                  ⚠️ Clicking 'Confirm Order' will deduct money from your wallet and place the order. This action cannot be undone.
                </p>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => setShowWalletConfirm(false)}
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleProceedToPayment}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded"
              >
                Confirm Order
              </button>
            </div>
          </div>
        </div>
      )}

      {isComplete && (
        <div className={`mt-4 text-center ${databaseUploadComplete ? 'text-green-600' : uploadError ? 'text-red-600' : 'text-blue-600'}`}>
          {databaseUploadComplete
            ? '✅ Print jobs submitted successfully'
            : uploadError
              ? `❌ ${uploadError}`
              : '⏳ Finalizing print job submission...'}
        </div>
      )}
    </div>
  );
};

export default OrderSummaryCard;
