import { supabase } from '../client/supabaseClient';

/**
 * Fetches the current user's purchases from the database.
 * Calls the 'fetch_my_purchases' RPC in the 'api' schema.
 */
export async function fetchMyPurchases() {
    try {
        const { data, error } = await supabase
            .schema('api')
            .rpc('fetch_my_purchases');

        if (error) {
            console.error('Error fetching purchases:', error);
            return [];
        }

        return data; // Returns an array of purchases
    } catch (error) {
        console.error('Unexpected error in fetchMyPurchases:', error);
        return [];
    }
}
