import { create } from 'zustand';

type PaymentType = 'wallet' | 'print_order' | 'print_bazaar_purchase' | null;

interface PaymentTypeStore {
  paymentType: PaymentType;
  setPaymentType: (type: PaymentType) => void;
  resetPaymentType: () => void;
}

export const usePaymentTypeStore = create<PaymentTypeStore>((set) => ({
  paymentType: null,
  setPaymentType: (type) => set({ paymentType: type }),
  resetPaymentType: () => set({ paymentType: null }),
}));
