import { supabase } from '../client/supabaseClient';
import { PrintBazaarDoc } from '../../../types/documentTypes';

export async function fetchDocumentDetails(docId: string): Promise<PrintBazaarDoc | null> {
  try {
    const { data, error } = await supabase
      .schema('api')
      .rpc('fetch_document_details', {
        _doc_id: docId
      });
    
    if (error) {
      console.error('Error fetching document details:', error);
      return null;
    }

    // Data will be an array with one item
    const document = data?.[0];
    
    if (!document) {
      console.error('No document found');
      return null;
    }

    // Parse JSON fields
    const parsedDoc = {
      ...document,
      preview_pages: Array.isArray(document.preview_pages) 
        ? document.preview_pages 
        : JSON.parse(document.preview_pages || '[]'),
      tags: Array.isArray(document.tags) 
        ? document.tags 
        : JSON.parse(document.tags || '[]')
    };

    console.log('Parsed document:', parsedDoc);
    return parsedDoc as PrintBazaarDoc;
  } catch (error) {
    console.error('Unexpected error in fetchDocumentDetails:', error);
    return null;
  }
}
