import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import usePrintStore from '../../store/printStore';
import { FiMapPin } from 'react-icons/fi';
import IconWrapper from '../print-steps/IconWrapper';
import { useAuthStore } from '../../store/authStore';
import { Shop, searchShops, getNearestShops, getAllShops } from '../../lib/supabase/api/shopService';
import useDebounce from '../../hooks/useDebounce';

// Import sub-components
import SearchInput from './SearchInput';
import LocationButton from './LocationButton';
import ShopList from './ShopList';

// Add this prop to receive location permission response from parent
interface ShopSelectionCardProps {
  showLocationModal?: (show: boolean) => void;
  locationResponse?: boolean | null;
}

const ShopSelectionCard: React.FC<ShopSelectionCardProps> = ({ 
  showLocationModal, 
  locationResponse 
}) => {
  const navigate = useNavigate();
  const { selectedShop, setSelectedShop, setCurrentStep, documents, removeDocument } = usePrintStore();
  const { user, signInWithGoogle, loading, setPreviousPrintStep } = useAuthStore();
  const [isRedirecting, setIsRedirecting] = useState(false);
  
  // Shop state
  const [shops, setShops] = useState<Shop[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadingShops, setIsLoadingShops] = useState(false);
  
  // Location state
  const [locationShared, setLocationShared] = useState(false);
  const [pendingLocationRequest, setPendingLocationRequest] = useState(false);
  
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const initialLoadCompleted = useRef(false);
  
  // Initial load - only run once
  useEffect(() => {
    if (initialLoadCompleted.current) return;
    initialLoadCompleted.current = true;
    
    // Check if geolocation is in permissions state
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        console.log('Initial geolocation permission state:', permissionStatus.state);
        
        if (permissionStatus.state === 'granted') {
          // User has already granted permission before
          setLocationShared(true);
          requestUserLocation();
          return;
        }
        
        // Show modal for initial location request
        if (showLocationModal) {
          setPendingLocationRequest(true);
          showLocationModal(true);
        } else {
          // No modal available, just load all shops
          loadAllShops();
        }
      });
    } else {
      // Permissions API not available, try direct approach
      if (showLocationModal) {
        setPendingLocationRequest(true);
        showLocationModal(true);
      } else {
        loadAllShops();
      }
    }
  }, []);

  // Handle location response from modal
  useEffect(() => {
    if (locationResponse === null || !pendingLocationRequest) return;
    
    setPendingLocationRequest(false);
    
    if (locationResponse === true) {
      setLocationShared(true);
      requestUserLocation();
    } else {
      setLocationShared(false);
      loadAllShops();
    }
  }, [locationResponse]);

  // This function directly requests user location from browser
  const requestUserLocation = () => {
    setIsLoadingShops(true);
    
    if (!navigator.geolocation) {
      loadAllShops();
      return;
    }
    
    // Clear any existing timeout
    const timeoutId = setTimeout(() => {
      setIsLoadingShops(false);
      loadAllShops();
    }, 15000); // 15 second timeout
    
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        clearTimeout(timeoutId);
        try {
          const nearestShops = await getNearestShops(
            position.coords.latitude, 
            position.coords.longitude
          );
          setShops(nearestShops);
          setLocationShared(true);
        } catch (error) {
          console.error("Error getting nearest shops:", error);
          loadAllShops();
        } finally {
          setIsLoadingShops(false);
        }
      },
      (error) => {
        clearTimeout(timeoutId);
        console.error("Geolocation error:", error);
        
        // If user denied permission in browser dialog
        if (error.code === error.PERMISSION_DENIED) {
          setLocationShared(false);
        }
        
        loadAllShops();
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );
  };

  // Search handler
  useEffect(() => {
    if (!debouncedSearchQuery) {
      // If search is cleared, go back to nearest shops or all shops
      if (locationShared) {
        requestUserLocation();
      } else {
        loadAllShops();
      }
      return;
    }
    
    const performSearch = async () => {
      setIsLoadingShops(true);
      try {
        const results = await searchShops(debouncedSearchQuery);
        setShops(results);
      } catch (error) {
        console.error("Search error:", error);
      } finally {
        setIsLoadingShops(false);
      }
    };
    
    performSearch();
  }, [debouncedSearchQuery]);

  const loadAllShops = async () => {
    setIsLoadingShops(true);
    try {
      const allShops = await getAllShops();
      setShops(allShops);
    } catch (error) {
      console.error("Error loading shops:", error);
      setShops([]);
    } finally {
      setIsLoadingShops(false);
    }
  };

  const handleUseCurrentLocation = () => {
    // Avoid multiple requests
    if (pendingLocationRequest || isLoadingShops) return;
    
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          // Permission already granted, just get location
          requestUserLocation();
        } else if (permissionStatus.state === 'prompt') {
          // Will prompt, show our modal first
          if (showLocationModal) {
            setPendingLocationRequest(true);
            showLocationModal(true);
          } else {
            requestUserLocation();
          }
        } else {
          // Permission denied previously, show message
          alert('Please enable location access in your browser settings to use this feature.');
          loadAllShops();
        }
      });
    } else {
      // No permissions API, try direct request
      if (showLocationModal) {
        setPendingLocationRequest(true);
        showLocationModal(true);
      } else {
        requestUserLocation();
      }
    }
  };

  const handleBack = () => {
    const isPrintBazaar = documents.some(doc => doc.source === 'print_bazar');
    
    if (isPrintBazaar) {
      // For print_bazar flow: navigate to MyAccounts purchases tab
      navigate('/my-account?tab=purchases');
      // Remove documents and reset step
      documents.forEach(doc => removeDocument(doc.id));
      setCurrentStep(0);
    } else {
      // For normal flow: go back to print settings
      setCurrentStep(1);
    }
  };

  const handleContinue = () => {
    if (user) {
      // User is authenticated, continue to next step
      setCurrentStep(3);
    } else {
      // User is not authenticated, save current step and redirect to sign in
      setPreviousPrintStep(2);
      setIsRedirecting(true);
      signInWithGoogle();
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl mx-auto">
      <h2 className="text-2xl font-semibold mb-4 text-blue-600">Select Print Shop</h2>

      <div className="mb-6">
        <div className="flex justify-center mb-3">
          <IconWrapper icon={FiMapPin} />
        </div>
        <p className="text-gray-500 mb-4">Choose where you'd like to pick up your prints</p>
        
        {/* Search input component */}
        <SearchInput 
          searchQuery={searchQuery} 
          setSearchQuery={setSearchQuery} 
        />
        
        {/* Location button component */}
        <LocationButton 
          locationShared={locationShared}
          pendingLocationRequest={pendingLocationRequest}
          isLoadingShops={isLoadingShops}
          onRequestLocation={handleUseCurrentLocation}
        />
        
        {/* Shop list component */}
        <ShopList 
          shops={shops}
          isLoading={isLoadingShops}
          selectedShopId={selectedShop}
          setSelectedShop={setSelectedShop}
          locationShared={locationShared}
        />
      </div>

      {!user && (
        <div className="mb-6 p-4 bg-yellow-50 border border-yellow-300 rounded-md">
          <p className="text-yellow-800">
            <strong>Note:</strong> You need to sign in to proceed to checkout.
          </p>
        </div>
      )}

      <div className="flex justify-between">
        <button
          onClick={handleBack}
          className="px-6 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg"
        >
          Back
        </button>
        <button
          onClick={handleContinue}
          className={`px-6 py-2 rounded-lg ${
            loading || isRedirecting || isLoadingShops
              ? 'bg-gray-400 cursor-wait'
              : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
          disabled={loading || isRedirecting || isLoadingShops}
        >
          {loading || isRedirecting 
            ? 'Please wait...' 
            : user 
              ? 'Continue' 
              : 'Sign in to Continue'
          }
        </button>
      </div>
    </div>
  );
};

export default ShopSelectionCard;
