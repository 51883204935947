import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { initPdfWorker } from './utils/pdfUtils';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

// Initialize PDF.js worker before the app renders
initPdfWorker();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


