import WalletPaymentService from './WalletPaymentService';
import RazorPayService from './RazorPayService';

interface PaymentResult {
    success: boolean;
    error?: string;
    payment_id?: string;
    order_id?: string;
    amount?: number;
}

class PrintBazaarPaymentService {
    async handleWalletPayment(userId: string, docId: string, amount: number, userInfo: any, paymentType: 'print_bazaar_purchase' | 'print_order') {
        try {
            const deductSuccess = await WalletPaymentService.deductWalletBalance(userId, amount);
            if (!deductSuccess) {
                throw new Error('Failed to deduct wallet balance');
            }

            await WalletPaymentService.addWalletTransaction(userId, amount, paymentType);

            // Create Razorpay order for record keeping
            const orderResult = await RazorPayService.createOrderId(
                amount,
                'ORDER',
                userId,
                'INR'
            );

            return {
                success: true,
                orderId: orderResult.orderId
            };
        } catch (error) {
            console.error('Wallet payment failed:', error);
            return { success: false };
        }
    }

    async handleRazorpayPayment(amount: number, userId: string, docId: string, userInfo: any) {
        try {
            const orderData = await RazorPayService.createOrderId(
                amount,
                'PRINT_BAZAAR',
                userId,
                'INR'
            );

            const paymentResult = await RazorPayService.processPrintBazaarPayment(
                orderData.orderId,
                amount,
                userId,
                {
                    author_id: userInfo.author_id,
                    document_id: docId,
                    totalCost: amount
                },
                {
                    prefill: {
                        name: userInfo.name,
                        email: userInfo.email,
                    }
                }
            ) as PaymentResult;

            return {
                success: paymentResult.success,
                orderId: orderData.orderId
            };
        } catch (error) {
            console.error('Razorpay payment failed:', error);
            return { success: false };
        }
    }
}

export default new PrintBazaarPaymentService();
