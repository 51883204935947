import React from 'react';
import usePrintStore from '../../store/printStore';

interface SidesSelectorProps {
  documentId: string;
  isDoubleSided: boolean;
}

const SidesSelector: React.FC<SidesSelectorProps> = ({ documentId, isDoubleSided }) => {
  const { updatePrintSettings } = usePrintStore();
  
  return (
    <div className="mb-3">
      <label className="block mb-1 font-medium text-sm text-gray-700">Sides</label>
      <select
        value={isDoubleSided ? 'double' : 'single'}
        onChange={e => updatePrintSettings(documentId, { doubleSided: e.target.value === 'double' })}
        className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
      >
        <option value="single">Single-Sided</option>
        <option value="double">Double-Sided</option>
      </select>
    </div>
  );
};

export default SidesSelector;
