import React from 'react';
import usePrintStore from '../../store/printStore';

// Define allowed paper types
type PaperType = 'A4' | 'Letter';

interface PaperSizeSelectorProps {
  documentId: string;
  paperType: string; // Use string to match PrintSettings
}

const PaperSizeSelector: React.FC<PaperSizeSelectorProps> = ({ documentId, paperType }) => {
  const { updatePrintSettings } = usePrintStore();
  
  // Validate the paper type or default to 'A4'
  const validPaperType = (paperType === 'A4' || paperType === 'Letter') ? paperType : 'A4';

  const handlePaperTypeChange = (newPaperType: PaperType) => {
    updatePrintSettings(documentId, { paperType: newPaperType });
  };

  return (
    <div className="mb-3">
      <label className="block mb-1 font-medium text-sm text-gray-700">Paper Size</label>
      <select
        value={validPaperType}
        onChange={e => handlePaperTypeChange(e.target.value as PaperType)}
        className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
      >
        <option value="A4">A4</option>
        <option value="Letter">Letter</option>
      </select>
    </div>
  );
};

export default PaperSizeSelector;
