import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { fetchMyPurchases } from '../../lib/supabase/api/fetch-my-purchases';
import { fetchDocumentDetails } from '../../lib/supabase/api/fetch-document-details';
import { PrintBazaarDoc } from '../../types/documentTypes';
import PrintSettingsDialog from '../../modules/print-settings/PrintSettingsDialog';
import { usePrintNavigation } from '../../hooks/usePrintNavigation';
import usePrintStore from '../../store/printStore';

interface Purchase {
  id: string;
  date: string;
  item: string;
  type: string;
  price: number;
  currency: string;
  doc_id: string;
}

const MyPurchases: React.FC = () => {
  const navigate = useNavigate();
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPrintSettings, setShowPrintSettings] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<PrintBazaarDoc | null>(null);
  const { navigateToPrintFlow } = usePrintNavigation();
  const { removeDocument } = usePrintStore();

  useEffect(() => {
    const loadPurchases = async () => {
      try {
        const data = await fetchMyPurchases();
        setPurchases(data);
      } catch (err) {
        setError('Failed to load purchases');
      } finally {
        setIsLoading(false);
      }
    };
    loadPurchases();
  }, []);

  const handlePrintClick = async (docId: string) => {
    try {
      const doc = await fetchDocumentDetails(docId);
      if (doc) {
        // Clear any existing documents first
        usePrintStore.getState().documents.forEach(doc => {
          removeDocument(doc.id);
        });
        setSelectedDocument(doc);
        setShowPrintSettings(true);
      }
    } catch (error) {
      console.error('Error fetching document details:', error);
    }
  };

  const handlePrintSettingsClose = () => {
    setShowPrintSettings(false);
    // Navigate to print flow if dialog was closed via Continue button
    if (usePrintStore.getState().currentStep === 2) {
      navigateToPrintFlow(2);
    }
  };

  if (isLoading) return <div className="text-center p-4">Loading purchases...</div>;
  if (error) return <div className="text-red-500 p-4">{error}</div>;

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4 text-blue-600">My Purchases</h2>

      {purchases.length > 0 ? (
        <div className="space-y-4">
          {purchases.map(purchase => (
            <Card 
              key={purchase.id} 
              className="shadow-sm border border-gray-100 cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => navigate(`/print-bazaar/${purchase.doc_id}`)}
            >
              <CardHeader className="pb-2">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                  <CardTitle className="text-base sm:text-lg text-gray-800">{purchase.item}</CardTitle>
                  <span className="text-blue-600 font-medium mt-2 sm:mt-0">
                    ₹{purchase.price}
                  </span>
                </div>
                <CardDescription>
                  {new Date(purchase.date).toLocaleDateString()} • {purchase.type}
                </CardDescription>
              </CardHeader>
              <CardFooter className="flex flex-wrap justify-end gap-2 pt-0 pb-3">
                <Button 
                  variant="outline" 
                  size="sm" 
                  className="border-blue-600 text-blue-600 hover:bg-blue-50"
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrintClick(purchase.doc_id);
                  }}
                >
                  Print
                </Button>
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="text-gray-600 hover:text-blue-600 hover:bg-blue-50"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Receipt
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      ) : (
        <Card className="p-8 text-center shadow-sm border border-gray-100">
          <p className="text-gray-500 mb-4">You haven't made any purchases yet.</p>
          <Button className="bg-blue-600 hover:bg-blue-700 text-white">Browse PrintBazaar</Button>
        </Card>
      )}

      {selectedDocument && (
        <PrintSettingsDialog
          isOpen={showPrintSettings}
          onClose={handlePrintSettingsClose}
          documentId={selectedDocument.id}
          bazaarDoc={selectedDocument}
        />
      )}
    </div>
  );
};

export default MyPurchases;
