/* eslint-disable @typescript-eslint/no-unused-vars */
import { supabase } from '../client/supabaseClient';
import axios from 'axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;
// Define shop interface
export interface Shop {
  id: string;
  name: string;
  location: string;
  distance?: number; // Optional distance property when using nearest shops
}

/**
 * Fetch shops based on search query
 */
export const searchShops = async (query: string): Promise<Shop[]> => {
  console.log('shopService: Searching shops with query:', query);
  try {
    const { data, error } = await supabase.schema('api')
                                         .rpc('search_shops', 
    {
      shop_name: query
    });

    console.log('shopService: Search results:', data);

    if (error) {
      console.error('shopService: Error searching shops:', error);
      return [];
    }

    return data || [];
  } catch (error) {
    console.error('shopService: Exception searching shops:', error);
    return [];
  }
};

/**
 * Fetch nearest shops based on user coordinates and optional search term
 */
//export const getNearestShops = async (
//  latitude: number,
//  longitude: number,
//  searchTerm: string = ''
//): Promise<Shop[]> => {
//  console.log('shopService: Getting nearest shops at:', latitude, longitude, 'with term:', searchTerm);
//  try {
//    const { data, error } = await supabase.schema('api')
//                                         .rpc('search_nearest_shops', {
//      user_lat: latitude,
//      user_lon: longitude,
//      shop_name: searchTerm
//    });
//
//    if (error) {
//      console.error('shopService: Error fetching nearest shops:', error);
//      return [];
//    }
//
//    console.log('shopService: Nearest shops results:', data);
//    return data || [];
//  } catch (error) {
//    console.error('shopService: Exception fetching nearest shops:', error);
//    return [];
//  }
//};
export const getNearestShops = async (latitude: number, longitude: number): Promise<Shop[]> => {
  const { data } = await axios.get(`${backendUrl}/shops`, {
      params: { lat: latitude, lon: longitude }
  });
  console.log ('shopService: Nearest shops results:', data);
  return data;
};

/**
 * Fetch all shops (used as fallback)
 */
export const getAllShops = async (): Promise<Shop[]> => {
  console.log('shopService: Getting all shops');
  try {
    const { data, error } = await supabase.schema('api')
                                         .rpc('search_shops', {
      shop_name: '' // Empty string to match all shops
    });

    if (error) {
      console.error('shopService: Error fetching all shops:', error);
      return [];
    }

    console.log('shopService: All shops results:', data);
    return data || [];
  } catch (error) {
    console.error('shopService: Exception fetching all shops:', error);
    return [];
  }
};