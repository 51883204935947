import React, { useState, useEffect } from 'react';
import { Document, PageSelection } from '../../types/documentTypes';
import { calculateEffectivePageCount } from '../../utils/pageUtils';

interface DocumentInfoProps {
  document: Document;
  pageSelection: PageSelection | undefined;
}

const DocumentInfo: React.FC<DocumentInfoProps> = ({ document, pageSelection }) => {
  const [effectivePageCount, setEffectivePageCount] = useState<number>(0);

  useEffect(() => {
    if (document.pageCount && pageSelection) {
      const count = calculateEffectivePageCount(
        pageSelection,
        document.pageCount
      );
      setEffectivePageCount(count);
    } else if (document.pageCount) {
      // Default to all pages if pageSelection is undefined
      setEffectivePageCount(document.pageCount);
    }
  }, [document.pageCount, pageSelection]);

  return (
    <div className="bg-blue-50 rounded p-2 mb-3">
      <p className="text-sm font-medium text-blue-700 truncate" title={document.name}>
        {document.name}
      </p>
      {document.pageCount && (
        <div className="flex justify-between items-center text-xs text-blue-500 mt-1">
          <span>Total: {document.pageCount} pages</span>
          <span>Selected: {effectivePageCount} pages</span>
        </div>
      )}
    </div>
  );
};

export default DocumentInfo;
