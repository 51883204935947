import React from 'react';
import usePrintStore from '../../store/printStore';

interface ColorModeSelectorProps {
  documentId: string;
  isColor: boolean;
}

const ColorModeSelector: React.FC<ColorModeSelectorProps> = ({ documentId, isColor }) => {
  const { updatePrintSettings } = usePrintStore();
  
  return (
    <div className="mb-3">
      <label className="block mb-1 font-medium text-sm text-gray-700">Print Mode</label>
      <select
        value={isColor ? 'color' : 'bw'}
        onChange={e => updatePrintSettings(documentId, { color: e.target.value === 'color' })}
        className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500 text-sm"
      >
        <option value="color">Color Print</option>
        <option value="bw">Black & White</option>
      </select>
    </div>
  );
};

export default ColorModeSelector;
