import { addPrintJob } from '../lib/supabase/api/add-print-job';
import useDocumentGroupsStore from '../store/documentGroupsStore';
import usePrintStore from '../store/printStore';

/**
 * Interface for the result of uploading print jobs
 */
interface UploadPrintJobsResult {
  success: boolean;
  totalUploaded: number;
  failedUploads: Array<{
    groupId: string;
    error: string;
  }>;
  successfulUploads: Array<{
    groupId: string;
    settingsCode: number;
  }>;
}

/**
 * Uploads all document groups as print jobs to the database
 * Each document group becomes a separate print job
 */
export async function uploadPrintJobs(totalPrice: number): Promise<UploadPrintJobsResult> {
  // Get document groups from the store
  const documentGroups = useDocumentGroupsStore.getState().documentGroups;
  
  // Get selected shop from the print store
  const selectedShop = usePrintStore.getState().selectedShop;
  
  // If no shop is selected, we can't proceed
  if (!selectedShop) {
    throw new Error('No shop selected for print job');
  }
  
  const result: UploadPrintJobsResult = {
    success: true,
    totalUploaded: 0,
    failedUploads: [],
    successfulUploads: []
  };
  
  // Upload each document group as a print job
  for (const group of documentGroups) {
    try {
      // Make sure the group has an upload URL
      if (!group.uploadFilePath) {
        throw new Error(`Document group ${group.id} has no upload URL`);
      }
      
      // Get the first document in the group for the file name
      const firstDoc = group.documents[0];
      const fileName = firstDoc ? firstDoc.name : 'document.pdf';
      
      // Get the total pages and price from the group directly
      const totalPages = group.totalPages || 0;
      const jobAmount = group.price || 0;

      const copies = group.printSettings.copies || 1;
      const pages = totalPages/copies;
      
      console.log(`Uploading job for group ${group.id}: ${fileName}, ${totalPages} pages, settings code: ${group.printSettingsCode}, price: ${jobAmount}`);
      
      // Call the addPrintJob function
      await addPrintJob({
        shop_id: selectedShop,
        group_id: group.id,
        copies: copies,
        pages: pages,
        amount: jobAmount,
        printsettings_code: group.printSettingsCode || 0,
        combined_file_path: '', // Leave empty as requested
        cover_invoice_path: '', // Leave empty as requested
        file_path: group.uploadFilePath,
        file_name: fileName,
        status: 'pending',
        source: group.source || 'default' // Use group's source
      });
      
      // Add to successful uploads
      result.successfulUploads.push({
        groupId: group.id,
        settingsCode: group.printSettingsCode || 0
      });
      
      result.totalUploaded++;
    } catch (error) {
      console.error(`Failed to upload print job for group ${group.id}:`, error);
      
      // Add to failed uploads
      result.failedUploads.push({
        groupId: group.id,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
      
      // Set overall success to false if any upload fails
      result.success = false;
    }
  }
  
  console.log(`Print job upload complete: ${result.totalUploaded} successful, ${result.failedUploads.length} failed`);
  return result;
}

// Note: When creating database records, use group properties like:
// - group.totalPages for the number of pages
// - group.price for the price specific to this group
