import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { PrintSettings } from '../../store/printStore';
import { initPdfWorker } from '../../utils/pdfUtils';

// Initialize the worker
initPdfWorker();

interface DocumentPreviewProps {
  documentUrl: string;
  printSettings: PrintSettings;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ documentUrl, printSettings }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    setLoading(false);
  }

  function onDocumentLoadError(): void {
    setError(true);
    setLoading(false);
  }

  // Reset to page 1 when document changes
  useEffect(() => {
    setCurrentPage(1);
    setLoading(true);
    setError(false);
  }, [documentUrl]);

  const pageClassName = `shadow-md ${printSettings.color ? '' : 'grayscale'}`;

  // Determine the container style based on orientation
  const containerStyle = {
    maxHeight: '350px',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  // Calculate ideal preview width based on container and orientation
  const getPreviewWidth = () => {
    // For mobile, use a smaller size to fit well
    return window.innerWidth < 768 ? 250 : 280;
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div 
        className="pdf-preview bg-white p-2 rounded-md shadow-md mb-4 overflow-hidden"
        style={containerStyle}
      >
        {loading && (
          <div className="flex flex-col items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            <p className="mt-2 text-sm text-gray-500">Loading preview...</p>
          </div>
        )}
        
        {error && (
          <div className="flex flex-col items-center justify-center h-64 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <p className="text-sm text-center">Failed to load preview</p>
          </div>
        )}

        {!error && (
          <Document
            file={documentUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            className="flex justify-center"
            loading={null} // Hide default loader
          >
            <Page 
              pageNumber={currentPage} 
              renderTextLayer={false} 
              renderAnnotationLayer={false}
              className={pageClassName}
              width={getPreviewWidth()}
              rotate={printSettings.orientation === 'landscape' ? 90 : 0}
              loading={null} // Hide default loader
            />
          </Document>
        )}
      </div>
      
      {numPages && numPages > 1 && (
        <div className="flex items-center gap-3 mt-2">
          <button 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage <= 1}
            className="px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50 text-sm"
          >
            Previous
          </button>
          <span className="text-sm">
            Page {currentPage} of {numPages}
          </span>
          <button 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages))}
            disabled={currentPage >= numPages}
            className="px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50 text-sm"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default DocumentPreview;
