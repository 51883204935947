import React, { useState } from 'react';
import usePrintStore from '../../store/printStore';
import { FiSettings } from 'react-icons/fi';
import IconWrapper from './IconWrapper';
import DocumentPreview from '../printSettings/DocumentPreview';
import DocumentCard from '../printSettings/DocumentCard';
import DocumentsList from '../printSettings/DocumentsList';
import PrintOptionsPanel from '../printSettings/PrintOptionsPanel';

const PrintSettingsCard: React.FC = () => {
  const { 
    documents, 
    printSettings, 
    setCurrentStep, 
    selectedDocumentId, 
    setSelectedDocument
  } = usePrintStore();

  // Find the currently selected document
  const selectedDocument = documents.find(doc => doc.id === selectedDocumentId);
  const currentSettings = selectedDocument ? printSettings[selectedDocument.id] : null;
  
  // Toggle mobile view sections
  const [activeSection, setActiveSection] = useState<'documents' | 'preview' | 'settings'>('documents');

  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md w-full max-w-5xl mx-auto overflow-hidden">
      <h2 className="text-xl sm:text-2xl font-semibold mb-3 text-blue-600 text-center sm:text-left">Print Settings</h2>

      <div className="flex justify-center mb-3">
        <IconWrapper icon={FiSettings} />
      </div>
      
      {/* Mobile Section Navigation */}
      <div className="flex md:hidden mb-4 border-b">
        <button 
          onClick={() => setActiveSection('documents')}
          className={`flex-1 py-2 px-1 text-center text-sm font-medium ${
            activeSection === 'documents' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
          }`}
        >
          Documents
        </button>
        <button 
          onClick={() => setActiveSection('preview')}
          className={`flex-1 py-2 px-1 text-center text-sm font-medium ${
            activeSection === 'preview' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
          }`}
          disabled={!selectedDocument}
        >
          Preview
        </button>
        <button 
          onClick={() => setActiveSection('settings')}
          className={`flex-1 py-2 px-1 text-center text-sm font-medium ${
            activeSection === 'settings' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
          }`}
          disabled={!selectedDocument}
        >
          Options
        </button>
      </div>

      <div className="md:grid md:grid-cols-3 md:gap-4 lg:gap-6">
        {/* Document Cards - Left Column */}
        <div 
          className={`
            md:block md:col-span-1 border rounded-lg p-3 bg-gray-50 
            ${activeSection === 'documents' ? 'block' : 'hidden'} 
            mb-4 md:mb-0
          `}
        >
          <DocumentsList 
            documents={documents}
            selectedDocumentId={selectedDocumentId}
            onSelectDocument={(docId) => {
              setSelectedDocument(docId);
              setActiveSection('preview');
            }}
          />
        </div>

        {/* Document Preview - Middle Column */}
        <div 
          className={`
            md:block md:col-span-1 flex flex-col items-center justify-center border rounded-lg p-3
            ${activeSection === 'preview' ? 'block' : 'hidden'} 
            mb-4 md:mb-0
          `}
        >
          <h3 className="font-medium mb-3 text-gray-700 text-center md:text-left w-full">Preview</h3>
          {selectedDocument && currentSettings ? (
            <div className="w-full flex justify-center">
              <DocumentPreview 
                documentUrl={selectedDocument.url} 
                printSettings={currentSettings} 
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-64 text-gray-400">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <p className="text-sm text-center">Select a document to preview</p>
            </div>
          )}
          
          {/* Mobile navigation buttons */}
          {selectedDocument && (
            <div className="mt-4 flex justify-between w-full md:hidden">
              <button
                onClick={() => setActiveSection('documents')}
                className="text-sm text-blue-600"
              >
                ← Back to Documents
              </button>
              <button
                onClick={() => setActiveSection('settings')}
                className="text-sm text-blue-600"
              >
                Settings →
              </button>
            </div>
          )}
        </div>

        {/* Print Settings - Right Column */}
        <div 
          className={`
            md:block md:col-span-1 border rounded-lg p-3
            ${activeSection === 'settings' ? 'block' : 'hidden'} 
          `}
        >
          {selectedDocument && currentSettings ? (
            <PrintOptionsPanel 
              document={selectedDocument}
              settings={currentSettings}
              onBack={() => setActiveSection('preview')}
            />
          ) : (
            <div className="text-gray-500 italic text-sm text-center py-8">
              Select a document to configure settings
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between mt-6 pt-4 border-t">
        <button
          onClick={() => setCurrentStep(0)}
          className="px-4 sm:px-6 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg text-sm"
        >
          Back
        </button>
        <button
          onClick={() => setCurrentStep(2)}
          className="px-4 sm:px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default PrintSettingsCard;
