import axios from 'axios';
import { supabase } from '../lib/supabase/client/supabaseClient';

class WalletPaymentService {
    private baseUrl = 'https://ctrlp-payment-server-eight.vercel.app/new-server';

    async deductWalletBalance(userId: string, amount: number): Promise<boolean> {
        const { data, error } = await supabase
            .schema('api')
            .rpc('deduct_wallet_balance', {
                user_id_param: userId,
                amount_to_deduct: amount
            });

        if (error) throw error;
        return data;
    }

    async addWalletTransaction(userId: string, amount: number, paymentType: 'print_bazaar_purchase' | 'print_order') {
        const reason = paymentType === 'print_bazaar_purchase' 
            ? 'Print Bazaar Purchase' 
            : 'Print Order Payment';

        const { error } = await supabase
            .schema('api')
            .rpc('add_wallet_transaction', {
                user_id_param: userId,
                amount_param: amount,
                transaction_type_param: 'DEBIT',
                reason_param: reason
            });

        if (error) throw error;
    }

    async createInvoice(orderData: {
        orderId: string;
        session: string;
        orderSummary: any;
        fileCosts: number[];
        userInfo: any;
        totalCost: number;
    }) {
        try {
            const { data } = await axios.post(
                `${this.baseUrl}/create-invoice`,
                orderData
            );
            return data;
        } catch (error) {
            console.error('Error creating invoice:', error);
            throw error;
        }
    }
}

export default new WalletPaymentService();
