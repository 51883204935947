import React, { useState, useEffect } from 'react';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { fetchPrintBazaarDocs } from '../../lib/supabase/api/fetch-print-bazaar-docs';
import { deletePrintBazaarDoc } from '../../lib/supabase/api/delete-print-bazaar-doc';
import { UploadDialog } from './UploadDialog';

// This type represents the document data structure for the PrintBazaar documents
interface UploadedDocument {
  id: string;
  user_id: string;
  title: string;
  description: string | null;
  file_name: string;
  file_path: string;
  cover_image_path: string | null;
  file_size: number;
  file_type: string;
  status: 'pending' | 'approved' | 'rejected';
  created_at: string;
  updated_at: string;
  views: number;
  downloads: number;
}

const MyUploads: React.FC = () => {
  const [documents, setDocuments] = useState<UploadedDocument[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Fetch user's documents when component mounts
  useEffect(() => {
    fetchUserDocuments();
  }, []);

  // Function to fetch the user's documents from PrintBazaar
  const fetchUserDocuments = async () => {
    setIsLoading(true);
    try {
      const docs = await fetchPrintBazaarDocs(true); // true = only fetch my documents
      setDocuments(docs || []);
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to delete document
  const deleteDocument = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        console.log('Deleting document:', id);
        const result = await deletePrintBazaarDoc(id);

        if (result.success) {
          // Update state to remove the deleted document
          setDocuments(documents.filter(doc => doc.id !== id));
        } else {
          throw new Error(result.message);
        }
      } catch (error) {
        console.error('Error deleting document:', error);
        alert('Failed to delete document. Please try again.');
      }
    }
  };

  // Helper function to format file size
  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return bytes + ' bytes';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + ' KB';
    else return (bytes / 1048576).toFixed(1) + ' MB';
  };

  // Helper function to get status badge color
  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'approved':
        return <Badge className="bg-green-500 hover:bg-green-600">Approved</Badge>;
      case 'pending':
        return <Badge className="bg-yellow-500 hover:bg-yellow-600">Pending</Badge>;
      case 'rejected':
        return <Badge className="bg-red-500 hover:bg-red-600">Rejected</Badge>;
      default:
        return <Badge>Unknown</Badge>;
    }
  };

  // Helper function to format date
  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-800">My PrintBazaar Documents</h2>
        <Button 
          onClick={() => setIsDialogOpen(true)}
          className="bg-blue-600 hover:bg-blue-700"
        >
          Upload New Document
        </Button>
      </div>

      {isLoading ? (
        <div className="text-center py-12">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-4 border-blue-500 border-t-transparent"></div>
          <p className="mt-2 text-gray-600">Loading documents...</p>
        </div>
      ) : documents.length === 0 ? (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-gray-500 text-lg">You haven't uploaded any documents yet.</p>
          <Button 
            onClick={() => setIsDialogOpen(true)}
            className="mt-4 bg-blue-600 hover:bg-blue-700"
          >
            Upload Your First Document
          </Button>
        </div>
      ) : (
        <div className="space-y-4">
          {documents.map((doc) => (
            <div key={doc.id} className="border rounded-lg p-4 flex flex-col sm:flex-row gap-4">
              <div className="w-24 h-24 bg-gray-100 rounded flex-shrink-0 flex items-center justify-center">
                {doc.cover_image_path ? (
                  <img src={doc.cover_image_path} alt={doc.title} className="max-w-full max-h-full object-contain" />
                ) : (
                  <svg className="w-12 h-12 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
                  </svg>
                )}
              </div>
              
              <div className="flex-grow">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-semibold text-lg">{doc.title}</h3>
                    {doc.description && (
                      <p className="text-gray-600 text-sm mt-1">{doc.description}</p>
                    )}
                  </div>
                  <div>{getStatusBadge(doc.status)}</div>
                </div>
                
                <div className="text-sm text-gray-500 mt-2 space-y-1">
                  <p>File: {doc.file_name}</p>
                  <p>Size: {formatFileSize(doc.file_size)}</p>
                  <p>Uploaded: {formatDate(doc.created_at)}</p>
                  <p>Views: {doc.views} | Downloads: {doc.downloads}</p>
                </div>
                
                <div className="flex gap-2 mt-3">
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => window.open(doc.file_path, '_blank')}
                  >
                    Preview
                  </Button>
                  <Button 
                    variant="destructive" 
                    size="sm"
                    onClick={() => deleteDocument(doc.id)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <UploadDialog 
        open={isDialogOpen} 
        onOpenChange={setIsDialogOpen}
      />
    </div>
  );
};

export default MyUploads;
