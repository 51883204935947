import React from 'react';
import { Document } from '../../types/documentTypes';
import DocumentCard from './DocumentCard';

interface DocumentsListProps {
  documents: Document[];
  selectedDocumentId: string | null;
  onSelectDocument: (documentId: string) => void;
}

const DocumentsList: React.FC<DocumentsListProps> = ({ 
  documents, 
  selectedDocumentId, 
  onSelectDocument 
}) => {
  return (
    <>
      <h3 className="font-medium mb-3 text-gray-700 flex items-center justify-between">
        <span>Documents</span>
        <span className="text-xs text-gray-500 font-normal">{documents.length} file(s)</span>
      </h3>
      <div className="max-h-[300px] md:max-h-[400px] overflow-y-auto pr-1 scrollbar-thin">
        {documents.length > 0 ? (
          documents.map(doc => (
            <DocumentCard
              key={doc.id}
              document={doc}
              isSelected={doc.id === selectedDocumentId}
              onClick={() => onSelectDocument(doc.id)}
            />
          ))
        ) : (
          <div className="text-gray-500 text-sm italic text-center py-8">
            No documents uploaded yet
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentsList;
