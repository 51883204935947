import React from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import IconWrapper from '../print-steps/IconWrapper';

interface SearchInputProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ searchQuery, setSearchQuery }) => {
  return (
    <div className="relative mb-4">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <IconWrapper icon={FiSearch} className="text-gray-400" />
      </div>
      
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="pl-10 pr-10 py-2 w-full border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
        placeholder="Search for print shops..."
      />
      
      {searchQuery && (
        <button 
          onClick={() => setSearchQuery('')}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
        >
          <IconWrapper icon={FiX} />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
