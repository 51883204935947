import React from 'react';
import { IconType } from 'react-icons';
import { IconBaseProps } from 'react-icons/lib';

interface IconWrapperProps {
  icon: IconType;
  className?: string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({ icon, className = "text-4xl text-gray-400" }) => {
  // Use a more specific type cast to work around TypeScript limitations with react-icons
  const IconComponent = icon as React.FC<IconBaseProps>;
  return <IconComponent className={className} />;
};

export default IconWrapper;
