import { pdfjs } from 'react-pdf';

// Initialize PDF.js worker
export const initPdfWorker = () => {
  if (!pdfjs.GlobalWorkerOptions.workerSrc) {
    // Use the suggested URL format with .mjs extension
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  }
};

export default initPdfWorker;
