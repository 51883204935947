import React from 'react';
import { Shop } from '../../lib/supabase/api/shopService';
import ShopCard from './ShopCard';

interface ShopListProps {
  shops: Shop[];
  isLoading: boolean;
  selectedShopId: string | null;
  setSelectedShop: (shopId: string) => void;
  locationShared: boolean;
}

const ShopList: React.FC<ShopListProps> = ({
  shops,
  isLoading,
  selectedShopId,
  setSelectedShop,
  locationShared
}) => {
  return (
    <>
      {/* Loading indicator */}
      {isLoading && (
        <div className="text-center py-4">
          <div className="inline-block animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600"></div>
          <p className="mt-2 text-gray-600">
            {locationShared ? 'Finding nearby print shops...' : 'Loading print shops...'}
          </p>
        </div>
      )}
      
      {/* Shops list */}
      <div className="max-h-96 overflow-y-auto pr-2" style={{ scrollbarWidth: 'thin' }}>
        {!isLoading && shops.length === 0 && (
          <p className="text-center text-gray-500 py-4">No print shops found. Try a different search term.</p>
        )}
        
        {!isLoading && shops.map(shop => (
          <ShopCard
            key={shop.id}
            shop={shop}
            isSelected={selectedShopId === shop.id}
            onSelect={setSelectedShop}
          />
        ))}
      </div>
    </>
  );
};

export default ShopList;
