import React from 'react';
import { FiMapPin, FiCheck } from 'react-icons/fi';
import IconWrapper from '../print-steps/IconWrapper';

interface LocationButtonProps {
  locationShared: boolean;
  pendingLocationRequest: boolean;
  isLoadingShops: boolean;
  onRequestLocation: () => void;
}

const LocationButton: React.FC<LocationButtonProps> = ({
  locationShared,
  pendingLocationRequest,
  isLoadingShops,
  onRequestLocation
}) => {
  return (
    <div className="mb-4">
      <button
        onClick={onRequestLocation}
        disabled={pendingLocationRequest || isLoadingShops}
        className={`flex items-center ${
          locationShared 
            ? 'text-green-600 hover:text-green-800' 
            : 'text-blue-600 hover:text-blue-800'
        } ${
          (pendingLocationRequest || isLoadingShops) ? 'opacity-50 cursor-wait' : ''
        }`}
      >
        <IconWrapper icon={locationShared ? FiCheck : FiMapPin} className="mr-1" /> 
        {locationShared 
          ? 'Location shared' 
          : pendingLocationRequest 
            ? 'Waiting for permission...'
            : 'Use my current location'
        }
      </button>
    </div>
  );
};

export default LocationButton;
