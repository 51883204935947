import React, { useState, useEffect, ChangeEvent } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';
import { Plus } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';
import { useWalletStore } from '../../store/walletStore';
import { fetchMyTransactions, Transaction } from '../../lib/supabase/api/fetch-my-transactions';

const MyWallet: React.FC = () => {
  const { user } = useAuthStore();
  const { 
    balance: walletBalance, 
    isLoading, 
    isAddMoneyOpen, 
    amount,
    paymentStatus,
    fetchWalletBalance,
    setIsAddMoneyOpen,
    setAmount,
    handleAddMoney
  } = useWalletStore();

  const quickAmounts = [50, 100, 200, 500];
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const INITIAL_TRANSACTION_LIMIT = 4;

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    setAmount(isNaN(value) ? 0 : Math.max(0, value));
  };

  const handleQuickAmountSelect = (value: number) => {
    setAmount(value);
  };

  useEffect(() => {
    if (user?.id) {
      fetchWalletBalance();
    }
  }, [user?.id]);

  useEffect(() => {
    if (paymentStatus === 'success') {
      fetchWalletBalance();
    }
  }, [paymentStatus]);

  useEffect(() => {
    const loadTransactions = async () => {
      setIsLoadingTransactions(true);
      const data = await fetchMyTransactions();
      setTransactions(data);
      setIsLoadingTransactions(false);
    };

    if (user?.id) {
      loadTransactions();
    }
  }, [user?.id]);

  const formatTransactionAmount = (type: string, amount: number) => {
    return `${type === 'CREDIT' ? '+' : '-'}₹${Math.abs(amount).toFixed(2)}`;
  };

  const displayedTransactions = showAllTransactions 
    ? transactions 
    : transactions.slice(0, INITIAL_TRANSACTION_LIMIT);

  const hasMoreTransactions = transactions.length > INITIAL_TRANSACTION_LIMIT;

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4 text-blue-600">My Wallet</h2>

      {/* Current Balance Card */}
      <Card className="bg-white mb-6 shadow-sm border border-gray-100">
        <CardHeader className="pb-2">
          <CardTitle className="text-lg text-gray-800">Current Balance</CardTitle>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="h-8 w-24 bg-gray-200 animate-pulse rounded"></div>
          ) : (
            <p className="text-3xl font-bold text-blue-600">
              ₹{walletBalance !== null ? walletBalance.toFixed(2) : '0.00'}
            </p>
          )}
        </CardContent>
        <CardFooter className="flex justify-center pt-0 pb-4">
          <Button
            className="bg-blue-600 hover:bg-blue-700 text-white"
            onClick={() => setIsAddMoneyOpen(true)}
          >
            Add Funds
          </Button>
        </CardFooter>
      </Card>

      {/* Transaction History Card */}
      <Card className="bg-white shadow-sm border border-gray-100">
        <CardHeader className="pb-2">
          <CardTitle className="text-lg text-gray-800">Transaction History</CardTitle>
        </CardHeader>
        <CardContent>
          {isLoadingTransactions ? (
            <div className="space-y-4">
              {[1, 2, 3, 4].map(i => (
                <div key={i} className="h-16 bg-gray-100 animate-pulse rounded"></div>
              ))}
            </div>
          ) : displayedTransactions.length > 0 ? (
            <div className="space-y-4 max-h-80 overflow-y-auto scrollbar-thin pr-1">
              {displayedTransactions.map(tx => (
                <div key={tx.id} className="flex flex-col sm:flex-row sm:justify-between sm:items-center py-2 border-b border-gray-100">
                  <div className="mb-1 sm:mb-0">
                    <p className="font-medium text-gray-800">{tx.reason}</p>
                    <p className="text-sm text-gray-500">
                      {new Date(tx.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  <span className={`font-medium text-right ${tx.transaction_type === 'CREDIT' ? 'text-green-600' : 'text-red-600'}`}>
                    {formatTransactionAmount(tx.transaction_type, tx.amount)}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500 py-4">No transactions yet</p>
          )}
        </CardContent>
        <CardFooter className="justify-center pt-2 pb-4">
          {hasMoreTransactions && (
            <Button 
              variant="outline" 
              className="border-blue-600 text-blue-600 hover:bg-blue-50"
              onClick={() => setShowAllTransactions(!showAllTransactions)}
            >
              {showAllTransactions ? 'Show Less' : 'View All Transactions'}
            </Button>
          )}
        </CardFooter>
      </Card>

      {/* Add Money Dialog */}
      <Dialog open={isAddMoneyOpen} onOpenChange={setIsAddMoneyOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Add Money</DialogTitle>
          </DialogHeader>

          <div className="space-y-6 py-2">
            <div className="space-y-2">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <span className="text-gray-500">₹</span>
                </div>
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  value={amount || ''}
                  onChange={handleAmountChange}
                  className="w-full pl-8"
                />
              </div>
              <p className="text-sm text-gray-500">Minimum amount: ₹50</p>
            </div>

            <div className="space-y-2">
              <p className="text-sm text-gray-500">Quick Select</p>
              <div className="flex flex-wrap gap-2">
                {quickAmounts.map((value) => (
                  <Button
                    key={value}
                    type="button"
                    variant={amount === value ? "default" : "outline"}
                    onClick={() => handleQuickAmountSelect(value)}
                    className="px-4 py-2 rounded-md"
                  >
                    ₹{value}
                  </Button>
                ))}
              </div>
            </div>

            <Button
              onClick={handleAddMoney}
              disabled={amount < 1}
              className="w-full py-6 bg-green-600 hover:bg-green-700 text-white rounded-md"
            >
              <Plus className="mr-2 h-4 w-4" /> Add Money
            </Button>
          </div>

          {paymentStatus === 'success' && (
            <div className="bg-green-50 text-green-800 border border-green-200 p-4 rounded-md mt-4">
              Payment successful! ₹{amount} added to your wallet successfully.
            </div>
          )}

          {paymentStatus === 'failed' && (
            <div className="bg-red-50 text-red-800 border border-red-200 p-4 rounded-md mt-4">
              Payment failed. Please try again or contact support.
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MyWallet;