import { useNavigate } from 'react-router-dom';
import usePrintStore from '../store/printStore';

export const usePrintNavigation = () => {
  const navigate = useNavigate();
  const { setCurrentStep } = usePrintStore();

  const navigateToPrintFlow = (step: number) => {
    setCurrentStep(step);
    navigate('/get-print');
  };

  return { navigateToPrintFlow };
};
