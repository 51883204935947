import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import usePrintStore from '../store/printStore';
import GetPrint from './GetPrint';
import { checkShopExists } from '../lib/supabase/api/check-shop-exists';

const ShopDirectGetPrint: React.FC = () => {
  const { shopId } = useParams<{ shopId: string }>();
  const { setSelectedShop } = usePrintStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [verifiedShopId, setVerifiedShopId] = useState<string | null>(null);
  
  useEffect(() => {
    const verifyShop = async () => {
      if (shopId) {
        setIsLoading(true);
        console.log(`Verifying shop ID: ${shopId} from QR code`);
        
        // Check if the shop exists
        const result = await checkShopExists(shopId);
        console.log(`Shop existence check result: ${result}`);
        if (result === '00000000-0000-0000-0000-000000000000') {
          // Shop not found, redirect to registration page
          console.log('Shop not found, redirecting to registration');
          navigate('/shop-registration', { state: { shopId } });
        } else {
          // Shop found, set the selected shop in the store
          console.log(`Shop found, setting shop ID: ${result}`);
          setSelectedShop(result);
          setVerifiedShopId(result);
          setIsLoading(false);
        }
      }
    };
    
    verifyShop();
  }, [shopId, setSelectedShop, navigate]);

  // Show loading state while checking shop existence
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Render the normal GetPrint component with shop pre-selected
  return <GetPrint directShopId={verifiedShopId} />;
};

export default ShopDirectGetPrint;
