import * as pdfjs from 'pdfjs-dist';
import { PDFDocument } from 'pdf-lib';
import { PageSelection } from '../types/documentTypes';
import { pageSelectionToString } from '../utils/pageUtils';

/**
 * Trims a PDF file to only include selected pages
 * @param file The original PDF file
 * @param pageSelection The page selection (string or PageSelection object)
 * @returns A new File object with only the selected pages
 */
export async function trimPdfByPageSelection(
  file: File, 
  pageSelection: string | PageSelection
): Promise<File> {
  if (!file || !(file instanceof File)) {
    throw new Error('Invalid file provided for trimming');
  }

  console.log(`Trimming PDF: ${file.name} with selection:`, pageSelection);
  
  try {
    // Read the file as array buffer
    const fileArrayBuffer = await file.arrayBuffer();
    
    // Load the PDF document using pdf-lib
    const pdfDoc = await PDFDocument.load(fileArrayBuffer, {ignoreEncryption: true});
    const totalPages = pdfDoc.getPageCount();
    
    console.log(`Original PDF has ${totalPages} pages`);
    
    // Convert PageSelection to string format if needed
    const pageSelectionStr = typeof pageSelection === 'string' 
      ? pageSelection 
      : pageSelectionToString(pageSelection, totalPages);
    
    console.log(`Page selection string: ${pageSelectionStr}`);
    
    // Parse the page selection
    const selectedPageNumbers = parsePageSelection(pageSelectionStr, totalPages);
    console.log(`Selected pages: ${selectedPageNumbers.join(', ')}`);
    
    // Create a new PDF document
    const newPdfDoc = await PDFDocument.create();
    
    // Copy selected pages to the new document
    const copiedPages = await newPdfDoc.copyPages(
      pdfDoc,
      selectedPageNumbers.map(pageNum => pageNum - 1) // Convert to 0-based index
    );
    
    // Add the copied pages to the new document
    copiedPages.forEach(page => {
      newPdfDoc.addPage(page);
    });
    
    // Serialize the new PDF document to bytes
    const newPdfBytes = await newPdfDoc.save();
    
    // Create a new File object
    const trimmedFile = new File(
      [newPdfBytes], 
      `trimmed-${file.name}`,
      { type: file.type }
    );
    
    console.log(`Trimmed PDF created with ${selectedPageNumbers.length} pages`);
    return trimmedFile;
  } catch (error) {
    console.error('Error in trimPdfByPageSelection:', error);
    throw error;
  }
}

/**
 * Parse a page selection string into an array of page numbers
 * @param pageSelection Page selection string (e.g., "1-5,8,10-12")
 * @param totalPages Total number of pages in the original PDF
 * @returns Array of selected page numbers
 */
function parsePageSelection(pageSelection: string, totalPages: number): number[] {
  // If pageSelection is empty or undefined, return all pages
  if (!pageSelection) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }
  
  const selectedPages = new Set<number>();
  
  // Split by comma to get individual selections
  const selections = pageSelection.split(',');
  
  selections.forEach(selection => {
    selection = selection.trim();
    
    if (selection.includes('-')) {
      // Handle page ranges (e.g., "1-5")
      const [start, end] = selection.split('-').map(Number);
      
      if (!isNaN(start) && !isNaN(end)) {
        // Add all pages in the range
        for (let i = Math.max(1, start); i <= Math.min(end, totalPages); i++) {
          selectedPages.add(i);
        }
      }
    } else {
      // Handle individual pages (e.g., "8")
      const pageNum = Number(selection);
      if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= totalPages) {
        selectedPages.add(pageNum);
      }
    }
  });
  
  // If no pages were selected, return all pages
  if (selectedPages.size === 0) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }
  
  // Convert to array and sort
  return Array.from(selectedPages).sort((a, b) => a - b);
}
