import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../ui/dialog';

interface PrintBazaarPaymentDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onWalletPayment: () => void;
  onRazorpayPayment: () => void;
  amount: number;
  walletBalance: number | null;
}

const PrintBazaarPaymentDialog: React.FC<PrintBazaarPaymentDialogProps> = ({
  isOpen,
  onClose,
  onWalletPayment,
  onRazorpayPayment,
  amount,
  walletBalance,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Purchase Document</DialogTitle>
          <DialogDescription>
            Choose your payment method
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <div className="mb-4">
            <p className="text-sm font-medium">Amount to pay: ₹{amount}</p>
            <p className="text-sm font-medium">Wallet Balance: ₹{walletBalance?.toFixed(2) || '0.00'}</p>
          </div>

          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
            <p className="text-sm text-yellow-700">
              ⚠️ Clicking 'Pay via Wallet' will confirm the purchase and deduct the amount from your wallet.
            </p>
          </div>
        </div>

        <DialogFooter className="flex gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
          >
            Cancel
          </button>
          <button
            onClick={onWalletPayment}
            className="px-4 py-2 bg-white hover:bg-gray-200 text-blue-600 rounded-lg border border-blue-600"
          >
            Pay via Wallet
          </button>
          <button
            onClick={onRazorpayPayment}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
          >
            Pay via Razorpay
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PrintBazaarPaymentDialog;
