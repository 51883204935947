import { createClient } from '@supabase/supabase-js';

// Get environment variables without fallbacks
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// Check and log Supabase configuration
console.log('Supabase Configuration Check:');
console.log(`- URL defined: ${supabaseUrl ? `Yes : ${supabaseUrl}` : 'No'}`);
console.log(`- Anon Key defined: ${supabaseAnonKey ? 'Yes' : 'No'}`);

// Ensure environment variables are set
if (!supabaseUrl || !supabaseAnonKey) {
  const errorMessage = 'Missing Supabase environment variables. Make sure .env or .env.local is properly set up.';
  console.error(errorMessage);
  throw new Error(errorMessage);
}
try{
  new URL(supabaseUrl);

}
catch(err){
  console.error('Invalid Supabase URL');
  throw err;
}
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Check if the client was created successfully
console.log('Supabase Client Initialized:', !!supabase);