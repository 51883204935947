import React from 'react';
import { FiMapPin, FiClock } from 'react-icons/fi';
import { Shop } from '../../lib/supabase/api/shopService';
import IconWrapper from '../print-steps/IconWrapper';

interface ShopCardProps {
  shop: Shop;
  isSelected: boolean;
  onSelect: (shopId: string) => void;
}

const ShopCard: React.FC<ShopCardProps> = ({ shop, isSelected, onSelect }) => {
  // Generate placeholder image URL for a shop
  const getShopImageUrl = (shop: Shop) => {
    return `https://robohash.org/${shop.id}?set=set2`;
  };

  return (
    <div 
      onClick={() => onSelect(shop.id)}
      className={`border ${
        isSelected ? 'border-blue-400 bg-blue-50' : 'border-gray-200' 
      } rounded-lg p-4 mb-3 cursor-pointer hover:bg-blue-50 flex items-center gap-4 transition-all`}
    >
      <div className="flex-shrink-0">
        <img 
          src={getShopImageUrl(shop)} 
          alt={shop.name} 
          className="w-16 h-16 rounded-lg object-cover"
          loading="lazy"
        />
      </div>
      
      <div className="flex-grow">
        <h3 className="font-medium text-lg">{shop.name}</h3>
        <p className="text-gray-600 text-sm">{shop.location}</p>
        
        <div className="flex flex-wrap items-center gap-4 mt-2">
          {shop.distance !== undefined && (
            <div className="flex items-center text-blue-600 text-sm">
              <IconWrapper icon={FiMapPin} className="mr-1 h-3 w-3" /> 
              {shop.distance.toFixed(1)} km away
            </div>
          )}
          
          <div className="flex items-center text-gray-500 text-sm">
            <IconWrapper icon={FiClock} className="mr-1 h-3 w-3" /> 
            Open 9AM-6PM
          </div>
        </div>
      </div>
      
      {/* Selected indicator */}
      <div className={`w-4 h-4 rounded-full border-2 ${
        isSelected ? 'bg-blue-600 border-blue-600' : 'border-gray-300'
      }`}></div>
    </div>
  );
};

export default ShopCard;
