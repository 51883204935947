import axios from "axios";
import { supabase } from "./supabaseClient";
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        //"Authorization" : `Bearer ${}`,
        "Content-Type": "application/json",
    },
});

apiClient.interceptors.request.use(
    async (config) => {
        const token = await supabase.auth.getSession();
        if (token) {
            config.headers.Authorization = `Bearer ${token.data.session?.access_token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
const createSSEConnection = async (endpoint: string) => {
    const session = await supabase.auth.getSession();
    const token = session.data.session?.access_token;
  
    if (!token) {
      throw new Error("No authentication token available");
    }
  
    // Construct the SSE URL with the token as a query parameter
    const url = `${process.env.REACT_APP_BACKEND_URL}${endpoint}?token=${token}`;
    return new EventSource(url);
  };
  
  export default apiClient;
  export { createSSEConnection };