import React, { useState } from 'react';
import { FiShield, FiDollarSign, FiUsers, FiX } from 'react-icons/fi';
import IconWrapper from '../print-steps/IconWrapper';
import { Separator } from '../ui/separator';

const AntiPiracyBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  
  if (!isVisible) {
    return null;
  }

  return (
    <div className="bg-gradient-to-r from-green-50 to-blue-50 border-y border-blue-100">
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-start">
          <div className="flex-grow">
            <h2 className="text-xl md:text-2xl font-semibold text-blue-800 mb-3">
              Supporting Creators, Combating Piracy
            </h2>
            
            <p className="text-gray-700 mb-4 max-w-3xl">
              PrintBazaar lets you access quality academic materials while ensuring creators get fairly compensated.
              By allowing print-only access, we protect intellectual property and support the academic community.
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex items-start">
                <div className="mt-1 mr-3 text-blue-600">
                  <IconWrapper icon={FiShield} className="h-5 w-5" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Protecting Content</h3>
                  <p className="text-sm text-gray-600">Documents can only be printed, not downloaded or copied digitally.</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="mt-1 mr-3 text-green-600">
                  <IconWrapper icon={FiDollarSign} className="h-5 w-5" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Fair Compensation</h3>
                  <p className="text-sm text-gray-600">Up to 70% of every purchase goes directly to the content creator.</p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="mt-1 mr-3 text-purple-600">
                  <IconWrapper icon={FiUsers} className="h-5 w-5" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Supporting Education</h3>
                  <p className="text-sm text-gray-600">Access quality materials while respecting the work of educators and students.</p>
                </div>
              </div>
            </div>
          </div>
          
          <button 
            onClick={() => setIsVisible(false)} 
            className="text-gray-500 hover:text-gray-700 p-1"
            aria-label="Close banner"
          >
            <IconWrapper icon={FiX} className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AntiPiracyBanner;
