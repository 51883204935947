import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Document, PageSelection, PrintSettings, PrintStore } from '../types/documentTypes';
import { 
  documentFilesMap, 
  FILE_STORAGE_PREFIX, 
  base64toFile, 
  saveFileToStorage,
  getDocumentFile as getFileFromStorage,
  removeFileFromStorage
} from '../utils/fileStorage';
import { calculateEffectivePageCount } from '../utils/pageUtils';

// Re-export types so existing imports continue to work
export type { Document, PageSelection, PrintSettings, PrintStore } from '../types/documentTypes';

const usePrintStore = create<PrintStore>()(
  persist(
    (set, get) => ({
      currentStep: 0,
      documents: [],
      printSettings: {},
      selectedShop: null,
      selectedDocumentId: null,
      
      setCurrentStep: (step) => set({ currentStep: step }),
      
      addDocument: (document) => {
        // If document has a file property, store it (handle async operation)
        if (document.file) {
          saveFileToStorage(document.id, document.file)
            .catch(err => console.error(`Failed to store file for document ${document.id}:`, err));
        }
        
        set((state) => ({
          documents: [...state.documents, document],
          printSettings: {
            ...state.printSettings,
            [document.id]: { 
              color: false, 
              doubleSided: false, 
              copies: 1,
              orientation: 'portrait',
              paperType: 'A4',
              pageSelection: { type: 'all' }
            }
          },
          selectedDocumentId: state.documents.length === 0 ? document.id : state.selectedDocumentId
        }));
      },
      
      removeDocument: (documentId) => {
        // Remove from storage (handle async operation)
        removeFileFromStorage(documentId)
          .catch(err => console.error(`Failed to remove file for document ${documentId}:`, err));
        
        // Get document URL to revoke
        const docToRemove = get().documents.find(doc => doc.id === documentId);
        if (docToRemove?.url && docToRemove.url.startsWith('blob:')) {
          URL.revokeObjectURL(docToRemove.url);
        }
        
        set(state => ({
          documents: state.documents.filter(doc => doc.id !== documentId),
          printSettings: Object.entries(state.printSettings).reduce((acc, [id, settings]) => {
            if (id !== documentId) {
              acc[id] = settings;
            }
            return acc;
          }, {} as Record<string, PrintSettings>),
          selectedDocumentId: state.selectedDocumentId === documentId 
            ? (state.documents.length > 1 ? state.documents.find(d => d.id !== documentId)?.id ?? null : null)
            : state.selectedDocumentId
        }));
      },
      
      updatePrintSettings: (documentId, settings) => {
        set(state => {
          const updatedSettings = {
            ...state.printSettings[documentId] || { color: false, doubleSided: false, copies: 1 },
            ...settings
          };
          
          return {
            printSettings: {
              ...state.printSettings,
              [documentId]: updatedSettings
            }
          };
        });
      },
      
      setSelectedShop: (shopId) => set({ selectedShop: shopId }),
      
      setSelectedDocument: (documentId) => set({ selectedDocumentId: documentId }),
      
      updatePageSelection: (documentId, pageSelection) => {
        const { documents } = get();
        const document = documents.find(doc => doc.id === documentId);
        
        if (document) {
          // Calculate effective page count using the existing utility function
          const effectivePageCount = calculateEffectivePageCount(
            pageSelection, 
            document.pageCount || 1
          );
          
          set((state) => ({
            printSettings: {
              ...state.printSettings,
              [documentId]: {
                ...state.printSettings[documentId],
                pageSelection,
                effectivePageCount // Store the effective page count here
              }
            }
          }));
        } else {
          // If document not found, just update the page selection without effectivePageCount
          set((state) => ({
            printSettings: {
              ...state.printSettings,
              [documentId]: {
                ...state.printSettings[documentId],
                pageSelection
              }
            }
          }));
        }
      },
      
      getDocumentFile: async (id: string): Promise<File | null> => {
        return await getFileFromStorage(id);
      },
      
      // After page reload, this restores document files from storage
      restoreDocuments: async () => {
        const { documents } = get();
        
        // For each document, try to restore the file from IndexedDB
        for (const doc of documents) {
          try {
            // Get file from IndexedDB
            const file = await getFileFromStorage(doc.id);
            
            if (file) {
              // Create a new blob URL since old ones don't persist after refresh
              const newBlobUrl = URL.createObjectURL(file);
              
              // Update the document with the new URL and retain pageCount if available
              set(state => ({
                documents: state.documents.map(d => 
                  d.id === doc.id ? { 
                    ...d, 
                    url: newBlobUrl,
                    pageCount: d.pageCount,
                    source: d.source // Ensure source is preserved
                  } : d
                )
              }));
            }
          } catch (err) {
            console.error(`Failed to restore file for document ${doc.id}:`, err);
          }
        }
      },

      // Add a function to calculate and update the effective page count
      updateEffectivePageCount: (docId: string) => {
        const { documents, printSettings } = get();
        const doc = documents.find(d => d.id === docId);
        const settings = printSettings[docId];
        
        if (!doc || !settings) return;
        
        let effectivePageCount = doc.pageCount || 1;
        
        // If pageSelection exists, use it to calculate effective page count
        if (settings.pageSelection) {
          effectivePageCount = calculateEffectivePageCount(
            settings.pageSelection,
            doc.pageCount || 1
          );
        }
        
        // Update the store with the calculated effective page count
        set(state => ({
          printSettings: {
            ...state.printSettings,
            [docId]: {
              ...state.printSettings[docId],
              effectivePageCount
            }
          }
        }));
        
        return effectivePageCount;
      }
    }),
    {
      name: 'print-storage',
      partialize: (state) => ({
        // Only persist serializable parts, exclude File objects
        documents: state.documents.map(doc => ({
          id: doc.id,
          name: doc.name,
          url: doc.url,
          pageCount: doc.pageCount,
          source: doc.source // Add source to persisted data
        })),
        printSettings: state.printSettings,
        selectedShop: state.selectedShop,
        selectedDocumentId: state.selectedDocumentId,
        currentStep: state.currentStep,
      }),
    }
  )
);

export default usePrintStore;
