import { supabase } from '../client/supabaseClient';

/**
 * Deletes a PrintBazaar document and its associated files
 * @param docId The ID of the document to delete
 * @param isAdmin Whether the user is an admin (can delete any document)
 * @returns Success or error information
 */
export const deletePrintBazaarDoc = async (docId: string, isAdmin = false): Promise<{ success: boolean; message?: string }> => {
  try {
    console.log(`Attempting to delete document with ID: ${docId}, isAdmin: ${isAdmin}`);
    
    // Call the RPC function to check permissions, delete the record, and return file paths
    const { data: result, error: rpcError } = await supabase
      .schema('api')
      .rpc(
        'delete_print_bazaar_doc',
        { doc_id: docId, is_admin: isAdmin }
      );
    
    if (rpcError) {
      console.error('RPC error:', rpcError);
      throw rpcError;
    }
    
    if (!result || !result.success) {
      console.log('RPC returned unsuccessful:', result);
      return result || { success: false, message: 'Unknown error in RPC' }; 
    }
    
    console.log('Database record deleted successfully, file paths:', result.paths);
    
    // If database deletion was successful, delete the files from storage
    const { file_path, cover_image_path } = result.paths || {};
    
    if (file_path) {
      try {
        // Extract the storage path properly
        // Option 1: If file_path is a full URL
        let filePath = '';
        try {
          const url = new URL(file_path);
          // Extract path after bucket name (everything after .../storage/v1/object/public/print-bazaar-docs/)
          filePath = url.pathname.split('/').slice(6).join('/');
        } catch (e) {
          // Option 2: If file_path is already just the path
          filePath = file_path;
        }
        
        console.log('Deleting document with storage path:', filePath);
        const { error: deleteError } = await supabase.storage
          .from('print-bazaar-docs')
          .remove([filePath]);
          
        if (deleteError) {
          console.error('Error deleting file from storage:', deleteError);
        } else {
          console.log('File deleted successfully from storage');
        }
      } catch (fileError) {
        console.error('Exception while deleting file:', fileError);
      }
    }
    
    if (cover_image_path) {
      try {
        // Extract the storage path properly
        // Option 1: If cover_image_path is a full URL
        let coverPath = '';
        try {
          const url = new URL(cover_image_path);
          // Extract path after bucket name
          coverPath = url.pathname.split('/').slice(6).join('/');
        } catch (e) {
          // Option 2: If cover_image_path is already just the path
          coverPath = cover_image_path;
        }
        
        console.log('Deleting cover image with storage path:', coverPath);
        const { error: deleteError } = await supabase.storage
          .from('print-bazaar-docs')
          .remove([coverPath]);
          
        if (deleteError) {
          console.error('Error deleting cover image from storage:', deleteError);
        } else {
          console.log('Cover image deleted successfully from storage');
        }
      } catch (fileError) {
        console.error('Exception while deleting cover image:', fileError);
      }
    }
    
    return { success: true };
  } catch (error) {
    console.error('Error in deletePrintBazaarDoc:', error);
    return { 
      success: false, 
      message: error instanceof Error ? error.message : 'Failed to delete document' 
    };
  }
};