import { supabase } from '../client/supabaseClient';
import { User as SupabaseUser } from '@supabase/supabase-js';

/**
 * Creates a user in the database if they don't already exist
 * Uses RPC to create the user with the current auth context
 */
export async function createUserIfNotExists(user: SupabaseUser): Promise<void> {
    try {
        if (!user || !user.email) {
            console.error('Invalid user data');
            return;
        }

        // Extract user details from auth user
        const _name = user.user_metadata?.full_name || '';
        const _email = user.email;

        // Call the RPC function that will create the user if they don't exist
        const { error } = await supabase
            .schema('api')
            .rpc('create_user_if_not_exists', {
                _uid: user.id,
                _name,
                _email
            });

        if (error) {
            console.error('Error creating user:', error);
        }
    } catch (error) {
        console.error('Unexpected error in createUserIfNotExists:', error);
    }
}