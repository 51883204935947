import { create } from 'zustand';
import { supabase } from '../lib/supabase/client/supabaseClient';
import RazorPayService from '../services/RazorPayService';
import { fetchWalletBalance } from '../lib/supabase/api/fetch-wallet-balance';
import WalletPaymentService from '../services/WalletPaymentService';

interface PaymentResult {
  success: boolean;
  error?: string;
  payment_id?: string;
  order_id?: string;
  amount?: number;
  [key: string]: any; // For any additional properties
}

interface WalletStore {
  balance: number | null;
  isLoading: boolean;
  hasLoaded: boolean;
  isAddMoneyOpen: boolean;
  amount: number;
  paymentStatus: 'idle' | 'success' | 'failed';

  // Core actions
  fetchWalletBalance: () => Promise<void>;
  resetWalletState: () => void;
  setIsAddMoneyOpen: (isOpen: boolean) => void;
  setAmount: (amount: number) => void;
  handleAddMoney: () => Promise<void>;
  handleDeductMoney: (amount: number, orderData: any) => Promise<boolean>;
}

export const useWalletStore = create<WalletStore>((set, get) => ({
  balance: null,
  isLoading: false,
  hasLoaded: false,
  isAddMoneyOpen: false,
  amount: 0,
  paymentStatus: 'idle',

  fetchWalletBalance: async () => {
    set({ isLoading: true });
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user?.id) {
        set({ isLoading: false });
        return;
      }

      // Use the imported fetchWalletBalance function
      try {
        const balance = await fetchWalletBalance(user.id);
        set({ balance, isLoading: false, hasLoaded: true });
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
        set({ balance: null, isLoading: false });
      }
    } catch (error) {
      console.error('Error getting user:', error);
      set({ isLoading: false });
    }
  },

  resetWalletState: () => {
    set({
      balance: null,
      hasLoaded: false,
      isLoading: false,
      isAddMoneyOpen: false,
      amount: 0,
      paymentStatus: 'idle'
    });
  },

  setIsAddMoneyOpen: (isOpen: boolean) => {
    set({ isAddMoneyOpen: isOpen });
  },

  setAmount: (amount: number) => {
    set({ amount });
  },

  handleAddMoney: async () => {
    const { amount } = get();

    if (amount < 1) return;

    try {
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) {
        throw new Error('User information is required for payment');
      }

      const userId = user.id;
      const orderNotes = {};

      set({ isLoading: true });

      const orderData = await RazorPayService.createOrderId(
        amount,
        'WALLET',
        userId,
        'INR',
        orderNotes
      );

      console.log(orderData);

      const paymentResult = await RazorPayService.processWalletPayment(
        orderData.orderId,
        amount,
        userId,
        {
          prefill: {
            name: user?.user_metadata.name,
            email: user?.user_metadata.email,
            contact: user?.phone || '',
          }
        }) as PaymentResult;

      console.log(paymentResult);

      if (paymentResult.success) {
        console.log("payment successful");
        set({ paymentStatus: 'success' });

        // Refresh the wallet balance
        await get().fetchWalletBalance();
      } else {
        console.error('Payment failed: ' + paymentResult.error);
        console.log("payment failed");
        set({ paymentStatus: 'failed' });
      }

      // Break out of the function if payment failed
      if (!paymentResult.success) {
        set({ isLoading: false });
        return;
      }

      // Reset after showing success message
      setTimeout(() => {
        set({
          paymentStatus: 'idle',
          isAddMoneyOpen: false,
          amount: 0,
          isLoading: false
        });
      }, 2000);

    } catch (error) {
      console.error('Error adding money to wallet:', error);
      set({
        paymentStatus: 'failed',
        isLoading: false
      });
    }
  },

  handleDeductMoney: async (amount: number, orderData: any) => {
    const { balance } = get();

    if (!balance || balance < amount) {
      alert('Insufficient wallet balance');
      return false;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not found');

      // First deduct the wallet balance
      const deductSuccess = await WalletPaymentService.deductWalletBalance(user.id, amount);
      if (!deductSuccess) {
        throw new Error('Failed to deduct wallet balance');
      }

      // Add transaction record
      await WalletPaymentService.addWalletTransaction(user.id, amount, 'print_order');

      // Create Razorpay order
      const orderResult = await RazorPayService.createOrderId(
        amount,
        'ORDER',
        user.id,
        'INR'
      );


      console.log('order Result:', orderResult);
      // Create invoice with order ID
      await WalletPaymentService.createInvoice({
        ...orderData,
        orderId: orderResult.orderId
      });

      // Refresh wallet balance
      await get().fetchWalletBalance();
      return true;
    } catch (error) {
      console.error('Wallet payment failed:', error);
      return false;
    }
  }
}));