import React from 'react';

interface CategorySelectorProps {
  categories: string[];
  selectedCategory: string;
  onChange: (category: string) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({ 
  categories, 
  selectedCategory, 
  onChange 
}) => {
  return (
    <div className="space-y-1 max-h-64 overflow-y-auto pr-2 scrollbar-thin">
      {categories.map(category => (
        <div key={category} className="flex items-center">
          <button
            className={`w-full text-left px-2 py-1.5 rounded-md text-sm transition-colors ${
              selectedCategory === category
                ? 'bg-blue-100 text-blue-700 font-medium'
                : 'text-gray-700 hover:bg-gray-100'
            }`}
            onClick={() => onChange(category)}
          >
            {category}
          </button>
        </div>
      ))}
    </div>
  );
};

export default CategorySelector;
