import { supabase } from '../client/supabaseClient';

/**
 * Uploads a PDF file to Supabase storage and returns its public URL
 * @param file The PDF file to upload
 * @param bucketName The storage bucket name
 * @param fileNamePrefix Optional prefix for the file name
 * @returns The public URL of the uploaded file
 */
export const uploadPDF = async (
  file: File,
  bucketName: string = 'pdfs',
  fileNamePrefix?: string
): Promise<string> => {
  try {
    console.log(`Starting upload for ${file.name} to bucket: ${bucketName}`);
    
    // Ensure the file exists and is a valid PDF
    if (!file) {
      throw new Error("No file provided for upload");
    }
    
    if (!file.type.includes('pdf') && !file.name.toLowerCase().endsWith('.pdf')) {
      console.warn(`File might not be a PDF. Type: ${file.type}, Name: ${file.name}`);
    }
    
    // 1. Make sure the bucket exists
    // try {
    //   const { data: bucketExists, error: bucketError } = await supabase.storage.getBucket(bucketName);
      
    //   if (bucketError) {
    //     console.log(`Bucket check error or bucket ${bucketName} does not exist, attempting to create it`);
    //     const { data: newBucket, error: createError } = await supabase.storage.createBucket(bucketName, {
    //       public: true, // Make it public so we can get URLs
    //     });
        
    //     if (createError) {
    //       console.error('Failed to create bucket:', createError);
    //       throw new Error(`Failed to create bucket: ${createError.message}`);
    //     }
    //   }
    // } catch (e) {
    //   console.log("Error during bucket check, trying to continue anyway:", e);
    // }
    
    // 2. Clean the filename - remove special characters and spaces
    const cleanFileName = file.name.replace(/[^\w.-]/g, '_');
    
    // 3. Generate a unique path to avoid collisions
    const timestamp = Date.now();
    const uniquePath = fileNamePrefix 
      ? `${fileNamePrefix.replace(/[^\w.-]/g, '_')}`
      : `${timestamp}_${cleanFileName}`;
    
    console.log(`Uploading to path: ${uniquePath}`);
    
    // 4. Upload the file
    const { data, error } = await supabase.storage
      .from(bucketName)
      .upload(uniquePath, file, {
        cacheControl: '3600',
        upsert: true // Allow overwriting
      });
    
    if (error) {
      console.error('upload PDF: Upload error:', error);
      throw new Error(`Upload failed: ${error.message}`);
    }
    
    console.log('Upload successful:', data);
    
    // 5. Get the public URL
    // const { data: publicUrl } = supabase.storage
    //   .from(bucketName)
    //   .getPublicUrl(uniquePath);
    
    // console.log('Generated public URL:', publicUrl);
    return data.path;
  } catch (error) {
    console.error('uploadPDF error:', error);
    throw error;
  }
};

/**
 * Uploads multiple PDF files to Supabase storage and returns their public URLs
 * @param files Array of PDF files to upload
 * @param bucket The storage bucket name (defaults to 'pdfs')
 * @param path Optional path within the bucket
 * @returns Array of public URLs for the uploaded files
 */
export async function uploadMultiplePDFs(
  files: File[],
  bucket: string = 'pdfs',
  path?: string
): Promise<string[]> {
  try {
    const uploadPromises = files.map(file => uploadPDF(file, bucket, path));
    return await Promise.all(uploadPromises);
  } catch (error) {
    console.error('Error uploading multiple PDFs:', error);
    throw error;
  }
}
