import { create } from 'zustand';
import { DocumentGroup, DocumentGroupsStore, PaperType } from '../types/documentGroupTypes';
import { PrintSettings } from '../types/documentTypes';

const useDocumentGroupsStore = create<DocumentGroupsStore>((set, get) => ({
  documentGroups: [],

  addGroup: (group) => set((state) => ({
    documentGroups: [...state.documentGroups, group]
  })),

  updateGroup: (id, changes) => set((state) => ({
    documentGroups: state.documentGroups.map((group) =>
      group.id === id ? { ...group, ...changes } : group
    )
  })),

  clearGroups: () => set({ documentGroups: [] }),

  setuploadFilePath: (groupId, filePath) => set((state) => ({
    documentGroups: state.documentGroups.map((group) =>
      group.id === groupId ? { ...group, uploadFilePath: filePath } : group
    )
  })),

  // Generate a numeric code based on print settings
  generatePrintSettingsCode: (settings: PrintSettings): number => {
    let code = 0;
    
    // Orientation: portrait=0, landscape=1
    if (settings.orientation === 'landscape') {
      code += 1;
    }
    
    // Color: BW=0, Color=10
    if (settings.color) {
      code += 10;
    }
    
    // Double-sided: simplex=0, duplex=100
    if (settings.doubleSided) {
      code += 100;
    }
    
    // Paper type: multiply by 1000
    let paperTypeCode = PaperType.A4; // default
    
    switch (settings.paperType) {
      case 'A4':
        paperTypeCode = PaperType.A4;
        break;
      case 'A3':
        paperTypeCode = PaperType.A3;
        break;
      case 'Letter':
        paperTypeCode = PaperType.LETTER;
        break;
      case 'Legal':
        paperTypeCode = PaperType.LEGAL;
        break;
    }
    
    code += paperTypeCode * 1000;
    
    return code;
  }
}));

export default useDocumentGroupsStore;
