import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import { fetchMyOrders } from '../../lib/supabase/api/fetch-my-orders';
import { createSSEConnection } from '../../lib/supabase/client/apiClient';
import { supabase } from '../../lib/supabase/client/supabaseClient';
const MyOrders: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let sse: EventSource | null = null;

    const initializeOrdersAndSSE = async () => {
 
        // Fetch initial orders
        setIsLoading(true);
        const orderData = await fetchMyOrders();
        console.log("Fetched orders:", orderData);
        console.log("Fetched orders:", orderData);
        setOrders(orderData);
        try {
        // Establish SSE connection
        sse = await createSSEConnection("/jobs/updates");

        // Handle SSE events
        sse.onopen = () => {
          console.log("SSE connection established");
        };

        sse.addEventListener("job_update", (event) => {
          const update = JSON.parse(event.data);
          console.log("Received job update:", update);

          // Update the orders state with the new status
          setOrders((prevOrders) =>
            prevOrders.map((order) =>
              order.id === update.jobId ? { ...order, status: update.status } : order
            )
          );
        });

        sse.onerror = (err) => {
          console.error("SSE error:", err);
          //setError("Failed to connect to real-time updates. Refresh to retry.");
          sse?.close();
        };
      } catch (err) {
        console.error("Error initializing orders or SSE:", err);
        //setError("Failed to load orders or connect to updates. Please try again later.");
        console.error("Error initializing orders or SSE:", err);
        //setError("Failed to load orders or connect to updates. Please try again later.");
      } finally {
        setIsLoading(false);
        setOrders(orderData);
        setOrders(orderData);
      }
    };

    initializeOrdersAndSSE();

    // Cleanup SSE connection on unmount
    return () => {
      if (sse) {
        sse.close();
        console.log("SSE connection closed");
      }
    };
  }, []);

  if (isLoading) {
    return <div className="text-center py-8">Loading your orders...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-500">{error}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">My Orders</h2>
      
      {orders.length > 0 ? (
        <div className="space-y-4">
          {orders.map(order => (
            <Card key={order.file_name} className="overflow-hidden">
              <CardHeader className="bg-gray-50 pb-2">
                <div className="flex justify-between items-center">
                  <CardTitle className="text-lg">{order.file_name}</CardTitle>
                  <span className={`px-2 py-1 rounded text-xs font-medium ${
                    order.status === 'Completed' ? 'bg-green-100 text-green-800' : 
                    order.status === 'Processing' ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-blue-100 text-blue-800'
                  }`}>
                    {order.status}
                  </span>
                </div>
                <CardDescription>{new Date(order.date).toLocaleDateString()}</CardDescription>
              </CardHeader>
              <CardContent className="pt-4">
                <p><strong>Print Shop:</strong> {order.shop}</p>
                <p><strong>Total:</strong> {order.total}</p>
              </CardContent>
              <CardFooter className="flex justify-end gap-2 bg-gray-50">
                <Button variant="default" size="sm" className="bg-blue-500 hover:bg-blue-600">Download Receipt</Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      ) : (
        <Card className="p-8 text-center">
          <p className="text-gray-500 mb-4">You haven't placed any orders yet.</p>
          <Button>Start Printing</Button>
        </Card>
      )}
    </div>
  );
};

export default MyOrders;
