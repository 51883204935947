import { PDFDocument } from 'pdf-lib';
import { jsPDF } from 'jspdf';

export type ProcessedFile = {
  file: File;
  url: string;
  pageCount: number;
};

/**
 * Process an uploaded file:
 * - For PDFs: Count pages
 * - For images: Convert to PDF
 * - For other files: Return as is with page count 1
 */
export async function processFile(file: File): Promise<ProcessedFile> {
  // Create URL for preview
  const url = URL.createObjectURL(file);
  
  // Check file type
  if (file.type === 'application/pdf') {
    // Process PDF file
    const pageCount = await getPdfPageCount(file);
    return { file, url, pageCount };
  } else if (file.type.startsWith('image/')) {
    // Convert image to PDF
    const pdfFile = await convertImageToPdf(file);
    return { 
      file: pdfFile, 
      url: URL.createObjectURL(pdfFile), 
      pageCount: 1
    };
  }
  
  // Default for other file types
  return { file, url, pageCount: 1 };
}

/**
 * Count pages in a PDF file
 */
export async function getPdfPageCount(file: File): Promise<number> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });
    return pdfDoc.getPageCount();
  } catch (error) {
    console.error('Error counting PDF pages:', error);
    return 1; // Default if we can't determine the page count
  }
}

/**
 * Convert an image file to a PDF
 */
async function convertImageToPdf(imageFile: File): Promise<File> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      
      img.onload = () => {
        // Create PDF with image dimensions (or constrain to reasonable max size)
        const orientation = img.width > img.height ? 'l' : 'p';
        const doc = new jsPDF({
          orientation,
          unit: 'px',
        });
        
        // Calculate dimensions to fit the image properly
        const pdfWidth = orientation === 'l' ? doc.internal.pageSize.getHeight() : doc.internal.pageSize.getWidth();
        const pdfHeight = orientation === 'l' ? doc.internal.pageSize.getWidth() : doc.internal.pageSize.getHeight();
        
        const ratio = Math.min(pdfWidth / img.width, pdfHeight / img.height);
        const width = img.width * ratio;
        const height = img.height * ratio;
        
        // Center the image
        const x = (pdfWidth - width) / 2;
        const y = (pdfHeight - height) / 2;
        
        doc.addImage(img, 'JPEG', x, y, width, height);
        
        // Convert to blob and then to File
        const pdfBlob = doc.output('blob');
        const pdfFileName = imageFile.name.replace(/\.[^/.]+$/, '.pdf');
        const pdfFile = new File([pdfBlob], pdfFileName, { type: 'application/pdf' });
        
        resolve(pdfFile);
      };
    };
    
    reader.readAsDataURL(imageFile);
  });
}
