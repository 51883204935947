import { supabase } from '../client/supabaseClient';

/**
 * Fetches the current user's print orders from the database.
 * Calls the 'fetch_my_orders' RPC in the 'api' schema.
 */
export async function fetchMyOrders() {
    try {
        const { data, error } = await supabase
            .schema('api')
            .rpc('fetch_my_orders');

        if (error) {
            console.error('Error fetching orders:', error);
            return [];
        }

        return data; // Returns an array of orders
    } catch (error) {
        console.error('Unexpected error in fetchMyOrders:', error);
        return [];
    }
}
