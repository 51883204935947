import React from 'react';
import usePrintStore from '../../store/printStore';
import { useAuthStore } from '../../store/authStore';
import { FiLock } from 'react-icons/fi';
import IconWrapper from './IconWrapper';
import { Document } from '../../types/documentTypes';

interface StepIndicatorProps {
  directShopId?: string | null;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ directShopId }) => {
  const { currentStep, setCurrentStep, documents } = usePrintStore();
  const { user } = useAuthStore();

  // Define steps based on whether a direct shop is selected
  const steps = React.useMemo(() => {
    if (directShopId) {
      // Skip the "Select Shop" step if a shop is already selected
      return ['Upload Documents', 'Print Settings', 'Order Summary'];
    }
    return ['Upload Documents', 'Print Settings', 'Select Shop', 'Order Summary'];
  }, [directShopId]);

  // Check localStorage first, then fall back to documents check
  const isPrintBazaar = React.useMemo(() => {
    const storedState = JSON.parse(localStorage.getItem('print-storage') || '{}');
    const storedDocs = storedState?.state?.documents || [];
    return storedDocs.some((doc: { source?: string }) => doc.source === 'print_bazar') || 
           documents.some((doc: Document) => doc.source === 'print_bazar');
  }, [documents]);

  // Map current step to display step index
  const getDisplayStep = (index: number) => {
    if (!directShopId) return index;
    
    // When direct shop is selected and we're at the order summary step (2 in the new flow),
    // it corresponds to step 3 in the original flow
    if (currentStep === 3 && index === 2) return currentStep;
    return index;
  };

  return (
    <div className="w-full max-w-3xl mx-auto mb-8">
      <div className="flex items-center justify-between">
        {steps.map((step, index) => {
          const displayStepIndex = getDisplayStep(index);
          const isDisabled = isPrintBazaar && index < 2;
          
          return (
            <React.Fragment key={index}>
              {/* Step dot */}
              <div 
                className={`flex flex-col items-center ${
                  isDisabled ? 'cursor-not-allowed opacity-50' : 
                  (displayStepIndex <= currentStep && (displayStepIndex < 3 || user)) ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                onClick={() => {
                  if (!isDisabled && displayStepIndex <= currentStep && (displayStepIndex < 3 || user)) {
                    setCurrentStep(displayStepIndex);
                  }
                }}
              >
                <div 
                  className={`w-8 h-8 rounded-full flex items-center justify-center text-sm relative
                    ${displayStepIndex < currentStep 
                      ? 'bg-green-500 text-white' 
                      : displayStepIndex === currentStep
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-300 text-gray-600'
                    }
                    ${(displayStepIndex <= currentStep && (displayStepIndex < 3 || user)) ? 'cursor-pointer' : 'cursor-not-allowed'}
                  `}
                >
                  {displayStepIndex < currentStep ? '✓' : index + 1}
                  
                  {/* Lock icon for protected steps */}
                  {displayStepIndex >= 3 && !user && (
                    <div className="absolute -top-1 -right-1 bg-yellow-500 rounded-full w-4 h-4 flex items-center justify-center">
                      <IconWrapper icon={FiLock} className="text-white text-xs" />
                    </div>
                  )}
                </div>
                
                <span className={`mt-2 text-xs sm:text-sm text-center block
                  ${displayStepIndex === currentStep ? 'font-medium text-blue-600' : 'text-gray-500'}`}
                >
                  {step}
                  {displayStepIndex >= 3 && !user && (
                    <span className="block text-xs text-yellow-600">(Login required)</span>
                  )}
                </span>
              </div>

              {/* Connector line between dots (except after the last one) */}
              {index < steps.length - 1 && (
                <div className="flex-1 h-0.5 mx-2 sm:mx-4">
                  <div 
                    className="h-full" 
                    style={{
                      background: `linear-gradient(to right, 
                        ${displayStepIndex < currentStep ? '#10B981' : '#D1D5DB'} 50%, 
                        ${index + 1 <= currentStep ? '#10B981' : '#D1D5DB'} 50%
                      )`
                    }}
                  ></div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default StepIndicator;
