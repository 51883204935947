import React, { useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../ui/dialog';
import { Badge } from '../ui/badge';
import { addPrintBazaarDoc } from '../../lib/supabase/api/add-print-bazaar-doc';
import { X } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { getPdfPageCount } from '../../utils/fileProcessing';

export const CATEGORIES = [
  'Computer Science',
  'Mathematics',
  'Physics',
  'Chemistry',
  'Electronics',
  'Literature',
  'Biology',
  'Economics',
  'Management'
] as const;

interface UploadDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function UploadDialog({ open, onOpenChange }: UploadDialogProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [previewPages, setPreviewPages] = useState<File[]>([]);
  const [cost, setCost] = useState<number>(0);
  const [isFree, setIsFree] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [currentTag, setCurrentTag] = useState('');
  const [error, setError] = useState('');
  const [category, setCategory] = useState<string>('');
  const [pageCount, setPageCount] = useState<number>(0);

  const handleCoverImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      setCoverImage(null);
      return;
    }

    if (!file.type.startsWith('image/')) {
      setError('Cover image must be an image file (jpg, png, etc)');
      setCoverImage(null);
      e.target.value = '';
      return;
    }

    setCoverImage(file);
    setError('');
  };

  const handlePreviewPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);

    if (files.length === 0) {
      setError('At least one preview image is required');
      setPreviewPages([]);
      return;
    }

    if (files.length > 3) {
      setError('Maximum 3 preview images allowed');
      setPreviewPages([]);
      e.target.value = '';
      return;
    }

    const invalidFiles = files.filter(file => !file.type.startsWith('image/'));
    if (invalidFiles.length > 0) {
      setError('Preview files must be images (jpg, png, etc)');
      setPreviewPages([]);
      e.target.value = '';
      return;
    }

    setPreviewPages(files);
    setError('');
  };

  const handleTagKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      if (currentTag && tags.length < 6 && currentTag.length <= 20) {
        setTags([...tags, currentTag]);
        setCurrentTag('');
      }
    }
  };

  const removeTag = (indexToRemove: number) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (value > 100) {
      setError('Maximum cost allowed is ₹100');
      setCost(100);
    } else {
      setError('');
      setCost(value);
    }
  };

  const handlePdfFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        setError('Only PDF files are allowed');
        return;
      }
      setPdfFile(file);
      const count = await getPdfPageCount(file);
      setPageCount(count);
    }
  };

  const handleSubmit = async () => {
    if (!title || !pdfFile || !coverImage || previewPages.length === 0 || !cost) {
      setError('All * marked fields are required');
      return;
    }

    try {
      await addPrintBazaarDoc({
        title,
        description,
        pdfFile,
        coverImage,
        previewPages,
        cost: isFree ? 0 : cost,
        isFree,
        tags,
        category,
        pageCount,
      });
      onOpenChange(false);
      setTitle('');
      setDescription('');
      setPdfFile(null);
      setCoverImage(null);
      setPreviewPages([]);
      setCost(0);
      setIsFree(false);
      setTags([]);
      setCurrentTag('');
      setError('');
      setCategory('');
      setPageCount(0);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to upload document');
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Upload New Document</DialogTitle>
          <DialogDescription>
            Add your document to the Print Bazaar marketplace.
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right text-sm">Title *</label>
            <Input
              className="col-span-3"
              placeholder="Enter document title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="grid grid-cols-4 items-start gap-4">
            <label className="text-right text-sm">Description</label>
            <Textarea
              className="col-span-3"
              placeholder="Enter document description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right text-sm">Cover Image *</label>
            <div className="col-span-3">
              <Input
                type="file"
                accept="image/*"
                onChange={handleCoverImageChange}
                required
              />
              <p className="text-xs text-gray-500 mt-1">
                Select one image file (jpg, png, etc)
              </p>
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right text-sm">PDF File *</label>
            <div className="col-span-3">
              <Input
                type="file"
                accept=".pdf"
                onChange={handlePdfFileChange}
                required
              />
              {pageCount > 0 && (
                <p className="text-sm text-gray-600 mt-1">
                  {pageCount} pages
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right text-sm">Preview Images *</label>
            <div className="col-span-3">
              <Input
                type="file"
                accept="image/*"
                multiple
                onChange={handlePreviewPageChange}
                required
              />
              <p className="text-xs text-gray-500 mt-1">
                Select 1-3 image files (jpg, png, etc)
              </p>
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right text-sm">Category</label>
            <Select onValueChange={setCategory} value={category}>
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select a category" />
              </SelectTrigger>
              <SelectContent>
                {CATEGORIES.map((cat) => (
                  <SelectItem key={cat} value={cat}>
                    {cat}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <label className="text-right text-sm">Cost (INR) *</label>
            <div className="col-span-3 flex items-center gap-4">
              <Input
                type="number"
                min=""
                max="100"
                step="0.01"
                value={cost}
                onChange={handleCostChange}
                disabled={isFree}
                required
              />
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={isFree}
                  onChange={(e) => {
                    setIsFree(e.target.checked);
                    if (e.target.checked) setCost(0);
                  }}
                  className="h-4 w-4 rounded border-gray-300"
                />
                <span className="text-sm">Free Document</span>
              </label>
            </div>
          </div>

          <div className="grid grid-cols-4 items-start gap-4">
            <label className="text-right text-sm justify-center">Tags</label>
            <div className="col-span-3 space-y-2">
              <div className="flex flex-wrap gap-2">
                {tags.map((tag, index) => (
                  <Badge key={index} variant="secondary" className="flex items-center gap-1">
                    {tag}
                    <button onClick={() => removeTag(index)} className="hover:text-destructive">
                      <X className="h-3 w-3" />
                    </button>
                  </Badge>
                ))}
              </div>
              <Input
                placeholder="Type and press Enter (max 6 tags, 20 chars each)"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                onKeyDown={handleTagKeyDown}
                disabled={tags.length >= 6}
              />
            </div>
          </div>

        </div>

        {error && (
          <p className="text-sm text-destructive mb-4">{error}</p>
        )}

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Upload</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
