import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle } from '../../components/ui/dialog';
import ColorModeSelector from '../../components/printSettings/ColorModeSelector';
import CopiesControl from '../../components/printSettings/CopiesControl';
import usePrintStore from '../../store/printStore';
import { Button } from '../../components/ui/button';
import { Document, PrintBazaarDoc } from '../../types/documentTypes';

interface PrintSettingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  documentId: string;
  bazaarDoc: PrintBazaarDoc;
}

const PrintSettingsDialog: React.FC<PrintSettingsDialogProps> = ({
  isOpen,
  onClose,
  documentId,
  bazaarDoc
}) => {
  const { printSettings, setCurrentStep, documents, addDocument, removeDocument } = usePrintStore();
  const settings = printSettings[documentId] || { color: false, copies: 1 };

  useEffect(() => {
    let isSubscribed = true;

    const addDocumentToStore = async () => {
      if (isOpen && !documents.some(doc => doc.id === documentId)) {
        try {
          const response = await fetch(bazaarDoc.file_path);
          const blob = await response.blob();
          const file = new File([blob], bazaarDoc.file_name, { type: 'application/pdf' });

          if (!isSubscribed) return;

          const newDocument: Document = {
            id: documentId,
            name: bazaarDoc.title || 'Untitled Document',
            url: bazaarDoc.file_path,
            pageCount: bazaarDoc.page_count,
            file,
            source: 'print_bazar' as const
          };
          
          console.log('Adding document to store:', documentId);
          addDocument(newDocument);
        } catch (error) {
          console.error('Error fetching document file:', error);
        }
      }
    };

    if (isOpen) {
      addDocumentToStore();
    }

    // Cleanup function
    return () => {
      isSubscribed = false;
      if (!isOpen) {
        // Clear document when dialog closes unless continuing to print flow
        const currentStep = usePrintStore.getState().currentStep;
        if (currentStep !== 2) {
          removeDocument(documentId);
        }
      }
    };
  }, [isOpen, documentId]);

  const handleContinue = () => {
    if (!documents.find(doc => doc.id === documentId)) {
      console.error('Document not found in print store');
      return;
    }
    
    setCurrentStep(2);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogTitle className="text-lg font-semibold">Print Settings</DialogTitle>
        <div className="grid gap-4 py-4">
          <div className="space-y-4">
            <ColorModeSelector 
              documentId={documentId} 
              isColor={settings.color} 
            />
            
            <CopiesControl 
              documentId={documentId} 
              copies={settings.copies} 
            />
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <Button variant="outline" onClick={onClose}>
            Back
          </Button>
          <Button onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PrintSettingsDialog;
