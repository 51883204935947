import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import GetPrint from './pages/GetPrint';
import MyAccountPage from './pages/MyAccountPage';
import ShopDirectGetPrint from './pages/ShopDirectGetPrint';
import PrintBazaarPage from './pages/PrintBazaarPage';
import PrintBazaarDetailsPage from './pages/PrintBazaarDetailsPage';
import AdminPanel from './pages/AdminPanel';
import ShopRegistrationPage from './pages/ShopRegistrationPage';
import { useAuthStore } from './store/authStore';
import AdvertisingPage from './pages/AdvertisingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';

// Admin route guard component
const AdminRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { user, isAdmin, loading } = useAuthStore();
  
  console.log('AdminRoute rendered with:', { userId: user?.id, isAdmin, loading });
  
  // If user is already confirmed as admin, render the component regardless of loading state
  // This prevents getting stuck in a loading state
  if (user && isAdmin) {
    console.log('User is admin, rendering admin panel');
    return <>{element}</>;
  }
  
  // If we're still loading and don't yet know if user is admin, show loading indicator
  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }
  
  // Otherwise, redirect to home
  console.log('User is not admin, redirecting to home');
  return <Navigate to="/" replace />;
};

function App() {
  const { checkSession } = useAuthStore();
  
  useEffect(() => {
    // Check for existing session on app load
    checkSession();
  }, [checkSession]);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/get-print" element={<GetPrint />} />
        <Route path="/ads" element={<AdvertisingPage />} />
        <Route path="/get-print/shop/:shopId" element={<ShopDirectGetPrint />} />
        <Route path="/my-account" element={<MyAccountPage />} />
        <Route path="/print-bazaar" element={<PrintBazaarPage />} />
        <Route path="/print-bazaar/:documentId" element={<PrintBazaarDetailsPage />} />
        <Route path="/admin" element={<AdminRoute element={<AdminPanel />} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/> } />
        <Route path="/terms-conditions" element={<TermsAndConditions/>} />
        <Route path="/shop-registration" element={<ShopRegistrationPage />} />
      </Routes>
    </Router>
  );
}

export default App;