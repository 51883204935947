import React, { useState } from 'react';
import { FiUploadCloud, FiX } from 'react-icons/fi';
import usePrintStore from '../../store/printStore';
import IconWrapper from './IconWrapper';
import { processFile } from '../../utils/fileProcessing';
import { Document, DocumentSource } from '../../types/documentTypes';

const UploadDocumentCard: React.FC = () => {
  const { documents, addDocument, removeDocument, setCurrentStep } = usePrintStore();
  const [isProcessing, setIsProcessing] = useState(false);
  
  const handleFileSelect = async (file: File) => {
    const processedFile = await processFile(file);
    const newDocument: Document = {
      id: `doc-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      name: processedFile.file.name,
      url: processedFile.url,
      file: processedFile.file,
      pageCount: processedFile.pageCount,
      source: 'default' as DocumentSource
    };
    addDocument(newDocument);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setIsProcessing(true);
      
      try {
        // Process files sequentially to avoid overwhelming the browser
        for (const file of Array.from(files)) {
          await handleFileSelect(file);
        }
      } catch (error) {
        console.error('Error processing files:', error);
        // Could add user-facing error notification here
      } finally {
        setIsProcessing(false);
      }
      
      // Clear the input to allow uploading the same file again
      e.target.value = '';
    }
  };

  const handleRemoveDocument = (id: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent other event handlers
    removeDocument(id);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-2xl mx-auto">
      <h2 className="text-2xl font-semibold mb-4 text-blue-600">Upload Your Documents</h2>

      <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center mb-6">
        <div className="flex justify-center mb-3">
          <IconWrapper icon={FiUploadCloud} className="text-5xl text-gray-400" />
        </div>
        <p className="text-gray-500 mb-2">Drag and drop your files here, or click to browse</p>
        <label className={`bg-blue-500 text-white py-2 px-4 rounded-lg cursor-pointer hover:bg-blue-600 inline-block ${
          isProcessing ? 'opacity-50 cursor-not-allowed' : ''
        }`}>
          {isProcessing ? 'Processing...' : 'Browse Files'}
          <input 
            type="file" 
            className="hidden" 
            multiple 
            onChange={handleFileUpload}
            disabled={isProcessing} 
          />
        </label>
      </div>

      {documents.length > 0 && (
        <div className="mb-6">
          <h3 className="font-medium text-lg mb-2">Uploaded Documents ({documents.length})</h3>
          <ul className="divide-y divide-gray-200">
            {documents.map(doc => (
              <li key={doc.id} className="py-2 flex justify-between items-center">
                <div className="truncate flex-1 mr-2">
                  <span>{doc.name}</span>
                  {doc.pageCount && <span className="text-xs text-gray-500 ml-2">({doc.pageCount} {doc.pageCount === 1 ? 'page' : 'pages'})</span>}
                </div>
                <button 
                  onClick={(e) => handleRemoveDocument(doc.id, e)}
                  className="text-gray-500 hover:text-red-500 p-1 rounded-full hover:bg-gray-100"
                  aria-label="Remove document"
                >
                  <IconWrapper icon={FiX} className="text-lg" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="flex justify-end">
        <button
          onClick={() => setCurrentStep(1)}
          disabled={documents.length === 0 || isProcessing}
          className={`px-6 py-2 rounded-lg ${
            documents.length === 0 || isProcessing
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default UploadDocumentCard;
