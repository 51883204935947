import React, { useEffect, useState, useRef } from 'react';
import { Button } from './ui/button';
import { useAuthStore } from '../store/authStore';
import { useWalletStore } from '../store/walletStore';
import { supabase } from '../lib/supabase/client/supabaseClient';
import { Link, useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const { user, signInWithGoogle, signOut, loading, checkSession, setLoading, isAdmin } = useAuthStore();
  const { balance, fetchWalletBalance, setAmount, isLoading, handleAddMoney } = useWalletStore();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const fetchedRef = useRef(false);
  const navigate = useNavigate();

  // Log the current auth state on render
  console.log('Header rendered with auth state:', { userId: user?.id, isAdmin, loading });

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Check the session when component mounts
  useEffect(() => {
    // Reset loading state on component mount to fix stuck loading state
    setLoading(false);

    checkSession();

    // Set up auth state change listener
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log('Auth state changed:', { event, userId: session?.user?.id });
        // Reset loading state whenever auth state changes
        setLoading(false);

        if (session) {
          console.log('Setting user state after auth change');
          useAuthStore.setState({ user: session.user, session });
          // We need to check admin status here too
          console.log('Checking admin status after auth change');
          useAuthStore.getState().checkAdminStatus(session.user);
        } else {
          console.log('Clearing user state after auth change');
          useAuthStore.setState({ user: null, session: null, isAdmin: false });
          // Reset wallet state on signout
          useWalletStore.getState().resetWalletState();
          fetchedRef.current = false;
        }
      }
    );

    // Clean up the listener
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [checkSession, setLoading]);

  // Fetch wallet balance only once when user is logged in
  useEffect(() => {
    if (user && !fetchedRef.current) {
      fetchedRef.current = true;
      fetchWalletBalance();
    }
  }, [user]);

  const handleMyAccount = () => {
    setDropdownOpen(false);
    // Navigate to account page
    navigate('/my-account');
  };

  const handleAdminPanel = () => {
    setDropdownOpen(false);
    navigate('/admin');
  };

  // Handle Google Sign In with additional loading state management
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      // Ensure loading is reset if there's an error
      setLoading(false);
    }
  };

  // Handle the Add to Wallet button in dropdown
  const handleAddToWallet = () => {
    // Use the new persistent function instead
    setAmount(10);
    handleAddMoney();
    setDropdownOpen(false);
  };

  // Add debug log when dropdown opens
  const toggleDropdown = () => {
    const newState = !dropdownOpen;
    console.log('Toggling dropdown:', newState, 'isAdmin:', isAdmin);
    setDropdownOpen(newState);
  };

  return (
    <header className="bg-white shadow-sm sticky top-0 z-50">
      <div className="container mx-auto py-3 sm:py-4 px-3 sm:px-4 flex justify-between items-center">
        <div className="flex items-center space-x-2 sm:space-x-3">
          <Link to="/" className="flex items-center">
            <img
              src="/ctrlp_white_nobg.png"
              alt="CTRL+P"
              className="h-6 sm:h-8 w-auto"
              style={{ filter: 'brightness(0.4) sepia(1) hue-rotate(190deg) saturate(4)' }}
            />
          </Link>
          <div className="ml-1 sm:ml-3 md:ml-6">
            <Link
              to="/print-bazaar"
              className="relative group flex items-center px-2 sm:px-2.5 py-1 rounded-full bg-blue-50 hover:bg-blue-100 transition-colors"
            >
              <span className="text-blue-600 font-medium text-xs sm:text-[13px]">PrintBazaar</span>
              <span className="ml-1 sm:ml-1.5 px-1 py-[0.5px] text-[8px] sm:text-[9px] font-bold bg-yellow-400 text-yellow-800 rounded-full leading-none">
                NEW
              </span>
              <div className="absolute inset-0 border-2 border-blue-200 rounded-full transition-all scale-105 opacity-0 group-hover:opacity-100"></div>
            </Link>
          </div>
        </div>

        <div className="flex items-center space-x-2 sm:space-x-3">
          {user && (
            <div className="flex items-center bg-blue-50 px-2 sm:px-3 py-1 sm:py-1.5 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 sm:h-4 sm:w-4 text-blue-600 mr-1 sm:mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
              <span className="text-xs sm:text-sm font-medium text-blue-800">
                ₹{balance !== null ? `${balance?.toFixed(2)}` : '0.00'}
              </span>
            </div>
          )}

          {user ? (
            <div className="relative" ref={dropdownRef}>
              <div
                className="flex items-center gap-1 sm:gap-2 cursor-pointer py-1.5 sm:py-2 px-2 sm:px-3 rounded-md hover:bg-gray-100 transition-colors duration-200"
                onClick={toggleDropdown}
              >
                {user.user_metadata.avatar_url && (
                  <img
                    src={user.user_metadata.avatar_url}
                    alt={user.user_metadata.full_name || "User"}
                    className="w-7 h-7 sm:w-8 sm:h-8 rounded-full"
                  />
                )}
                <span className="text-xs sm:text-sm font-medium md:inline hidden">
                  Hi, {user.user_metadata.full_name?.split(' ')[0] || 'User'}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-3.5 w-3.5 sm:h-4 sm:w-4 transition-transform duration-200 ${dropdownOpen ? 'transform rotate-180' : ''}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>

              {/* Dropdown menu */}
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-40 sm:w-48 bg-white rounded-md shadow-lg py-1 z-50 border border-gray-200 transform origin-top-right transition-all duration-200 ease-out">
                  <button
                    className="w-full text-left px-3 sm:px-4 py-2 text-xs sm:text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                    onClick={handleAddToWallet}
                  >
                    Add ₹10 to Wallet
                  </button>
                  <button
                    className="w-full text-left px-3 sm:px-4 py-2 text-xs sm:text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                    onClick={handleMyAccount}
                  >
                    My Account
                  </button>

                  {/* Admin Panel button only for admins */}
                  {isAdmin && (
                    <button
                      className="w-full text-left px-3 sm:px-4 py-2 text-xs sm:text-sm text-blue-600 hover:bg-gray-100 transition-colors flex items-center"
                      onClick={handleAdminPanel}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 sm:h-4 sm:w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      Admin Panel
                    </button>
                  )}

                  <button
                    className="w-full text-left px-3 sm:px-4 py-2 text-xs sm:text-sm text-gray-700 hover:bg-gray-100 transition-colors"
                    onClick={signOut}
                  >
                    {isSigningOut ? 'Signing out...' : 'Sign Out'}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Button
              onClick={handleGoogleSignIn}
              disabled={loading}
              className="flex items-center gap-1 sm:gap-1.5 bg-white text-gray-700 border border-gray-300 hover:bg-gray-100 text-xs sm:text-sm py-1 sm:py-1.5 px-2 sm:px-3 h-auto min-h-0 rounded-full"
            >
              <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
                <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
                <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
                <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
              </svg>
              <span className="whitespace-nowrap">{loading ? 'Signing in...' : 'Sign in'}</span>
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;