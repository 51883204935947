import { create } from 'zustand';
import { supabase } from '../lib/supabase/client/supabaseClient';
import { Session, User } from '@supabase/supabase-js';
import { createUserIfNotExists } from '../lib/supabase/api/user-service';
import { checkIfUserIsAdmin } from '../lib/supabase/api/admin-service';
import { persist } from 'zustand/middleware';

interface AuthState {
  user: User | null;
  session: Session | null;
  loading: boolean;
  error: string | null;
  isAdmin: boolean;
  previousPrintStep: number | null;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  checkSession: () => Promise<void>;
  checkAdminStatus: (user: User | null) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
  setPreviousPrintStep: (step: number | null) => void;
  clearPreviousPrintStep: () => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      session: null,
      loading: false,
      error: null,
      isAdmin: false,
      previousPrintStep: null,
      
      setLoading: (isLoading) => set({ loading: isLoading }),
      
      setPreviousPrintStep: (step) => set({ previousPrintStep: step }),
      
      clearPreviousPrintStep: () => set({ previousPrintStep: null }),
      
      checkAdminStatus: async (user) => {
        console.log('checkAdminStatus called for user:', user?.id);
        try {
          // Don't set loading: true here, as it might interfere with other operations
          const isAdmin = await checkIfUserIsAdmin(user);
          console.log('Setting isAdmin state to:', isAdmin);
          set({ isAdmin });
          
          // Make sure loading is false after admin check
          set({ loading: false });
        } catch (error) {
          console.error('Error checking admin status:', error);
          set({ isAdmin: false, loading: false });
        }
      },
      
      signInWithGoogle: async () => {
        try {
          set({ loading: true, error: null });
          
          // Redirect to Google OAuth
          await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
              redirectTo: window.location.origin + window.location.pathname,
            },
          });
          
        } catch (error) {
          if (error instanceof Error) {
            set({ error: error.message });
          } else {
            set({ error: 'An unknown error occurred' });
          }
          set({ loading: false });
        }
      },
      
      signOut: async () => {
        try {
          set({ loading: true, error: null });
          await supabase.auth.signOut();
          set({ user: null, session: null, isAdmin: false });
        } catch (error) {
          if (error instanceof Error) {
            set({ error: error.message });
          } else {
            set({ error: 'An unknown error occurred' });
          }
        } finally {
          set({ loading: false });
        }
      },
      
      checkSession: async () => {
        try {
          set({ loading: true, error: null });
          const { data, error } = await supabase.auth.getSession();
          
          if (error) throw error;
          
          if (data.session?.user) {
            console.log('Session found for user:', data.session.user.id);
            set({ 
              session: data.session,
              user: data.session.user 
            });
            
            // Create user in database if they don't exist yet
            await createUserIfNotExists(data.session.user);
            
            // Check if user is an admin
            console.log('Checking admin status during session check');
            await get().checkAdminStatus(data.session.user);
          } else {
            console.log('No session found');
            set({
              session: null,
              user: null,
              isAdmin: false
            });
          }
        } catch (error) {
          if (error instanceof Error) {
            console.error('Error in checkSession:', error.message);
            set({ error: error.message });
          } else {
            console.error('Unknown error in checkSession');
            set({ error: 'An unknown error occurred' });
          }
        } finally {
          set({ loading: false });
        }
      }
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        previousPrintStep: state.previousPrintStep,
        // Don't persist sensitive information
      }),
    }
  )
);