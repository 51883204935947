import React, { useRef, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import '../styles/animations.css'; // Import the animations CSS file

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const servicesRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const printerRef = useRef<HTMLDivElement>(null);
  
  // Animation effect for the printer
  useEffect(() => {
    const printer = printerRef.current;
    if (!printer) return;
    
    // Simple bobbing animation
    let direction = 1;
    let position = 0;
    
    const animate = () => {
      if (printer) {
        position += 0.1 * direction;
        if (position > 5) direction = -1;
        if (position < -5) direction = 1;
        printer.style.transform = `translateY(${position}px)`;
      }
      requestAnimationFrame(animate);
    };
    
    animate();
    
    return () => {
      // Cleanup if needed
    };
  }, []);
  
  const services = [
    {
      icon: "upload",
      title: "Upload Document",
      description: "Simply select your file and set your printing preferences.",
      delay: 0.2
    },
    {
      icon: "map-pin",
      title: "Select Print Shop",
      description: "Choose a nearby Ctrl+P-enabled store for pickup.",
      delay: 0.4
    },
    {
      icon: "credit-card",
      title: "Pay Online",
      description: "Securely complete your payment, no cash needed.",
      delay: 0.6
    },
    {
      icon: "printer",
      title: "Collect Your Print",
      description: "Visit the shop and pick up your high-quality prints.",
      delay: 0.8
    }
  ];

  const features = [
    {
      icon: "book",
      title: "Cheat Sheets & PYQs",
      description: "Access Exam Cheat Sheets, Previous Year Questions, and Revision Notes.",
      delay: 0.2
    },
    {
      icon: "graduation-cap",
      title: "Interview Prep",
      description: "Get Handpicked Interview Resources and Placement Papers.",
      delay: 0.4
    },
    {
      icon: "landmark",
      title: "College Notes",
      description: "Find Subject-Wise College Notes and Essential PDFs.",
      delay: 0.6
    },
    {
      icon: "printer",
      title: "Direct Print",
      description: "Simply Select, Pay, and Print - Fully Automated.",
      delay: 0.8
    }
  ];

  const handleStartPrinting = () => {
    navigate('/get-print');
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-grow">
        {/* Enhanced Hero section with animated printer */}
        <section className="bg-gradient-to-b from-blue-50 to-white py-10 px-4">
          <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between min-h-[70vh] relative overflow-hidden">
            {/* Left side content */}
            <div className="lg:w-1/2 z-10 text-center lg:text-left mb-10 lg:mb-0">
              <div className="mb-4">
                <img 
                  src="/ctrlp_white_nobg.png" 
                  alt="CTRL+P Logo" 
                  className="h-20 md:h-24 lg:h-28 mx-auto lg:mx-0 w-auto object-contain"
                  style={{ filter: 'brightness(0.4) sepia(1) hue-rotate(190deg) saturate(4)' }}

                />
              </div>
              <p className="text-xl text-gray-600 mb-8">Your one-stop solution for quick and easy document printing</p>
              <button 
                onClick={handleStartPrinting}
                className="print-button bg-blue-600 hover:bg-blue-700 text-white font-bold py-4 px-8 rounded-lg transition-all duration-300 shadow-md hover:shadow-lg relative overflow-hidden group"
              >
                <span className="relative z-10">Start Printing</span>
                <span className="absolute bottom-0 left-0 w-full h-0 bg-blue-800 transition-all duration-300 group-hover:h-full -z-0"></span>
                {/* Paper coming out animation on hover */}
                <span className="paper absolute -top-10 left-1/2 transform -translate-x-1/2 w-8 h-8 bg-white opacity-0 group-hover:opacity-100 group-hover:translate-y-10 transition-all duration-700">
                  <span className="absolute top-1 left-1 right-1 h-0.5 bg-gray-300"></span>
                  <span className="absolute top-3 left-1 right-1 h-0.5 bg-gray-300"></span>
                  <span className="absolute top-5 left-1 right-1 h-0.5 bg-gray-300"></span>
                </span>
              </button>
            </div>
            
            {/* Right side animated printer */}
            <div className="lg:w-1/2 flex justify-center items-center relative">
              <div className="floating-papers absolute w-full h-full">
                {[...Array(8)].map((_, i) => (
                  <div 
                    key={i} 
                    className="paper absolute bg-white rounded shadow-md opacity-80" 
                    style={{
                      width: `${Math.random() * 60 + 40}px`,
                      height: `${Math.random() * 60 + 60}px`,
                      left: `${Math.random() * 80}%`,
                      top: `${Math.random() * 80}%`,
                      animation: `float ${Math.random() * 8 + 10}s infinite ease-in-out, 
                                  fadeInOut ${Math.random() * 5 + 5}s infinite alternate ease-in-out`,
                      animationDelay: `${i * 0.5}s`
                    }}
                  >
                    <div className="h-1 w-3/4 mt-3 ml-3 bg-gray-300 rounded"></div>
                    <div className="h-1 w-1/2 mt-3 ml-3 bg-gray-300 rounded"></div>
                    <div className="h-1 w-2/3 mt-3 ml-3 bg-gray-300 rounded"></div>
                  </div>
                ))}
              </div>
              
              <div ref={printerRef} className="printer-container transition-all">
                <div className="printer w-64 h-40 bg-gradient-to-b from-gray-700 to-gray-800 rounded-lg shadow-xl relative">
                  <div className="printer-top w-full h-6 bg-gray-600 rounded-t-lg"></div>
                  <div className="printer-slot w-48 h-2 bg-black absolute top-12 left-8 right-8"></div>
                  <div className="printer-panel absolute top-20 right-4 flex flex-col">
                    <div className="w-3 h-3 rounded-full bg-green-400 mb-2 animate-pulse"></div>
                    <div className="w-3 h-3 rounded-full bg-blue-400 mb-2"></div>
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                  </div>
                  <div className="printer-paper h-16 w-32 bg-white absolute -top-14 left-16 rounded-sm shadow-md flex flex-col justify-center items-center animate-printPaper">
                    <div className="w-20 h-1 bg-gray-300 mb-2"></div>
                    <div className="w-16 h-1 bg-gray-300 mb-2"></div>
                    <div className="w-24 h-1 bg-gray-300"></div>
                  </div>
                  <div className="printer-tray w-56 h-6 bg-gray-600 absolute -bottom-6 left-4 rounded-b-lg"></div>
                </div>
              </div>
            </div>
            
            {/* Background decorative elements */}
            <div className="absolute top-0 right-0 w-96 h-96 bg-blue-100 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob"></div>
            <div className="absolute bottom-0 left-20 w-80 h-80 bg-blue-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob animation-delay-2"></div>
          </div>
        </section>
        
        {/* Services section - fix ref type */}
        <div ref={servicesRef} className="py-16 px-4 bg-white">
          <div className="container mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-2">Amazing <span className="text-blue-600">Services</span> for You</h2>
              <p className="text-gray-600">Get your prints in just 4 simple steps!</p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {services.map((service, index) => (
                <Card key={index} className="transition-all hover:shadow-lg hover:-translate-y-1">
                  <CardHeader>
                    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                      <i className={`text-blue-600 text-xl`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-${service.icon}">
                          {service.icon === "upload" && (
                            <>
                              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                              <polyline points="17 8 12 3 7 8" />
                              <line x1="12" y1="3" x2="12" y2="15" />
                            </>
                          )}
                          {service.icon === "map-pin" && (
                            <>
                              <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
                              <circle cx="12" cy="10" r="3" />
                            </>
                          )}
                          {service.icon === "credit-card" && (
                            <>
                              <rect width="20" height="14" x="2" y="5" rx="2" />
                              <line x1="2" y1="10" x2="22" y2="10" />
                            </>
                          )}
                          {service.icon === "printer" && (
                            <>
                              <polyline points="6 9 6 2 18 2 18 9" />
                              <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                              <rect width="12" height="8" x="6" y="14" />
                            </>
                          )}
                        </svg>
                      </i>
                    </div>
                    <CardTitle>{service.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <CardDescription>{service.description}</CardDescription>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </div>
        
        {/* Features section - fix ref type */}
        <div ref={featuresRef} className="py-16 px-4 bg-gray-50">
          <div className="container mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-2">Print<span className="text-blue-600">Bazaar</span> Features</h2>
              <p className="text-gray-600">A Pre-Uploaded Document Marketplace for Quick Access</p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {features.map((feature, index) => (
                <Card key={index} className="transition-all hover:shadow-lg hover:-translate-y-1">
                  <CardHeader>
                    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                      <i className={`text-blue-600 text-xl`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`lucide lucide-${feature.icon}`}>
                          {feature.icon === "book" && (
                            <>
                              <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
                            </>
                          )}
                          {feature.icon === "graduation-cap" && (
                            <>
                              <path d="M22 10v6M2 10l10-5 10 5-10 5z" />
                              <path d="M6 12v5c0 2 2 3 6 3s6-1 6-3v-5" />
                            </>
                          )}
                          {feature.icon === "landmark" && (
                            <>
                              <line x1="3" y1="22" x2="21" y2="22" />
                              <line x1="6" y1="18" x2="6" y2="11" />
                              <line x1="10" y1="18" x2="10" y2="11" />
                              <line x1="14" y1="18" x2="14" y2="11" />
                              <line x1="18" y1="18" x2="18" y2="11" />
                              <polygon points="12 2 20 7 4 7" />
                            </>
                          )}
                          {feature.icon === "printer" && (
                            <>
                              <polyline points="6 9 6 2 18 2 18 9" />
                              <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                              <rect width="12" height="8" x="6" y="14" />
                            </>
                          )}
                        </svg>
                      </i>
                    </div>
                    <CardTitle>{feature.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <CardDescription>{feature.description}</CardDescription>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default LandingPage;
