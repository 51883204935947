import { supabase } from '../client/supabaseClient';

export interface Transaction {
    id: string;
    created_at: string;
    amount: number;
    transaction_type: 'CREDIT' | 'DEBIT';
    reason: 'Wallet Recharge' | 'Print Order' | 'Print Bazaar Purchase';
}

/**
 * Fetches the current user's wallet transactions from the database.
 * Calls the 'fetch_my_transactions' RPC in the 'api' schema.
 */
export async function fetchMyTransactions(): Promise<Transaction[]> {
    try {
        const { data, error } = await supabase
            .schema('api')
            .rpc('fetch_my_transactions');

        if (error) {
            console.error('Error fetching transactions:', error);
            return [];
        }

        return data;
    } catch (error) {
        console.error('Unexpected error in fetchMyTransactions:', error);
        return [];
    }
}
