/* eslint-disable @typescript-eslint/no-unused-vars */
import { supabase } from '../client/supabaseClient';
import apiClient from '../client/apiClient';
type PrintJobResult = {
    id: string;
    user_id: string;
    shop_id: string;
    status: string;
    eta: number;
    // Add other fields that are being returned
};

/**
 * Creates a Print Job in the PrintJobs table using Supabase RPC
 * Calls the `api.add_print_job` function
 */
export async function addPrintJob({
    shop_id,
    group_id,
    copies,
    pages,
    amount,
    printsettings_code,
    combined_file_path,
    cover_invoice_path,
    file_path,
    file_name,
    status = 'pending',
    source = 'default'
}: {
    shop_id: string;
    group_id: string;
    copies: number;
    pages: number;
    amount: number;
    printsettings_code: number;
    combined_file_path: string;
    cover_invoice_path: string;
    file_path: string;
    file_name: string;
    status?: 'pending' | 'processing' | 'completed' | 'failed' | 'cancelled';
    source?: 'print_bazar' | 'default';
}): Promise<PrintJobResult | null> {
    try {
        console.log('adding print job...');
        console.log('Request payload:', {
            shop_id,
            group_id,
            file_path,
            cover_invoice_path,
            copies,
            pages,
            printsettings_code,
            status,
            source,
            amount,
            file_name,
        });

        // Capture both data and error
        //const { data, error } = await supabase
        //    .schema('api')
        //    .rpc('add_print_job', {
        //        _shop_id: shop_id,
        //        _amount: amount,
        //        _number_of_pages: number_of_pages,
        //        _printsettings_code: printsettings_code,
        //        _combined_file_path: combined_file_path,
        //        _cover_invoice_path: cover_invoice_path,
        //        _file_path: file_path,
        //        _file_name: file_name,
        //        _status: status,
        //        _source: source
        //    });
        //    console.log("error", error);

        //if (error) {
        //    console.error('Error adding print job:', error);
        //    throw new Error(`Failed to add print job: ${error.message}`);
        //}
        const response = await apiClient.post('/jobs', {
            shop_id,
            group_id,
            file_path,
            cover_invoice_path,
            copies,
            pages,
            printsettings_code,
            status,
            source,
            amount,
            file_name,
        });
        const data: PrintJobResult = response.data;
        console.log('Print job added successfully:', data);
        return data;
    } catch (error) {
        console.error('Unexpected error in addPrintJob:', error);
        throw error;
    }
}