import { PageSelection } from '../types/documentTypes';

/**
 * Calculates the effective number of pages based on page selection
 * @param pageSelection Page selection string or object (e.g., "1-5,8,10-12" or PageSelection object)
 * @param totalPages Total number of pages in the original PDF
 * @returns Number of effective pages after selection
 */
export function calculateEffectivePageCount(
  pageSelection: string | PageSelection | undefined,
  totalPages: number
): number {
  // If pageSelection is empty or undefined, return the total number of pages
  if (!pageSelection) {
    return totalPages;
  }

  // Handle PageSelection object
  if (typeof pageSelection !== 'string') {
    // Handle 'all' type - all pages selected
    if (pageSelection.type === 'all') {
      return totalPages;
    }
    
    // Handle 'range' type
    if (pageSelection.type === 'range' && pageSelection.range) {
      const { start, end } = pageSelection.range;
      // Make sure start and end are valid
      const validStart = Math.max(1, start);
      const validEnd = Math.min(end, totalPages);
      
      if (validEnd >= validStart) {
        return validEnd - validStart + 1;
      }
      return totalPages;
    }
    
    // Handle 'custom' type
    if (pageSelection.type === 'custom' && pageSelection.customPages) {
      // Filter out any page numbers that exceed totalPages
      const validPages = pageSelection.customPages.filter(
        p => p >= 1 && p <= totalPages
      );
      return validPages.length || totalPages;
    }
    
    // Default to all pages if no valid selection
    return totalPages;
  }

  // Handle string format (e.g., "1-5,8,10-12")
  const selectedPages = new Set<number>();
  
  // Split by comma to get individual selections
  const selections = pageSelection.split(',');
  
  selections.forEach(selection => {
    selection = selection.trim();
    
    if (selection.includes('-')) {
      // Handle page ranges (e.g., "1-5")
      const [start, end] = selection.split('-').map(Number);
      
      if (!isNaN(start) && !isNaN(end)) {
        // Add all pages in the range
        for (let i = Math.max(1, start); i <= Math.min(end, totalPages); i++) {
          selectedPages.add(i);
        }
      }
    } else {
      // Handle individual pages (e.g., "8")
      const pageNum = Number(selection);
      if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= totalPages) {
        selectedPages.add(pageNum);
      }
    }
  });
  
  // Return the number of selected pages or all pages if none were selected
  return selectedPages.size || totalPages;
}

/**
 * Converts PageSelection object to a string representation
 * to be used with PDF trimming functions
 * @param pageSelection PageSelection object or string
 * @param totalPages Total number of pages in the PDF
 * @returns A string representation of the page selection (e.g., "1-5,8,10-12")
 */
export function pageSelectionToString(
  pageSelection: string | PageSelection | undefined,
  totalPages: number
): string {
  // If it's already a string or undefined, return it (or empty string for undefined)
  if (typeof pageSelection === 'string' || !pageSelection) {
    return pageSelection || '';
  }
  
  // Handle PageSelection object
  if (pageSelection.type === 'all') {
    // For 'all' type, return a range from 1 to totalPages
    return `1-${totalPages}`;
  }
  
  if (pageSelection.type === 'range' && pageSelection.range) {
    const { start, end } = pageSelection.range;
    // Make sure start and end are valid
    const validStart = Math.max(1, start);
    const validEnd = Math.min(end, totalPages);
    
    if (validEnd >= validStart) {
      return `${validStart}-${validEnd}`;
    }
  }
  
  if (pageSelection.type === 'custom' && pageSelection.customPages && pageSelection.customPages.length > 0) {
    // Convert custom pages array to string (e.g., [1, 2, 5, 8] => "1,2,5,8")
    return pageSelection.customPages
      .filter(p => p >= 1 && p <= totalPages) // Filter out invalid pages
      .sort((a, b) => a - b) // Sort numerically
      .join(',');
  }
  
  return ''; // Default to empty string for invalid selections
}
