import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../store/authStore';
import { FiSearch, FiFilter, FiBookOpen, FiCopy, FiClock, FiHeart, FiTrendingUp } from 'react-icons/fi';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Card, CardContent, CardFooter } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Separator } from '../components/ui/separator';
import { Badge } from '../components/ui/badge';
import AntiPiracyBanner from '../components/print-bazaar/AntiPiracyBanner';
import CategorySelector from '../components/print-bazaar/CategorySelector';
import IconWrapper from '../components/print-steps/IconWrapper';
import { fetchPrintBazaarDocs, fetchFreePrintBazaarDocs } from '../lib/supabase/api/fetch-print-bazaar-docs';
import { useNavigate } from 'react-router-dom';

// Define interface for document type
interface Document {
  id: string;
  title: string;
  author: string;
  authorId: string;
  category: string;
  cost: number;
  is_free: boolean;
  page_count: number;
  pages: number;
  rating: number;
  reviews: number;
  image: string;
  tags: string[];
  featured: boolean;
  description: string;
  created_at: string;
}

// Define interface for API document response
interface ApiDocument {
  id: string;
  user_id: string;
  display_name: string;
  title: string;
  description: string;
  file_name: string;
  cover_image_path: string;
  file_size: number;
  file_type: string;
  status: string;
  created_at: string;
  updated_at: string;
  views: number;
  downloads: number;
  cost: number | null;
  is_free: boolean;
  page_count: number;
  category: string;
  tags: string[];
}

// Available categories for filtering
const CATEGORIES = [
  'All Categories',
  'Computer Science',
  'Mathematics',
  'Physics',
  'Chemistry',
  'Electronics',
  'Literature',
  'Biology',
  'Economics',
  'Management'
];

// Redesigned Note Card component
const NoteCard: React.FC<{ document: Document }> = ({ document }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/print-bazaar/${document.id}`)}
      className="flex flex-col bg-white rounded-md overflow-hidden shadow-sm hover:shadow-lg transition-all duration-200 cursor-pointer border-y-[2px] border-x-[2px] border-[#1e40af]"
    >
      {/* Document image */}
      <div className="relative">
        <div 
          className="w-full h-28 bg-cover bg-center"
          style={{ backgroundImage: `url(${document.image})` }}
        />
        
        {document.featured && (
          <Badge className="absolute top-1 right-1 bg-amber-500 text-xs px-1.5 py-0.5 z-10">
            Featured
          </Badge>
        )}
        
        {/* Page count badge */}
        <div className="absolute bottom-1 right-1 bg-white/80 backdrop-blur-sm text-xs px-1.5 py-0.5 rounded-sm flex items-center">
          <IconWrapper icon={FiCopy} className="mr-1 w-2.5 h-2.5" />
          {document.pages} pages
        </div>
      </div>
      
      {/* Document details */}
      <div className="p-2 flex-grow flex flex-col justify-between border-t border-gray-100">
        <div>
          <h3 className="text-sm font-medium line-clamp-1 mb-0.5">{document.title}</h3>
          <p className="text-xs text-gray-600 mb-1">{document.author}</p>
        </div>
        
        <div className="flex justify-between items-center mt-1 pt-1 border-t border-gray-100">
          <span className="text-xs font-bold text-blue-600">
            {document.is_free ? 'Free' : `₹${document.cost}`}
          </span>
          <div className="flex items-center">
            <span className="text-amber-500 text-xs mr-0.5">★</span>
            <span className="text-xs text-gray-700">{document.rating}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const PrintBazaarPage: React.FC = () => {
  const { user, loading, checkSession } = useAuthStore();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [activeTab, setActiveTab] = useState('all');
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const [allDocuments, setAllDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [priceRange, setPriceRange] = useState({ min: '', max: '' });
  const [tempPriceRange, setTempPriceRange] = useState({ min: '', max: '' });
  const [sortBy, setSortBy] = useState('popular');
  
  // Check user session when component mounts
  useEffect(() => {
    if (!user) {
      checkSession();
    }
  }, [user, checkSession]);

  // Fetch documents from API
  useEffect(() => {
    const fetchDocuments = async () => {
      setIsLoading(true);
      try {
        const apiDocuments = await fetchPrintBazaarDocs(false);
        console.log('Raw API response:', apiDocuments);
        
        if (apiDocuments) {
          const documents: Document[] = apiDocuments.map((doc: ApiDocument) => ({
            id: doc.id,
            title: doc.title || "Untitled Document",
            author: doc.display_name || "Unknown Author",
            authorId: doc.user_id,
            description: doc.description || "No description available",
            image: doc.cover_image_path || "https://placehold.co/500x300/e2e8f0/64748b?text=No+Image",
            category: doc.category || "General",
            cost: Number(doc.cost || 0),
            is_free: Boolean(doc.is_free),
            page_count: Number(doc.page_count || 0),
            pages: Number(doc.page_count || 0),
            rating: 5.0,
            reviews: doc.views || 0,
            tags: Array.isArray(doc.tags) ? doc.tags : [doc.file_type || "Document"],
            featured: doc.views > 50 || doc.downloads > 10,
            created_at: doc.created_at
          }));
          
          console.log('Processed documents:', documents);
          setAllDocuments(documents);
          setFilteredDocuments(documents);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        setAllDocuments([]);
        setFilteredDocuments([]);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchDocuments();
  }, []);

  // Filter documents based on search query, selected category, and active tab
  useEffect(() => {
    const filterAndMapDocs = async () => {
      setIsLoading(true);
      try {
        let docs = [...allDocuments];

        // Apply filters
        if (searchQuery.trim()) {
          docs = docs.filter(doc => 
            doc.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
            doc.description?.toLowerCase().includes(searchQuery.toLowerCase())
          );
        }

        if (selectedCategory !== 'All Categories') {
          docs = docs.filter(doc => doc.category?.toLowerCase() === selectedCategory.toLowerCase());
        }

        // Apply price range filter
        if (priceRange.min !== '' || priceRange.max !== '') {
          docs = docs.filter(doc => {
            const cost = doc.cost || 0;
            const min = priceRange.min !== '' ? parseFloat(priceRange.min) : -Infinity;
            const max = priceRange.max !== '' ? parseFloat(priceRange.max) : Infinity;
            return cost >= min && cost <= max;
          });
        }

        if (activeTab === 'free') {
          docs = docs.filter(doc => doc.is_free);
        } else if (activeTab === 'featured') {
          docs = docs.filter(doc => doc.featured);
        }

        // Apply sorting
        docs = docs.sort((a, b) => {
          switch (sortBy) {
            case 'price-low':
              return (a.cost || 0) - (b.cost || 0);
            case 'price-high':
              return (b.cost || 0) - (a.cost || 0);
            case 'newest':
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            default:
              return 0;
          }
        });

        setFilteredDocuments(docs);
      } catch (error) {
        console.error('Error filtering documents:', error);
      } finally {
        setIsLoading(false);
      }
    };

    filterAndMapDocs();
  }, [searchQuery, selectedCategory, activeTab, allDocuments, priceRange, sortBy]);

  // Handle price range apply
  const handlePriceRangeApply = () => {
    setPriceRange(tempPriceRange);
  };

  // Add reset price range function
  const handleResetPriceRange = () => {
    setPriceRange({ min: '', max: '' });
    setTempPriceRange({ min: '', max: '' });
  };

  // Add handler for mobile filters reset
  const handleMobileFiltersReset = () => {
    setSelectedCategory('All Categories');
    setTempPriceRange({ min: '', max: '' });
  };

  // Handle mobile apply filters
  const handleMobileApplyFilters = () => {
    setPriceRange(tempPriceRange);
    setShowMobileFilter(false);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header />
      
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-8 md:py-10">
          <div className="container mx-auto px-3 md:px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-3">PrintBazaar</h1>
              <p className="text-base md:text-xl mb-4 md:mb-6">Discover quality academic materials created by students and educators</p>
              <div className="relative">
                <div className="flex gap-2 md:gap-0">
                  <div className="relative flex-grow">
                    <Input
                      type="text"
                      placeholder="Search for notes, guides, practice papers..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full pl-10 pr-4 py-3 rounded-md md:rounded-l-md text-gray-800 placeholder-gray-500 focus:ring-2 focus:ring-blue-400"
                    />
                    <div className="absolute left-3 top-3 text-gray-400">
                      <IconWrapper icon={FiSearch} className="w-5 h-5" />
                    </div>
                  </div>
                  <Button
                    onClick={() => setShowMobileFilter(!showMobileFilter)}
                    className="md:hidden bg-white text-blue-800 hover:bg-gray-100 rounded-md px-3"
                  >
                    <IconWrapper icon={FiFilter} className="w-5 h-5" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Anti-Piracy Banner */}
        <AntiPiracyBanner />
        
        <div className="container mx-auto px-3 md:px-4 py-4 md:py-6">
          <div className="flex flex-col md:flex-row gap-4 md:gap-6">
            {/* Filters Panel - Desktop */}
            <div className="hidden md:block w-full md:w-64 space-y-6">
              <Card>
                <CardContent className="p-4">
                  <h3 className="font-semibold text-lg mb-3">Categories</h3>
                  <CategorySelector 
                    categories={CATEGORIES}
                    selectedCategory={selectedCategory}
                    onChange={setSelectedCategory}
                  />
                </CardContent>
              </Card>
              
              <Card>
                <CardContent className="p-4 space-y-3">
                  <h3 className="font-semibold text-lg mb-2">Price Range</h3>
                  <div className="grid grid-cols-2 gap-2">
                    <Input 
                      type="number" 
                      placeholder="Min" 
                      className="text-sm"
                      value={tempPriceRange.min}
                      onChange={(e) => setTempPriceRange(prev => ({ ...prev, min: e.target.value }))}
                    />
                    <Input 
                      type="number" 
                      placeholder="Max" 
                      className="text-sm"
                      value={tempPriceRange.max}
                      onChange={(e) => setTempPriceRange(prev => ({ ...prev, max: e.target.value }))}
                    />
                  </div>
                  <div className="space-y-2">
                    <Button 
                      className="w-full bg-blue-600 hover:bg-blue-700"
                      onClick={handlePriceRangeApply}
                    >
                      Apply
                    </Button>
                    <Button 
                      variant="outline"
                      className="w-full text-blue-600 border-blue-600 hover:bg-blue-50"
                      onClick={handleResetPriceRange}
                    >
                      Reset
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
            
            {/* Mobile Filters Panel */}
            {showMobileFilter && (
              <div className="md:hidden bg-white p-4 rounded-lg shadow-md mb-4 space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-semibold">Filters</h3>
                  <Button 
                    variant="ghost" 
                    onClick={() => setShowMobileFilter(false)}
                    className="h-8 w-8 p-0"
                  >
                    ✕
                  </Button>
                </div>
                
                <div>
                  <h4 className="font-medium mb-2">Categories</h4>
                  <CategorySelector 
                    categories={CATEGORIES}
                    selectedCategory={selectedCategory}
                    onChange={setSelectedCategory}
                  />
                </div>
                
                <div>
                  <h4 className="font-medium mb-2">Price Range</h4>
                  <div className="grid grid-cols-2 gap-2">
                    <Input 
                      type="number" 
                      placeholder="Min" 
                      className="text-sm"
                      value={tempPriceRange.min}
                      onChange={(e) => setTempPriceRange(prev => ({ ...prev, min: e.target.value }))}
                    />
                    <Input 
                      type="number" 
                      placeholder="Max" 
                      className="text-sm"
                      value={tempPriceRange.max}
                      onChange={(e) => setTempPriceRange(prev => ({ ...prev, max: e.target.value }))}
                    />
                  </div>
                </div>
                
                <div className="space-y-2">
                  <Button 
                    className="w-full bg-blue-600 hover:bg-blue-700"
                    onClick={handleMobileApplyFilters}
                  >
                    Apply Filters
                  </Button>
                  <Button 
                    variant="outline"
                    className="w-full text-blue-600 border-blue-600"
                    onClick={handleMobileFiltersReset}
                  >
                    Reset Filters
                  </Button>
                </div>
              </div>
            )}
            
            {/* Main Content */}
            <div className="flex-1">
              {/* Tabs and Sort Controls */}
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-3 md:mb-4">
                <Tabs defaultValue="all" value={activeTab} className="w-full sm:w-auto mb-2 sm:mb-0" onValueChange={setActiveTab}>
                  <TabsList className="bg-white p-0.5 shadow-sm rounded-md border">
                    <TabsTrigger value="all" className="text-xs md:text-sm">
                      <IconWrapper icon={FiBookOpen} className="mr-1 text-xs md:text-base" /> All
                    </TabsTrigger>
                    <TabsTrigger value="featured" className="text-xs md:text-sm">
                      <IconWrapper icon={FiHeart} className="mr-1 text-xs md:text-base" /> Featured
                    </TabsTrigger>
                    <TabsTrigger value="trending" className="text-xs md:text-sm">
                      <IconWrapper icon={FiTrendingUp} className="mr-1 text-xs md:text-base" /> Trending
                    </TabsTrigger>
                    <TabsTrigger value="free" className="text-xs md:text-sm">
                      <IconWrapper icon={FiBookOpen} className="mr-1 text-xs md:text-base" /> Free
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
                
                <div className="flex items-center mt-2 sm:mt-0">
                  <span className="text-xs text-gray-600 mr-2 hidden sm:inline">Sort by:</span>
                  <select 
                    className="text-xs border rounded-md px-2 py-1 bg-white"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <option value="popular">Most Popular</option>
                    <option value="price-low">Price: Low to High</option>
                    <option value="price-high">Price: High to Low</option>
                    <option value="newest">Newest First</option>
                  </select>
                </div>
              </div>
              
              {/* Results Info */}
              <div className="flex justify-between items-center mb-3">
                <p className="text-xs text-gray-600">
                  Showing {filteredDocuments.length} results
                  {selectedCategory !== 'All Categories' && <span> in <Badge variant="outline" className="text-xs">{selectedCategory}</Badge></span>}
                  {searchQuery && <span> for <Badge variant="outline" className="text-xs">{searchQuery}</Badge></span>}
                </p>
                
                <Button 
                  variant="ghost" 
                  className="text-xs text-blue-600 hover:text-blue-800 p-0 h-auto hidden sm:block"
                  onClick={() => {
                    setSearchQuery('');
                    setSelectedCategory('All Categories');
                    setActiveTab('all');
                  }}
                >
                  Clear all filters
                </Button>
              </div>
              
              {/* Document Grid - Modified for note-like appearance */}
              {isLoading ? (
                <div className="flex justify-center items-center min-h-[300px]">
                  <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
                </div>
              ) : filteredDocuments.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 md:gap-4">
                  {filteredDocuments.map(document => (
                    <NoteCard key={document.id} document={document} />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center bg-white rounded-lg p-6 text-center">
                  <IconWrapper icon={FiSearch} className="text-4xl text-gray-300 mb-2" />
                  <h3 className="text-base font-medium text-gray-800 mb-1">No documents found</h3>
                  <p className="text-xs text-gray-500 mb-3">Try adjusting your search or filters</p>
                  <Button 
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setSearchQuery('');
                      setSelectedCategory('All Categories');
                      setActiveTab('all');
                    }}
                  ></Button>
                  <Button>
                    Reset all filters
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default PrintBazaarPage;
