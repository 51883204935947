import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiStar, FiUser, FiFileText, FiDownload } from 'react-icons/fi';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Button } from '../components/ui/button';
import { Card, CardContent } from '../components/ui/card';
import { Badge } from '../components/ui/badge';
import IconWrapper from '../components/print-steps/IconWrapper';
import AntiPiracyBanner from '../components/print-bazaar/AntiPiracyBanner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { fetchDocumentDetails } from '../lib/supabase/api/fetch-document-details';
import { PrintBazaarDoc } from '../types/documentTypes';
import PrintBazaarPaymentDialog from '../components/print-bazaar/PrintBazaarPaymentDialog';
import { useAuthStore } from '../store/authStore';
import { useWalletStore } from '../store/walletStore';
import { usePaymentTypeStore } from '../store/paymentTypeStore';
import { addPrintBazaarPurchase, checkPrintBazaarPurchase } from '../lib/supabase/api/print-bazaar-purchase';
import PrintBazaarPaymentService from '../services/PrintBazaarPaymentService';
import PrintSettingsDialog from '../modules/print-settings/PrintSettingsDialog';
import { usePrintNavigation } from '../hooks/usePrintNavigation';
import usePrintStore from '../store/printStore';

const PrintBazaarDetailsPage: React.FC = () => {
  const { documentId } = useParams<{ documentId: string }>();
  const navigate = useNavigate();
  const [document, setDocument] = useState<PrintBazaarDoc | null>(null);
  const [loading, setLoading] = useState(true);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [showPrintSettings, setShowPrintSettings] = useState(false);
  const { user } = useAuthStore();
  const { balance } = useWalletStore();
  const { setPaymentType } = usePaymentTypeStore();
  const { navigateToPrintFlow } = usePrintNavigation();

  useEffect(() => {
    const fetchDocument = async () => {
      if (!documentId) return;

      try {
        const doc = await fetchDocumentDetails(documentId);
        console.log('Fetched document:', doc);
        console.log('Document fields:', {
          title: doc?.title,
          description: doc?.description,
          cover_image_path: doc?.cover_image_path,
          preview_pages: doc?.preview_pages,
          cost: doc?.cost,
          tags: doc?.tags,
          page_count: doc?.page_count,
        });

        if (doc) {
          const docWithDefaultValues = {
            ...doc,
            preview_pages: Array.isArray(doc.preview_pages) ? doc.preview_pages : [],
            tags: Array.isArray(doc.tags) ? doc.tags : [],
            cost: doc.cost || 0,
            description: doc.description || 'No description available'
          };
          setDocument(docWithDefaultValues);
        } else {
          navigate('/print-bazaar');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [documentId, navigate]);

  useEffect(() => {
    const checkPurchaseStatus = async () => {
      if (!user || !documentId) return;
      try {
        const purchased = await checkPrintBazaarPurchase(user.id, documentId);
        setIsPurchased(purchased);
      } catch (error) {
        console.error('Error checking purchase status:', error);
      }
    };

    checkPurchaseStatus();
  }, [user, documentId]);

  const handlePurchaseClick = async () => {
    if (!user || !document) return;

    if (document.is_free) {
      try {
        await addPrintBazaarPurchase({
          user_id: user.id,
          doc_id: document.id,
          amount: 0,
          payment_type: 'FREE'
        });
        setIsPurchased(true);
        alert('Document added to your library');
      } catch (error) {
        console.error('Error adding free purchase:', error);
        alert('Failed to process free document');
      }
      return;
    }

    setShowPaymentDialog(true);
  };

  const handleWalletPayment = async () => {
    if (!user || !document) return;

    setPaymentType('wallet');
    
    try {
      if (!balance || balance < document.cost) {
        alert('Insufficient wallet balance');
        return;
      }

      const paymentResult = await PrintBazaarPaymentService.handleWalletPayment(
        user.id, 
        document.id, 
        document.cost,
        user.user_metadata,
        'print_bazaar_purchase' // Pass payment type here
      );

      if (paymentResult.success) {
        await addPrintBazaarPurchase({
          user_id: user.id,
          doc_id: document.id,
          amount: document.cost,
          payment_type: 'WALLET',
          order_id: paymentResult.orderId
        });
        setIsPurchased(true);
        setShowPaymentDialog(false);
      } else {
        alert('Payment failed');
      }
    } catch (error) {
      console.error('Error processing wallet payment:', error);
      alert('Payment failed');
    }
  };

  const handleRazorpayPayment = async () => {
    if (!user || !document) return;
    setPaymentType('print_bazaar_purchase');
    
    try {
      const paymentResult = await PrintBazaarPaymentService.handleRazorpayPayment(
        document.cost,
        user.id,
        document.id,
        user.user_metadata
      );

      if (paymentResult.success) {
        await addPrintBazaarPurchase({
          user_id: user.id,
          doc_id: document.id,
          amount: document.cost,
          payment_type: 'RAZORPAY',
          order_id: paymentResult.orderId
        });
        setIsPurchased(true);
      }
    } catch (error) {
      console.error('Error processing Razorpay payment:', error);
    }
    
    setShowPaymentDialog(false);
  };

  const handlePrintNow = () => {
    if (!document) return;
    setShowPrintSettings(true);
  };

  const handlePrintSettingsClose = () => {
    setShowPrintSettings(false);
    // Navigate to print flow if dialog was closed via Continue button
    if (usePrintStore.getState().currentStep === 2) {
      navigateToPrintFlow(2);
    }
  };

  const renderPurchaseSection = () => (
    isPurchased ? (
      <div>
        <p className="text-green-600 font-medium">Purchased</p>
        <button 
          className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          onClick={handlePrintNow}
        >
          Print Now
        </button>
        {document && <PrintSettingsDialog
          isOpen={showPrintSettings}
          onClose={handlePrintSettingsClose}
          documentId={document.id}
          bazaarDoc={document}  // Pass the document data
        />}
      </div>
    ) : (
      <Button 
        className="w-full bg-blue-600 hover:bg-blue-700 mb-3 sm:mb-4"
        onClick={handlePurchaseClick}
      >
        {document?.is_free ? 'Get Free' : `Purchase for ₹${document?.cost}`}
      </Button>
    )
  );

  if (loading || !document) {
    return (
      <div className="min-h-screen flex flex-col bg-gray-50">
        <Header />
        <div className="flex-grow container mx-auto px-4 py-8">
          <div className="flex justify-center items-center min-h-[300px]">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header />

      <main className="flex-grow container mx-auto px-3 sm:px-4 py-4 sm:py-6">
        <button
          onClick={() => navigate('/print-bazaar')}
          className="flex items-center text-gray-600 hover:text-blue-600 mb-4"
        >
          <IconWrapper icon={FiArrowLeft} className="mr-2" />
          Back to PrintBazaar
        </button>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8">
          <div className="lg:col-span-2">
            <Card className="overflow-hidden">
              <CardContent className="p-3 sm:p-4">
                <Swiper
                  modules={[Navigation, Pagination]}
                  navigation
                  pagination={{ clickable: true }}
                  className="w-full aspect-[16/10] mb-4 sm:mb-6 rounded-lg overflow-hidden"
                >
                  {document.cover_image_path && (
                    <SwiperSlide>
                      <img
                        src={document.cover_image_path}
                        alt="Cover"
                        className="w-full h-full object-contain bg-gray-50"
                      />
                    </SwiperSlide>
                  )}
                  {document.preview_pages?.map((url: string, index: number) => (
                    <SwiperSlide key={index}>
                      <img
                        src={url}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-contain bg-gray-50"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-3">{document.title}</h2>
                <div className="flex items-center gap-4 mb-6">
                  <div className="flex items-center text-gray-500">
                    <IconWrapper icon={FiFileText} className="mr-1" />
                    {document.page_count} pages
                  </div>
                </div>
                <div className="prose max-w-none text-sm sm:text-base">
                  <h3 className="text-base sm:text-lg font-semibold mb-2">About this document</h3>
                  <p className="text-gray-600 mb-4">{document.description}</p>

                  <Button
                    variant="outline"
                    size="sm"
                    className="mb-4"
                  >
                    Share Document
                  </Button>

                  {document.tags && document.tags.length > 0 && (
                    <div className="flex flex-wrap gap-2 mb-4">
                      {document.tags.map((tag: string, index: number) => (
                        <Badge key={index} variant="secondary" className="text-xs">
                          {tag}
                        </Badge>
                      ))}
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>

          <div className="lg:col-span-1">
            <div className="lg:sticky lg:top-4">
              <Card>
                <CardContent className="p-4 sm:p-6">
                  <div className="text-center mb-4 sm:mb-6">
                    <div className="text-2xl sm:text-3xl font-bold text-blue-600 mb-1 sm:mb-2">
                      {document.is_free ? 'Free' : `₹${document.cost}`}
                    </div>
                    <p className="text-xs sm:text-sm text-gray-500">Print-only access</p>
                  </div>

                  {renderPurchaseSection()}
                  <div className="text-xs sm:text-sm text-gray-500 text-center">
                    <p>Supports the author with 70% revenue share</p>
                  </div>

                  <div className="border-t border-gray-200 mt-4 sm:mt-6 pt-4 sm:pt-6 space-y-3">
                    <div className="flex items-center text-xs sm:text-sm text-gray-600">
                      <IconWrapper icon={FiUser} className="mr-2" />
                    </div>
                    <div className="flex items-center text-xs sm:text-sm text-gray-600">
                      <IconWrapper icon={FiDownload} className="mr-2" />
                      <span>Print-only access (no downloads)</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

        <PrintBazaarPaymentDialog
          isOpen={showPaymentDialog}
          onClose={() => setShowPaymentDialog(false)}
          onWalletPayment={handleWalletPayment}
          onRazorpayPayment={handleRazorpayPayment}
          amount={document?.cost || 0}
          walletBalance={balance}
        />
      </main>

      <Footer />
    </div>
  );
};

export default PrintBazaarDetailsPage;
