import React, { useRef, useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

interface Section {
    title: string;
    content: React.ReactNode;
}

const PrivacyPolicy: React.FC = () => {
    const [showScrollTop, setShowScrollTop] = useState<boolean>(false);
    const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        sectionRefs.current = new Array(sections.length).fill(null);
    }, []);

    const sections: Section[] = [
        {
            title: "Data Collection",
            content: (
                <div>
                    <h3>Google Sign-In</h3>
                    <p>We use Google authentication to verify your identity. This provides us with:</p>
                    <ul>
                        <li>Your name</li>
                        <li>Email address</li>
                        <li>Profile picture</li>
                    </ul>
                    <p>We use this information solely for authentication purposes and to personalize your experience within our application. We do not access any other Google services or data associated with your account.</p>

                    <h3>Transactional Data</h3>
                    <ul>
                        <li>Documents uploaded for printing (deleted immediately after printing)</li>
                        <li>Order history and print preferences</li>
                        <li>Payment receipts (handled securely by RazorPay)</li>
                    </ul>
                </div>
            )
        },
        {
            title: "How We Use Your Data",
            content: (
                <div>
                    <ul>
                        <li>To authenticate you via Google Sign-In</li>
                        <li>To send order confirmations and invoices to your email</li>
                        <li>To process and fulfill print requests</li>
                        <li>For customer support (e.g., resolving issues with orders)</li>
                    </ul>
                </div>
            )
        },
        {
            title: "Data Sharing with Third Parties",
            content: (
                <div>
                    <p>We share limited personal information with RazorPay, our payment processing service provider. This includes:</p>
                    <ul>
                        <li>Your name</li>
                        <li>Your email address</li>
                    </ul>
                    <p>This information is shared solely for the purpose of processing payments and sending you transaction-related communications (such as payment confirmation or failure notifications). RazorPay may use this information only for providing these specific services and in accordance with their own privacy policy and our agreement with them.</p>
                    <p>We do not sell, rent, or otherwise share your personal information with any other third parties unless required by law or with your explicit consent.</p>
                </div>
            )
        },
        {
            title: "Data Storage & Security",
            content: (
                <div>
                    <ul>
                        <li><b>Google Data:</b> Only stored to identify your account</li>
                        <li><b>Uploaded Files:</b> Deleted permanently after printing</li>
                        <li><b>Payment Data:</b> We never store credit card/bank details. Payments are processed by RazorPay.</li>
                    </ul>
                    <p>We implement industry-standard security measures to protect your personal information, including:</p>
                    <ul>
                        <li>Encryption of data in transit and at rest</li>
                        <li>Regular security audits</li>
                        <li>Strict access controls for our employees</li>
                    </ul>
                </div>
            )
        },
        {
            title: "Data Retention",
            content: (
                <div>
                    <p>We retain your Google account information for as long as you maintain an active account with us. Transactional data is kept for <b>1 year</b> for legal and accounting purposes.</p>
                </div>
            )
        },
        {
            title: "Your Rights and Control Over Data",
            content: (
                <div>
                    <ul>
                        <li><b>Access and Correction:</b> You have the right to access and correct your personal information. Contact us at <a href="mailto:support.ctrlp@gmail.com">support.ctrlp@gmail.com</a> for assistance.</li>
                        <li><b>Deletion:</b> Email <a href="mailto:support.ctrlp@gmail.com">support.ctrlp@gmail.com</a> to request deletion of your order history or other data.</li>
                        <li><b>Revoke Google Access:</b> Remove our app's access at any time via <a href="https://myaccount.google.com/connections/overview/ATTSlxY3-_FuYXk87Wy2mQ59dkgO4hTwbL6WklBEKVJupH2o8ykUsafndPkEfwOxRZW9DEZAh96P556SSSNL_1BIn3A?hl=en-GB">Google Account Settings</a>.</li>
                        <li><b>Withdraw Consent:</b> You can withdraw your consent for us to use your Google account information at any time by disconnecting our application from your Google account and contacting us to delete your account.</li>
                    </ul>
                </div>
            )
        },
        {
            title: "Use of Google API Services",
            content: (
                <div>
                    <p>We use Google Sign-In API to authenticate users. Our use of information received from Google APIs adheres to the <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
                    <ul>
                        <li>We only request access to the data necessary for authentication (name, email, profile picture).</li>
                        <li>We do not transfer, sell, or use this data for advertising purposes.</li>
                        <li>We do not allow humans to read this data unless we have your affirmative agreement, it is necessary for security purposes, or it is required to comply with applicable laws.</li>
                    </ul>
                </div>
            )
        },
        {
            title: "Data Breach Notification",
            content: (
                <div>
                    <p>In the event of a data breach that affects your personal information, we will notify you via email within <b>72 hours</b> of discovering the breach. We will provide information about the nature of the breach and steps we are taking to address it.</p>
                </div>
            )
        },
        {
            title: "Children's Privacy",
            content: (
                <div>
                    <p>Our service is not intended for use by children under the age of <b>13</b>. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us at <a href="mailto:support.ctrlp@gmail.com">support.ctrlp@gmail.com</a>.</p>
                </div>
            )
        },
        {
            title: "Updates to This Policy",
            content: (
                <div>
                    <p>We review this privacy policy regularly and may update it as necessary to reflect changes in our practices or legal requirements. The date at the top of this policy indicates when it was last updated.</p>
                </div>
            )
        },
        {
            title: "Compliance with Data Protection Laws",
            content: (
                <div>
                    <p>We comply with applicable <b>Indian data protection laws</b>. Our commitments include:</p>
                    <ul>
                        <li>Protecting the privacy of Indian citizens’ personal data</li>
                        <li>Ensuring transparency in data collection and processing</li>
                        <li>Implementing security measures to safeguard personal information</li>
                    </ul>
                </div>
            )
        },
        {
            title: "Contact Us",
            content: (
                <div>
                    <p><b>CTRLP</b></p>
                    <p>35 Padmavati Colony, Behind St. Paul School, Indore, M.P. (Pin: 452001)</p>
                    <p>Email: <a href="mailto:dev.ctrlp@gmail.com">dev.ctrlp@gmail.com</a>, <a href="mailto:support.ctrlp@gmail.com">support.ctrlp@gmail.com</a></p>
                    <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us at <a href="mailto:support.ctrlp@gmail.com">support.ctrlp@gmail.com</a>.</p>
                </div>
            )
        }
    ];

    const scrollToSection = (index: number): void => {
        const element = sectionRefs.current[index];
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToTop = (): void => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = (): void => {
            setShowScrollTop(window.scrollY > 200);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="min-h-screen flex flex-col bg-gray-50">
            <Header />
            <div className="min-h-screen bg-white">
                <div className="flex flex-col md:flex-row max-w-7xl mx-auto px-4 py-8">
                    {/* Main Content */}
                    <div className="w-full md:w-3/4 pr-0 md:pr-8">
                        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
                        <p className="text-gray-600 mb-8">Last updated: <i>30 January 2025</i></p>

                        {sections.map((section, index) => (
                            <div
                                key={index}
                                ref={element => { sectionRefs.current[index] = element; }}
                                className="mb-8"
                            >
                                <h2 className="text-2xl font-semibold mb-4">
                                    {index + 1}. {section.title}
                                </h2>
                                <div className="prose max-w-none">{section.content}</div>
                            </div>
                        ))}
                    </div>

                    {/* Table of Contents Sidebar */}
                    <div className="w-full md:w-1/4 mt-8 md:mt-0">
                        <div className="sticky top-4">
                            <h3 className="text-xl font-semibold mb-4">Table of Contents</h3>
                            <nav className="space-y-2">
                                {sections.map((section, index) => (
                                    <button
                                        key={index}
                                        onClick={() => scrollToSection(index)}
                                        className="block text-left text-sm text-gray-600 hover:text-gray-900 w-full px-2 py-1 rounded hover:bg-gray-100"
                                    >
                                        {index + 1}. {section.title}
                                    </button>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>

                {showScrollTop && (
                    <button
                        onClick={scrollToTop}
                        className="fixed bottom-8 right-8 bg-gray-800 text-white w-10 h-10 rounded-full flex items-center justify-center shadow-lg hover:bg-gray-700 transition-colors"
                        aria-label="Scroll to top"
                    >
                        ↑
                    </button>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
