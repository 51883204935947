import { storeFile, retrieveFile, deleteFile } from './indexedDBUtils';

export const FILE_STORAGE_PREFIX = 'ctrlp-file-';

// File storage map to keep track of uploaded files (in-memory cache)
export const documentFilesMap: Record<string, File> = {};

// Helper to convert File to base64 for storage
export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

// Helper to convert base64 to File
export const base64toFile = (base64: string, filename: string, type: string): File => {
  const arr = base64.split(',');
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  
  while(n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, { type });
};

// Save file to IndexedDB
export const saveFileToStorage = async (documentId: string, file: File): Promise<void> => {
  // Keep in memory for quick access
  documentFilesMap[documentId] = file;
  
  // Store in IndexedDB
  const key = `${FILE_STORAGE_PREFIX}${documentId}`;
  return storeFile(key, file);
};

// Get file from memory cache or IndexedDB
export const getDocumentFile = async (id: string): Promise<File | null> => {
  // First check in-memory cache
  if (documentFilesMap[id]) {
    return documentFilesMap[id];
  }
  
  // If not in memory, get from IndexedDB
  const key = `${FILE_STORAGE_PREFIX}${id}`;
  const file = await retrieveFile(key);
  
  // If found, cache it in memory
  if (file) {
    documentFilesMap[id] = file;
  }
  
  return file;
};

// Remove file from storage
export const removeFileFromStorage = async (documentId: string): Promise<void> => {
  // Remove from memory
  if (documentFilesMap[documentId]) {
    delete documentFilesMap[documentId];
  }
  
  // Remove from IndexedDB
  const key = `${FILE_STORAGE_PREFIX}${documentId}`;
  return deleteFile(key);
};
