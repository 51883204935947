import React, { useEffect, useState } from 'react';
import { useAuthStore } from '../store/authStore';
import { useWalletStore } from '../store/walletStore';
import { useSearchParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Card, CardContent } from '../components/ui/card';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MyOrders from '../components/account/MyOrders';
import MyPurchases from '../components/account/MyPurchases';
import MyWallet from '../components/account/MyWallet';
import MyUploads from '../components/account/MyUploads';
import Support from '../components/account/Support';

const MyAccountPage: React.FC = () => {
  const { user, loading, checkSession } = useAuthStore();
  const { hasLoaded } = useWalletStore(); // We don't need fetchWalletBalance here, Header takes care of it
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [searchParams] = useSearchParams();
  
  // Get tab from URL or use 'orders' as default
  const defaultTab = searchParams.get('tab') || 'orders';
  
  // Ensure we have user data
  useEffect(() => {
    const init = async () => {
      if (!user) {
        await checkSession();
      }
      // Give time for components to render
      setTimeout(() => setIsPageLoading(false), 500);
    };
    
    init();
  }, [user, checkSession]);
  
  // Show loading state while user data is being fetched
  if (isPageLoading || loading) {
    return (
      <div className="min-h-screen flex flex-col bg-white">
        <Header />
        <main className="flex-grow container mx-auto py-8 px-4 flex justify-center items-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
            <p className="text-gray-600">Loading your account information...</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
  
  // Show warning if not authenticated
  if (!user) {
    return (
      <div className="min-h-screen flex flex-col bg-white">
        <Header />
        <main className="flex-grow container mx-auto py-8 px-4">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded">
            <div className="flex">
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  You need to sign in to access your account.
                </p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
  
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Header />
      
      <main className="flex-grow container mx-auto py-6 px-4 sm:py-8">
        {/* Profile Header */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-4 sm:p-6 mb-6 sm:mb-8">
          <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-6">
            <div className="w-20 h-20 sm:w-24 sm:h-24 rounded-full overflow-hidden border-2 border-blue-500">
              <img 
                src={user?.user_metadata?.avatar_url || '/default-avatar.png'} 
                alt={user?.user_metadata?.full_name || 'User'}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-center sm:text-left">
              <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800">
                {user?.user_metadata?.full_name || 'User'}
              </h1>
              <p className="text-gray-500 mt-1 text-sm sm:text-base">{user?.email}</p>
            </div>
          </div>
        </div>

        {/* Account Sections */}
        <Card className="bg-white shadow-sm border border-gray-100">
          <Tabs defaultValue={defaultTab} className="w-full">
            <div className="overflow-x-auto px-1">
              <TabsList className="w-full bg-gray-50 p-1 rounded-t-lg flex min-w-max">
                <TabsTrigger 
                  value="orders" 
                  className="flex-1 min-w-[100px] whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-blue-600 text-xs sm:text-sm"
                >
                  My Orders
                </TabsTrigger>
                <TabsTrigger 
                  value="purchases" 
                  className="flex-1 min-w-[100px] whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-blue-600 text-xs sm:text-sm"
                >
                  My Purchases
                </TabsTrigger>
                <TabsTrigger 
                  value="uploads" 
                  className="flex-1 min-w-[100px] whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-blue-600 text-xs sm:text-sm"
                >
                  My Uploads
                </TabsTrigger>
                <TabsTrigger 
                  value="wallet" 
                  className="flex-1 min-w-[100px] whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-blue-600 text-xs sm:text-sm"
                >
                  My Wallet
                </TabsTrigger>
                <TabsTrigger 
                  value="support" 
                  className="flex-1 min-w-[100px] whitespace-nowrap data-[state=active]:bg-white data-[state=active]:text-blue-600 text-xs sm:text-sm"
                >
                  Support
                </TabsTrigger>
              </TabsList>
            </div>
            <CardContent className="p-4 sm:p-6">
              <TabsContent value="orders" className="mt-2 sm:mt-4">
                <MyOrders />
              </TabsContent>
              <TabsContent value="purchases" className="mt-2 sm:mt-4">
                <MyPurchases />
              </TabsContent>
              <TabsContent value="uploads" className="mt-2 sm:mt-4">
                <MyUploads />
              </TabsContent>
              <TabsContent value="wallet" className="mt-2 sm:mt-4">
                <MyWallet />
              </TabsContent>
              <TabsContent value="support" className="mt-2 sm:mt-4">
                <Support />
              </TabsContent>
            </CardContent>
          </Tabs>
        </Card>
      </main>
      
      <Footer />
    </div>
  );
};

export default MyAccountPage;
