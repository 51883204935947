import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import usePrintStore from '../store/printStore';
import StepIndicator from '../components/print-steps/StepIndicator';
import UploadDocumentCard from '../components/print-steps/UploadDocumentCard';
import PrintSettingsCard from '../components/print-steps/PrintSettingsCard';
import ShopSelectionCard from '../components/print-steps/ShopSelectionCard';
import OrderSummaryCard from '../components/print-steps/OrderSummaryCard';
import { useAuthStore } from '../store/authStore';
import { FiMapPin, FiCheckCircle } from 'react-icons/fi';
import IconWrapper from '../components/print-steps/IconWrapper';

import '../styles/transitions.css';

interface GetPrintProps {
  directShopId?: string | null;
}

const GetPrint: React.FC<GetPrintProps> = ({ directShopId }) => {
  const { currentStep, setCurrentStep, documents, restoreDocuments, selectedShop } = usePrintStore();
  const { user, previousPrintStep, clearPreviousPrintStep } = useAuthStore();
  const [prevStep, setPrevStep] = useState(currentStep);
  const [transitioning, setTransitioning] = useState(false);
  const [direction, setDirection] = useState<'forward' | 'backward'>('forward');
  const [isRestoring, setIsRestoring] = useState(true);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [locationResponse, setLocationResponse] = useState<boolean | null>(null);

  // Initialize or restore state on component mount
  useEffect(() => {
    const initializeStore = async () => {
      setIsRestoring(true);
      try {
        await restoreDocuments();
      } catch (error) {
        console.error("Failed to restore documents:", error);
      } finally {
        setIsRestoring(false);
      }
    };
    
    initializeStore();
  }, [restoreDocuments]);

  // Track direction changes
  useEffect(() => {
    if (currentStep !== prevStep) {
      const isForward = currentStep > prevStep;
      setDirection(isForward ? 'forward' : 'backward');
      setTransitioning(true);
      
      const timer = setTimeout(() => {
        setTransitioning(false);
        setPrevStep(currentStep);
      }, 300); // Match this with your CSS transition duration
      
      return () => clearTimeout(timer);
    }
  }, [currentStep, prevStep]);

  // Check if we need to restore the previous step after login
  useEffect(() => {
    if (user && previousPrintStep !== null) {
      // Restore the step user was trying to access before authentication
      setCurrentStep(previousPrintStep);
      clearPreviousPrintStep();
    }
  }, [user, previousPrintStep, setCurrentStep, clearPreviousPrintStep]);

  // Prevent unauthorized access to step 3+
  useEffect(() => {
    if (currentStep >= 3 && !user) {
      // If user tries to access step 3 or higher without being logged in,
      // redirect them back to step 2
      setCurrentStep(2);
    }
  }, [currentStep, user, setCurrentStep]);

  // This will be triggered when the child component asks for location permission
  useEffect(() => {
    if (locationResponse !== null) {
      // The modal has been responded to
      setShowLocationModal(false);
    }
  }, [locationResponse]);

  // Handle location modal request from ShopSelectionCard
  const handleShowLocationModal = (show: boolean) => {
    console.log('Parent: Location modal request received:', show);
    setShowLocationModal(show);
  };

  // Handle the response from the location modal
  const handleLocationModalResponse = (allow: boolean) => {
    console.log('Parent: Location response:', allow);
    setLocationResponse(allow);
  };

  // Reset location response when modal closes
  useEffect(() => {
    if (!showLocationModal) {
      // Small delay to ensure child component receives the previous value
      const timer = setTimeout(() => {
        if (locationResponse !== null) {
          console.log('Parent: Resetting location response');
        }
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [showLocationModal]);

  // Determine which component to render
  const getCurrentStep = () => {
    // Show loading spinner while restoring documents
    if (isRestoring) {
      return (
        <div className="flex flex-col items-center justify-center py-16">
          <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
          <p className="mt-4 text-gray-600">Restoring your documents...</p>
        </div>
      );
    }
    
    switch(currentStep) {
      case 0: return <UploadDocumentCard />;
      case 1: return <PrintSettingsCard />;
      case 2: 
        // If directShopId is provided, skip shop selection
        return directShopId ? (
          <div className="py-4">
            <OrderSummaryCard />
            <div className="mt-4 p-3 bg-green-50 border border-green-200 rounded-lg flex items-center">
              <IconWrapper icon={FiCheckCircle} className="text-green-600 mr-2" />
              <div>
                <p className="text-green-800">Shop pre-selected from QR code</p>
                <p className="text-xs text-green-600">Shop ID: {directShopId}</p>
              </div>
            </div>
          </div>
        ) : (
          <ShopSelectionCard 
            showLocationModal={handleShowLocationModal} 
            locationResponse={locationResponse}
          />
        );
      case 3: return user ? <OrderSummaryCard /> : (
        <ShopSelectionCard 
          showLocationModal={handleShowLocationModal}
          locationResponse={locationResponse}
        />
      );
      default: return <UploadDocumentCard />;
    }
  };

  // Get the animation class based on direction and transition state
  const getAnimationClass = () => {
    if (!transitioning || isRestoring) return '';
    
    if (direction === 'forward') {
      return 'slide-from-right';
    } else {
      return 'slide-from-left';
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-grow container mx-auto py-8 px-4">
        <div className="bg-white rounded-lg shadow-md p-6">
          {directShopId && (
            <div className="mb-4 p-2 bg-blue-50 border border-blue-200 rounded-lg">
              <p className="text-sm text-blue-800">Direct shop selected: <span className="font-mono">{directShopId}</span></p>
            </div>
          )}
          
          <StepIndicator directShopId={directShopId} />
          
          <div className="relative overflow-hidden">
            <div className={`step-container ${getAnimationClass()}`}>
              {getCurrentStep()}
            </div>
          </div>
        </div>
      </main>
      
      <Footer />

      {/* Location Permission Modal */}
      {showLocationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
            <h3 className="text-xl font-semibold mb-3 text-gray-800">Find Nearby Print Shops</h3>
            <div className="mb-4 flex items-center text-blue-600">
              <IconWrapper icon={FiMapPin} className="mr-2" />
              <span>Location Access</span>
            </div>
            <p className="text-gray-600 mb-4">
              Would you like to share your location to find the nearest print shops? This helps us suggest convenient options nearby.
            </p>
            <div className="flex justify-end space-x-3">
              <button 
                onClick={() => handleLocationModalResponse(false)}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100"
              >
                No, Thanks
              </button>
              <button 
                onClick={() => handleLocationModalResponse(true)}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Allow Location
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetPrint;
