import { PDFDocument } from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';
import { PrintSettings } from '../types/documentTypes';
import useDocumentGroupsStore from '../store/documentGroupsStore';

// Type for our document groups
interface DocumentWithFile {
  id: string;
  name: string;
  file: File;
}

interface DocumentGroup {
  documentIds: string[];
  settings: PrintSettings;
}

interface MergeInfo {
  docNames: string[];
  settings: string;
}

interface MergeResult {
  mergedFiles: File[];
  mergeInfo: MergeInfo[];
}

// Generate a settings key to group documents by identical print settings
export const generateSettingsKey = (settings: PrintSettings): string => {
  // Create a more comprehensive key to ensure proper grouping
  return [
    settings.color ? 'color' : 'bw',
    settings.doubleSided ? 'duplex' : 'simplex',
    settings.paperType || 'default',
    settings.orientation || 'portrait',
    settings.copies || '1'
  ].join('_');
};

// Create a readable settings description for user notification
export const getSettingsDescription = (settings: PrintSettings): string => {
  const parts = [];
  
  parts.push(settings.color ? 'Color' : 'Black & White');
  parts.push(settings.doubleSided ? 'Double-sided' : 'Single-sided');
  
  if (settings.paperType) {
    parts.push(`Paper: ${settings.paperType}`);
  }
  
  if (settings.orientation) {
    parts.push(settings.orientation.charAt(0).toUpperCase() + settings.orientation.slice(1));
  }
  
  return parts.join(', ');
};

// A helper function to generate a settings signature for comparison
const generateSettingsSignature = (settings: PrintSettings): string => {
  return `${settings.color ? 1 : 0}-${settings.doubleSided ? 1 : 0}-${settings.orientation}-${settings.paperType}`;
};

// Group documents with identical print settings
export const groupDocumentsBySettings = (
  documents: DocumentWithFile[],
  printSettings: Record<string, PrintSettings>
): DocumentGroup[] => {
  const groups: DocumentGroup[] = [];
  const documentsCopy = [...documents];

  // Process all documents and group them by settings
  while (documentsCopy.length > 0) {
    const document = documentsCopy.shift();
    if (!document) continue;

    const docId = document.id;
    const settings = printSettings[docId];
    
    // Skip if no settings found
    if (!settings) continue;

    if(settings.copies > 1) {
      groups.push({
        documentIds: [docId],
        settings
      });
      continue;
    }

    // Check if similar settings group exists
    let foundGroup = false;
    for (const group of groups) {
      const groupSettings = printSettings[group.documentIds[0]];
      
      if (areSettingsEqual(settings, groupSettings)) {

        group.documentIds.push(docId);
        foundGroup = true;
        break;
      }
    }

    // Create new group if no existing group found
    if (!foundGroup) {
      groups.push({
        documentIds: [docId],
        settings
      });
    }
  }

  return groups;
};

// Helper function to compare settings
const areSettingsEqual = (settings1: PrintSettings, settings2: PrintSettings): boolean => {
  if (!settings1 || !settings2) return false;
  
  return (
    settings1.color === settings2.color &&
    settings1.doubleSided === settings2.doubleSided &&
    settings1.orientation === settings2.orientation &&
    settings1.paperType === settings2.paperType&&
    settings1.copies === settings2.copies
  );
};

// Function to create settings summary string
const createSettingsSummary = (settings: PrintSettings): string => {
  const colorMode = settings.color ? 'Color' : 'B&W';
  const duplex = settings.doubleSided ? 'Double-sided' : 'Single-sided';
  return `${colorMode}, ${duplex}, ${settings.paperType}, ${settings.orientation}`;
};

// Generate a filename for the merged document
export const generateMergedFilename = (group: DocumentGroup): string => {
  // Use up to first 3 document names in the filename
  const documentPart = group.documentIds
    .slice(0, 3)
    .map(doc => doc.replace(/\.[^/.]+$/, "")) // Remove extension
    .join('_');
  
  // Add settings code at the end
  return `${documentPart}_${generateSettingsKey(group.settings)}.pdf`;
};

// Merge PDFs with the same print settings
export const mergePDFs = async (files: File[]): Promise<File> => {
  if (files.length === 0) {
    throw new Error("No files to merge");
  }
  
  if (files.length === 1) {
    return files[0]; // No need to merge if there's only one file
  }
  
  console.log(`Merging ${files.length} PDFs into one document`);
  
  try {
    // Create a new PDF document
    const mergedPdf = await PDFDocument.create();
    
    // Process each file
    for (const file of files) {
      // Convert File to ArrayBuffer
      const buffer = await file.arrayBuffer();
      
      // Load the PDF
      const pdf = await PDFDocument.load(buffer, { ignoreEncryption: true });
      
      // Copy all pages to the merged PDF
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach(page => {
        mergedPdf.addPage(page);
      });
      
      console.log(`Added ${pdf.getPageCount()} pages from "${file.name}"`);
    }
    
    // Save the merged PDF
    const mergedPdfBytes = await mergedPdf.save();
    
    // Convert to Blob and then to File
    const mergedFile = new File(
      [mergedPdfBytes], 
      `merged_${Date.now()}.pdf`,
      { type: 'application/pdf' }
    );
    
    console.log(`Successfully merged PDFs into a new document with ${mergedPdf.getPageCount()} pages`);
    return mergedFile;
    
  } catch (error) {
    console.error("Error merging PDFs:", error);
    throw new Error(`Failed to merge PDFs: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

// Process each group - merge documents with identical settings
export const processMergeGroups = async (
  groups: DocumentGroup[]
): Promise<MergeResult> => {
  const mergedFiles: File[] = [];
  const mergeInfo: MergeInfo[] = [];

  // Get document groups from our store to access files
  const documentGroups = useDocumentGroupsStore.getState().documentGroups;

  // Process each group
  for (const group of groups) {
    const { documentIds, settings } = group;
    
    // Only merge if there's more than one document
    if (documentIds.length <= 1) {
      // For single-document groups, just add the original file
      const docId = documentIds[0];
      
      // Find the document group containing this specific document
      for (const docGroup of documentGroups) {
        const specificDoc = docGroup.documents.find(d => d.id === docId);
        if (specificDoc && specificDoc.file) {
          mergedFiles.push(specificDoc.file);
          break;
        }
      }
      continue;
    }
    
    // Get all files for this group
    const files: File[] = [];
    const docNames: string[] = [];
    
    for (const docId of documentIds) {
      // Look through all document groups to find the specific document with this ID
      let foundDoc = false;
      for (const docGroup of documentGroups) {
        const specificDoc = docGroup.documents.find(d => d.id === docId);
        if (specificDoc && specificDoc.file) {
          files.push(specificDoc.file);
          docNames.push(specificDoc.name);
          foundDoc = true;
          break;
        }
      }
      
      if (!foundDoc) {
        console.warn(`Could not find document with ID: ${docId}`);
      }
    }
    
    // Skip if no files to merge
    if (files.length === 0) continue;
    
    console.log(`Merging ${files.length} files for group with ${docNames.join(', ')}`);
    
    try {
      // Create a new merged PDF
      const mergedPdf = await PDFDocument.create();
      
      // Add each document to the merged PDF
      for (const file of files) {
        const fileBytes = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(fileBytes, { ignoreEncryption: true });
        const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
        copiedPages.forEach(page => mergedPdf.addPage(page));
      }
      
      // Save the merged document
      const mergedBytes = await mergedPdf.save();
      const mergedBlob = new Blob([mergedBytes], { type: 'application/pdf' });
      
      // Create a File object from the blob
      const mergedFileName = `merged_${docNames.length}_docs_${uuidv4()}.pdf`;
      const mergedFile = new File([mergedBlob], mergedFileName, { type: 'application/pdf' });
      
      // Add to our results
      mergedFiles.push(mergedFile);
      
      // Add merge info for notification
      mergeInfo.push({
        docNames,
        settings: createSettingsSummary(settings)
      });
    } catch (error) {
      console.error('Error merging PDFs:', error);
      // In case of error, fall back to just the first file
      if (files.length > 0) {
        mergedFiles.push(files[0]);
      }
    }
  }

  console.log(`processMergeGroups: Processed ${groups.length} groups, created ${mergedFiles.length} merged files`);
  return { mergedFiles, mergeInfo };
};