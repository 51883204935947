import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import { Input } from '../components/ui/input';
import { Textarea } from '../components/ui/textarea';
import { Button } from '../components/ui/button';
import { useNavigate } from 'react-router-dom';

const AdvertisingPage: React.FC = () => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    businessName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    setFormSubmitted(true);
    
    // Reset form after submission
    setTimeout(() => {
      setFormData({
        name: '',
        businessName: '',
        email: '',
        phone: '',
        message: '',
      });
      setFormSubmitted(false);
    }, 5000);
  };

  const adBenefits = [
    {
      icon: "target",
      title: "Targeted Audience",
      description: "Reach students exactly when they're engaged with your content.",
    },
    {
      icon: "eye",
      title: "High Visibility",
      description: "Every printed document includes your ad on its coverpage.",
    },
    {
      icon: "map",
      title: "Location-Based",
      description: "Target specific campuses or academic institutions.",
    },
    {
      icon: "trending-up",
      title: "Performance Metrics",
      description: "Get detailed analytics on impressions and engagement.",
    },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-grow">
        {/* Hero Section */}
        <section className="bg-gradient-to-b from-blue-50 to-white py-16 px-4">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
              <div className="lg:w-1/2">
                <h1 className="text-4xl md:text-5xl font-bold mb-6">
                  <span className="text-blue-600">Advertise</span> Where Students Look
                </h1>
                <p className="text-lg text-gray-700 mb-8">
                  Place your brand on document coverpages seen by thousands of students daily. 
                  Premium ad space that gets noticed at the moment of highest engagement.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Button 
                    className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-6 rounded-lg text-lg"
                    onClick={() => {
                      // Scroll to contact form
                      document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Start Advertising
                  </Button>
                  <Button 
                    variant="outline" 
                    className="border-blue-600 text-blue-600 hover:bg-blue-50 px-8 py-6 rounded-lg text-lg"
                    onClick={() => {
                      // Scroll to learn more section
                      document.getElementById('how-it-works')?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Learn More
                  </Button>
                </div>
              </div>
              <div className="lg:w-1/2">
                <div className="relative">
                  {/* Removed the blue gradient spot from the top right */}
                  {/* Removed the pink/purple gradient spot from the bottom left */}
                  <div className="relative rounded-xl overflow-hidden border-0">
                    <img 
                      src="/ad1.png" 
                      alt="Example of a coverpage advertisement" 
                      className="w-full h-auto mix-blend-multiply"
                      style={{ backgroundColor: 'transparent' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-16 px-4 bg-white">
          <div className="container mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-2">Why Advertise on <span className="text-blue-600">Coverpages</span>?</h2>
              <p className="text-gray-600">Targeted, visible, and effective advertising where it matters most.</p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {adBenefits.map((benefit, index) => (
                <Card key={index} className="transition-all hover:shadow-lg hover:-translate-y-1">
                  <CardHeader>
                    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                      <i className="text-blue-600 text-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={`lucide lucide-${benefit.icon}`}>
                          {benefit.icon === "target" && (
                            <>
                              <circle cx="12" cy="12" r="10"/>
                              <circle cx="12" cy="12" r="6"/>
                              <circle cx="12" cy="12" r="2"/>
                            </>
                          )}
                          {benefit.icon === "eye" && (
                            <>
                              <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/>
                              <circle cx="12" cy="12" r="3"/>
                            </>
                          )}
                          {benefit.icon === "map" && (
                            <>
                              <polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"/>
                              <line x1="9" x2="9" y1="3" y2="18"/>
                              <line x1="15" x2="15" y1="6" y2="21"/>
                            </>
                          )}
                          {benefit.icon === "trending-up" && (
                            <>
                              <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"/>
                              <polyline points="16 7 22 7 22 13"/>
                            </>
                          )}
                        </svg>
                      </i>
                    </div>
                    <CardTitle>{benefit.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <CardDescription>{benefit.description}</CardDescription>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section id="how-it-works" className="py-16 px-4 bg-gray-50">
          <div className="container mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-2">How It <span className="text-blue-600">Works</span></h2>
              <p className="text-gray-600">Simple steps to get your brand in front of students</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
              <div className="text-center">
                <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mx-auto mb-4">
                  <span className="text-blue-600 text-2xl font-bold">1</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">Choose Your Target</h3>
                <p className="text-gray-600">Select specific colleges, departments, or student demographics to reach.</p>
              </div>
              
              <div className="text-center">
                <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mx-auto mb-4">
                  <span className="text-blue-600 text-2xl font-bold">2</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">Design Your Ad</h3>
                <p className="text-gray-600">Upload your design or work with our team to create an effective ad.</p>
              </div>
              
              <div className="text-center">
                <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mx-auto mb-4">
                  <span className="text-blue-600 text-2xl font-bold">3</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">Launch & Monitor</h3>
                <p className="text-gray-600">Your ad appears on coverpages with real-time performance analytics.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Statistics Section */}
        <section className="py-16 px-4 bg-blue-600 text-white">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
              <div className="text-center">
                <div className="text-4xl font-bold mb-2">10,000+</div>
                <p className="text-blue-100">Documents Printed Monthly</p>
              </div>
              
              <div className="text-center">
                <div className="text-4xl font-bold mb-2">85%</div>
                <p className="text-blue-100">Student Recognition Rate</p>
              </div>
              
              <div className="text-center">
                <div className="text-4xl font-bold mb-2">25+</div>
                <p className="text-blue-100">Campus Locations</p>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="py-16 px-4 bg-white">
          <div className="container mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-2">What <span className="text-blue-600">Advertisers</span> Say</h2>
              <p className="text-gray-600">Success stories from businesses who've worked with us</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              <Card className="shadow-lg border-0">
                <CardContent className="pt-6">
                  <p className="text-gray-600 mb-4">
                    "We saw a 30% increase in student sign-ups after just one month of advertising on CTRL+P coverpages. The targeting capabilities are unmatched."
                  </p>
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                      <span className="text-blue-600 font-bold">CE</span>
                    </div>
                    <div className="ml-3">
                      <p className="font-medium">Chris Evans</p>
                      <p className="text-sm text-gray-500">Campus Eats</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
              
              <Card className="shadow-lg border-0">
                <CardContent className="pt-6">
                  <p className="text-gray-600 mb-4">
                    "The coverpage ads gave us direct access to engineering students, which was exactly our target market. Our campus recruiting numbers doubled this semester."
                  </p>
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                      <span className="text-blue-600 font-bold">SP</span>
                    </div>
                    <div className="ml-3">
                      <p className="font-medium">Sarah Parker</p>
                      <p className="text-sm text-gray-500">TechStart Solutions</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* Contact Form */}
        <section id="contact-form" className="py-16 px-4 bg-gray-50">
          <div className="container mx-auto">
            <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="md:flex">
                <div className="md:w-1/2 bg-blue-600 text-white p-8">
                  <h3 className="text-2xl font-bold mb-4">Get in Touch</h3>
                  <p className="mb-6">Ready to reach thousands of students with your brand? Fill out the form and our team will contact you within 24 hours.</p>
                  
                  <div className="mb-6">
                    <h4 className="text-xl font-semibold mb-2">Our Contact Info</h4>
                    <div className="flex items-center mb-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                      <span>(+91) 9044400879</span>
                    </div>
                    <div className="flex items-center mb-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                      <span>advertising@ctrlp.com</span>
                    </div>
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2">
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                        <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                      <span>IIIT Nagpur Campus</span>
                    </div>
                  </div>
                </div>
                
                <div className="md:w-1/2 p-8">
                  <h3 className="text-2xl font-bold mb-4">Start Advertising</h3>
                  {formSubmitted ? (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                      <strong className="font-bold">Thank you!</strong>
                      <span className="block sm:inline"> We've received your request and will be in touch shortly.</span>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2">Your Name</label>
                        <Input
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          className="w-full"
                          placeholder="John Doe"
                          required
                        />
                      </div>
                      
                      <div className="mb-4">
                        <label htmlFor="businessName" className="block text-gray-700 text-sm font-medium mb-2">Business Name</label>
                        <Input
                          id="businessName"
                          name="businessName"
                          value={formData.businessName}
                          onChange={handleInputChange}
                          className="w-full"
                          placeholder="Your Company"
                          required
                        />
                      </div>
                      
                      <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">Email</label>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="w-full"
                          placeholder="you@example.com"
                          required
                        />
                      </div>
                      
                      <div className="mb-4">
                        <label htmlFor="phone" className="block text-gray-700 text-sm font-medium mb-2">Phone Number</label>
                        <Input
                          id="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          className="w-full"
                          placeholder="(123) 456-7890"
                        />
                      </div>
                      
                      <div className="mb-6">
                        <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-2">Message</label>
                        <Textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          className="w-full"
                          placeholder="Tell us about your advertising needs..."
                          rows={3}
                        />
                      </div>
                      
                      <Button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 rounded-lg"
                      >
                        Submit Request
                      </Button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      
      <Footer />
    </div>
  );
};

export default AdvertisingPage;
