const DB_NAME = 'CtrlpFileStorage';
const DB_VERSION = 1;
const FILE_STORE = 'files';

// Initialize the database
export const initDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    
    request.onerror = () => {
      reject('Error opening IndexedDB');
    };
    
    request.onsuccess = () => {
      resolve(request.result);
    };
    
    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains(FILE_STORE)) {
        db.createObjectStore(FILE_STORE, { keyPath: 'id' });
      }
    };
  });
};

// Store file in IndexedDB
export const storeFile = async (id: string, file: File): Promise<void> => {
  try {
    // Convert file to ArrayBuffer for efficient storage
    const arrayBuffer = await file.arrayBuffer();
    
    const db = await initDB();
    const transaction = db.transaction([FILE_STORE], 'readwrite');
    const store = transaction.objectStore(FILE_STORE);
    
    const fileData = {
      id,
      buffer: arrayBuffer,
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: file.lastModified
    };
    
    return new Promise((resolve, reject) => {
      const request = store.put(fileData);
      request.onsuccess = () => resolve();
      request.onerror = () => reject(new Error('Failed to save file to IndexedDB'));
      
      // Close DB connection when transaction completes
      transaction.oncomplete = () => db.close();
    });
  } catch (error) {
    console.error('Error saving file to IndexedDB:', error);
    throw error;
  }
};

// Retrieve file from IndexedDB
export const retrieveFile = async (id: string): Promise<File | null> => {
  try {
    const db = await initDB();
    const transaction = db.transaction([FILE_STORE], 'readonly');
    const store = transaction.objectStore(FILE_STORE);
    
    return new Promise((resolve, reject) => {
      const request = store.get(id);
      
      request.onsuccess = () => {
        if (!request.result) {
          resolve(null);
          return;
        }
        
        const { buffer, name, type, lastModified } = request.result;
        const file = new File([buffer], name, { type, lastModified });
        resolve(file);
      };
      
      request.onerror = () => reject(new Error('Failed to retrieve file from IndexedDB'));
      
      // Close DB connection when transaction completes
      transaction.oncomplete = () => db.close();
    });
  } catch (error) {
    console.error('Error retrieving file from IndexedDB:', error);
    return null;
  }
};

// Delete file from IndexedDB
export const deleteFile = async (id: string): Promise<void> => {
  try {
    const db = await initDB();
    const transaction = db.transaction([FILE_STORE], 'readwrite');
    const store = transaction.objectStore(FILE_STORE);
    
    return new Promise((resolve, reject) => {
      const request = store.delete(id);
      request.onsuccess = () => resolve();
      request.onerror = () => reject(new Error('Failed to delete file from IndexedDB'));
      
      // Close DB connection when transaction completes
      transaction.oncomplete = () => db.close();
    });
  } catch (error) {
    console.error('Error deleting file from IndexedDB:', error);
    throw error;
  }
};
