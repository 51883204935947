import React from 'react';
import { calculateBWPrice, calculateColorPrice } from '../../utils/priceCalculations';
import { calculateEffectivePageCount } from '../../utils/pageUtils';
import { Document, PrintSettings } from '../../types/documentTypes';

interface Props {
  doc: Document;
  settings: PrintSettings;
}

const DocumentListItem: React.FC<Props> = ({ doc, settings }) => {
  const getPageCount = () => {
    if (settings.effectivePageCount) {
      return settings.effectivePageCount;
    }
    if (settings.pageSelection && doc.pageCount) {
      return calculateEffectivePageCount(settings.pageSelection, doc.pageCount);
    }
    return 1;
  };

  const calculatePrice = () => {
    const pageCount = getPageCount();
    const pricePerPage = settings.color 
      ? calculateColorPrice(pageCount)
      : calculateBWPrice(pageCount);
    const copies = settings.copies || 1;
    return pricePerPage * pageCount * copies;
  };

  return (
    <li className="flex justify-between">
      <div>
        <p>{doc.name}</p>
        <p className="text-sm text-gray-600">
          {settings.color ? 'Color' : 'Black & White'} | 
          {settings.doubleSided ? ' Double-sided' : ' Single-sided'} | 
          {' '}{getPageCount()} {getPageCount() > 1 ? 'pages' : 'page'} x 
          {' '}{settings.copies || 1} {(settings.copies || 1) > 1 ? 'copies' : 'copy'}
          {settings.pageRange ? ` | Range: ${settings.pageRange}` : ''}
        </p>
      </div>
      <span>₹{calculatePrice().toFixed(2)}</span>
    </li>
  );
};

export default DocumentListItem;
