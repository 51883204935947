import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useAuthStore } from '../store/authStore';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { 
  getAdminDashboardStats, 
  AdminDashboardStats, 
  getPendingDocuments, 
  updateDocumentStatus,
  PendingDocument
} from '../lib/supabase/api/admin-service';
import { Eye, Download } from 'lucide-react';

const AdminPanel: React.FC = () => {
  const { user, isAdmin, loading } = useAuthStore();
  const navigate = useNavigate();
  
  const [dashboardStats, setDashboardStats] = useState<AdminDashboardStats>({
    users_count: 0,
    documents_count: 0,
    pending_approvals: 0,
    total_revenue: 0
  });
  const [statsLoading, setStatsLoading] = useState(true);
  const [pendingDocuments, setPendingDocuments] = useState<PendingDocument[]>([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [processingDocId, setProcessingDocId] = useState<string | null>(null);
  
  // Redirect non-admin users
  useEffect(() => {
    if (!loading && !isAdmin && user) {
      console.log('AdminPanel: User is not an admin, redirecting');
      navigate('/');
    }
  }, [user, isAdmin, loading, navigate]);
  
  // Fetch dashboard stats when component mounts
  useEffect(() => {
    const fetchStats = async () => {
      if (isAdmin) {
        setStatsLoading(true);
        try {
          const stats = await getAdminDashboardStats();
          console.log('Fetched admin stats:', stats);
          setDashboardStats(stats);
        } catch (error) {
          console.error('Error fetching admin stats:', error);
        } finally {
          setStatsLoading(false);
        }
      }
    };
    
    fetchStats();
  }, [isAdmin]);
  
  // Fetch pending documents when approvals tab is active
  const fetchPendingDocuments = async () => {
    console.log('Starting to fetch pending documents...');
    setDocumentsLoading(true);
    try {
      const documents = await getPendingDocuments();
      console.log('Fetched pending documents successfully:', documents);
      setPendingDocuments(documents);
    } catch (error: any) {
      const errorMessage = error?.message || 'Unknown error';
      const errorCode = error?.code || 'No error code';
      console.error('Error fetching pending documents:', { errorMessage, errorCode, error });
      
      // More detailed error alert
      alert(`Failed to load pending documents: ${errorMessage} (${errorCode})`);
    } finally {
      setDocumentsLoading(false);
    }
  };

  // Handle document approval/denial
  const handleStatusUpdate = async (documentId: string, status: 'approved' | 'denied') => {
    console.log(`Attempting to update document ${documentId} to status ${status}`);
    setProcessingDocId(documentId);
    try {
      await updateDocumentStatus(documentId, status);
      console.log(`Successfully updated document ${documentId} to ${status}`);
      
      // Update local state
      setPendingDocuments(prev => prev.filter(doc => doc.id !== documentId));
      
      // Update stats to reflect one less pending document
      setDashboardStats(prev => ({
        ...prev,
        pending_approvals: Math.max(0, prev.pending_approvals - 1)
      }));
      
      alert(`Document ${status === 'approved' ? 'approved' : 'denied'} successfully`);
    } catch (error: any) {
      const errorMessage = error?.message || 'Unknown error';
      const errorCode = error?.code || 'No error code';
      console.error(`Error updating document status:`, { errorMessage, errorCode, error });
      
      // More detailed error alert
      alert(`Failed to ${status} document: ${errorMessage} (${errorCode})`);
    } finally {
      setProcessingDocId(null);
    }
  };

  // Handle tab change
  const handleTabChange = (value: string) => {
    console.log(`Tab changed to ${value}`);
    if (value === "approvals") {
      fetchPendingDocuments();
    }
  };
  
  // Helper function to format file size
  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Only show loading state if we're loading AND we don't yet know if user is admin
  if (loading && !isAdmin) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
        <Footer />
      </div>
    );
  }
  
  // Show access denied only if we're sure the user is not an admin
  if (!isAdmin && !loading && user) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow flex items-center justify-center">
          <div className="text-center p-6">
            <h2 className="text-2xl font-bold text-red-600 mb-2">Access Denied</h2>
            <p className="text-gray-600 mb-4">You do not have permission to access the admin panel.</p>
            <Button onClick={() => navigate('/')}>Return to Home</Button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header />
      
      <main className="flex-grow">
        <div className="container mx-auto px-3 md:px-4 py-6">
          <div className="flex flex-col space-y-6">
            {/* Admin Header */}
            <div>
              <h1 className="text-2xl font-bold text-gray-800">Admin Panel</h1>
              <p className="text-gray-600">Manage your application settings and data</p>
            </div>
            
            {/* Stats Overview */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <Card>
                <CardContent className="p-6">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-500">Total Users</span>
                    {statsLoading ? (
                      <div className="h-8 w-16 bg-gray-200 animate-pulse rounded mt-1"></div>
                    ) : (
                      <span className="text-3xl font-bold text-gray-800">{dashboardStats.users_count}</span>
                    )}
                  </div>
                </CardContent>
              </Card>
              
              <Card>
                <CardContent className="p-6">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-500">Total Documents</span>
                    {statsLoading ? (
                      <div className="h-8 w-16 bg-gray-200 animate-pulse rounded mt-1"></div>
                    ) : (
                      <span className="text-3xl font-bold text-gray-800">{dashboardStats.documents_count}</span>
                    )}
                  </div>
                </CardContent>
              </Card>
              
              <Card>
                <CardContent className="p-6">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-500">Pending Approvals</span>
                    {statsLoading ? (
                      <div className="h-8 w-16 bg-gray-200 animate-pulse rounded mt-1"></div>
                    ) : (
                      <span className="text-3xl font-bold text-orange-500">{dashboardStats.pending_approvals}</span>
                    )}
                  </div>
                </CardContent>
              </Card>
              
              <Card>
                <CardContent className="p-6">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-gray-500">Total Revenue</span>
                    {statsLoading ? (
                      <div className="h-8 w-16 bg-gray-200 animate-pulse rounded mt-1"></div>
                    ) : (
                      <span className="text-3xl font-bold text-green-600">₹{dashboardStats.total_revenue.toFixed(2)}</span>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>
            
            {/* Admin Tabs */}
            <Tabs defaultValue="users" onValueChange={handleTabChange}>
              <TabsList className="mb-4">
                <TabsTrigger value="users">Users</TabsTrigger>
                <TabsTrigger value="documents">Documents</TabsTrigger>
                <TabsTrigger value="approvals">Approvals</TabsTrigger>
                <TabsTrigger value="settings">Settings</TabsTrigger>
              </TabsList>
              
              <TabsContent value="users">
                <Card>
                  <CardHeader>
                    <CardTitle>User Management</CardTitle>
                    <CardDescription>Manage user accounts and permissions</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-600">No users found. User management will be implemented in a future update.</p>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="documents">
                <Card>
                  <CardHeader>
                    <CardTitle>Document Management</CardTitle>
                    <CardDescription>Manage all uploaded documents</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-600">No documents found. Document management will be implemented in a future update.</p>
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="approvals">
                <Card>
                  <CardHeader>
                    <CardTitle>Document Approvals</CardTitle>
                    <CardDescription>Review and approve submitted documents</CardDescription>
                  </CardHeader>
                  <CardContent>
                    {documentsLoading ? (
                      <div className="flex justify-center py-8">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                      </div>
                    ) : pendingDocuments.length > 0 ? (
                      <div className="space-y-6">
                        {pendingDocuments.map((doc) => (
                          <Card key={doc.id} className="overflow-hidden">
                            <div className="flex flex-col md:flex-row">
                              {/* Document Cover Image */}
                              <div className="w-full md:w-1/4 p-4 flex items-center justify-center bg-gray-100">
                                {doc.cover_image_path ? (
                                  <img 
                                    src={doc.cover_image_path} 
                                    alt={doc.title} 
                                    className="max-h-48 object-contain"
                                  />
                                ) : (
                                  <div className="w-full h-48 bg-gray-200 flex items-center justify-center text-gray-400">
                                    No cover image
                                  </div>
                                )}
                              </div>
                              
                              {/* Document Details */}
                              <div className="w-full md:w-3/4 p-4">
                                <div className="flex flex-col space-y-4">
                                  <div>
                                    <h3 className="text-xl font-semibold text-gray-800">
                                      <a 
                                        href={doc.file_path} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:underline"
                                      >
                                        {doc.title}
                                      </a>
                                    </h3>
                                    <p className="text-sm text-gray-500">
                                      Submitted by {doc.user_email} • {new Date(doc.created_at).toLocaleDateString()}
                                    </p>
                                  </div>
                                  
                                  {doc.description && (
                                    <p className="text-gray-600 text-sm">{doc.description}</p>
                                  )}
                                  
                                  <div className="flex flex-wrap gap-2">
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 border border-gray-200">
                                      {doc.file_type}
                                    </span>
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 border border-gray-200">
                                      {formatFileSize(doc.file_size)}
                                    </span>
                                    <div className="flex items-center space-x-1 text-gray-500 text-xs">
                                      <Eye size={14} />
                                      <span>{doc.views}</span>
                                    </div>
                                    <div className="flex items-center space-x-1 text-gray-500 text-xs">
                                      <Download size={14} />
                                      <span>{doc.downloads}</span>
                                    </div>
                                  </div>
                                  
                                  <div className="flex gap-2 mt-2">
                                    <Button
                                      variant="default"
                                      size="sm"
                                      onClick={() => handleStatusUpdate(doc.id, 'approved')}
                                      disabled={processingDocId === doc.id}
                                      className="bg-green-600 hover:bg-green-700"
                                    >
                                      {processingDocId === doc.id ? 'Processing...' : 'Approve'}
                                    </Button>
                                    <Button
                                      variant="destructive"
                                      size="sm"
                                      onClick={() => handleStatusUpdate(doc.id, 'denied')}
                                      disabled={processingDocId === doc.id}
                                    >
                                      {processingDocId === doc.id ? 'Processing...' : 'Deny'}
                                    </Button>
                                    <a 
                                      href={doc.file_path}
                                      target="_blank" 
                                      rel="noopener noreferrer"
                                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                      Preview File
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-600 text-center py-8">No pending approvals found.</p>
                    )}
                  </CardContent>
                </Card>
              </TabsContent>
              
              <TabsContent value="settings">
                <Card>
                  <CardHeader>
                    <CardTitle>System Settings</CardTitle>
                    <CardDescription>Configure application settings</CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-600">Settings configuration will be implemented in a future update.</p>
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default AdminPanel;
